import React from "react";
import styled from "styled-components";
import { filtersform } from "../../formsConfig/formsConfig";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { Button, Input, Loader } from "../../components/Ui";
import ProductBox from "../../components/ProductBox/ProductBox";
import { getProducts } from "../../store/actions/products";

const CatalogWrapper = styled.div`
  .slide {
    position: relative;
    padding: 130px 15px 0 15px;
    z-index: 11;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 540px;
        height: 100%;
        margin-top: 0;
        padding: 0 12px;
      `}

      div {
        .contentTitle {
          display: flex;
          margin-bottom: 20px;

          h3 {
            font-size: 44px;
            color: #1d241e;
          }
        }

        h3 {
          font-size: 28px;
          font-weight: 300;
        }

        span {
          display: block;
          width: 40px;
          height: 2px;
          background-color: #eb5a3f;
          margin-bottom: 20px;
        }
      }
    }
  }

  .boxs {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #fff;
    z-index: 10;
    margin-top: 30px;
    padding: 0 12px 30px 12px;

    ${respondTo.md`
      margin-top: 0;
      top: -20px;
      flex-wrap: nowrap;
      padding: 50px 12px 55px 12px;
    `}

    .filter-box {
      width: 100%;
      background-color: #1bb410;
      padding: 15px;
      border-radius: 24px;

      ${respondTo.md`
        width: calc(33.333% - 20px);
      `}

      h3 {
        color: #fff;
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > div {
          width: 100%;
        }

        .filter-separator {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          hr {
            width: 100%;
            border-color: transparent;
            border-bottom: 1px solid #00852a;
          }

          span {
            flex: 0 0 auto;
            padding: 0 10px;
            font-size: 12px;
            text-transform: uppercase;
            color: #00852a;
          }
        }

        .filter-half {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #00852a;

          & > div {
            width: calc(50% - 10px);
          }
        }
      }

      label {
        color: #fff;
        font-size: 14px;
      }

      .filter-buttons {
        display: flex;
        justify-content: center;
      }
    }

    .wishlist-box {
      width: 100%;
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
        width: calc(66.666% - 20px);
      `}

      .wishlist-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
          color: #1bb410;
          font-size: 18px;
          font-weight: 600;
        }
      }

      h4 {
        font-size: 44px;
        color: #1d241e;
      }
    }

    .wishlist-body {
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;

      .wishlist-products {
        width: 100%;
        padding-right: 15px;

        .w-elements {
          display: flex;
          flex-wrap: wrap;
        }

        .w-products {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 15px;
        }

        .w-add-to {
          flex: 0 0 100%;
          background-color: #ebebeb;
          border-radius: 24px;
          padding: 30px 16px;
          text-align: center;
          color: #94a2ac;
          display: flex;
          align-items: center;

          ${respondTo.md`
            flex: 0 0 250px;
            margin-left: 30px;
          `}

          span {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
          }

          p {
            font-size: 20px;
            font-weight: 300;
          }
        }
      }

      .add-more {
        width: 100%;
        border-left: 1px solid #e4e7f1;
        padding: 0 15px;

        span {
          display: block;
        }

        ${respondTo.md`
          width: 25%;
        `}
      }
    }

    .wishlist-empty {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .left-text {
        width: 100%;

        ${respondTo.md`
          width: 60%;
        `}

        span {
          display: block;
          margin-bottom: 15px;
        }
      }

      .right-icon {
        width: 40%;
        margin-top: 20px;
        text-align: right;
        display: none;

        ${respondTo.md`
          display: block;
        `}
      }

      .empty-footer {
        margin-top: 27px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      span {
        color: #e61274;
        font-size: 28px;
      }
    }

    .wishlist-user {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      color: #e61274;
      display: none;

      ${respondTo.md`
        display: flex;
      `}

      .user-info {
        margin-left: 10px;

        p {
          text-transform: lowercase;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    .wishlist-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-top: 20px;

      h4 {
        margin-bottom: 10px;
      }

      a {
        margin-top: 15px;
      }

      ${respondTo.md`
        margin-top: 0;

        h4 {
          margin-bottom: 0;
        }

        a {
          margin-top: 0;
        }
      `}
    }
  }

  .products-list {
    position: relative;
    border-radius: 24px;
    padding-top: 50px;
    background-color: #ebebeb;
    z-index: 11;

    ${respondTo.md`
      top: -40px;
    `}

    h3 {
      font-size: 44px;
      color: #1d241e;
      margin-bottom: 20px;
    }

    .product-list {
      display: flex;
      flex-wrap: wrap;

      .single-product {
        width: 50%;
        margin-bottom: 20px;

        ${respondTo.md`
          width: 16.666%;
        `}
      }
    }
  }
`;

const Catalog = (props) => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.products.categories);

  const filtersForm = filtersform(9000);

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const wishlist = useSelector((state) => state.user.user.wishlist);
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.loading);

  const filterProductsHandler = () => {
    dispatch(getProducts(formData));
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(filterProductsHandler, filtersForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        categories={categories}
        {...inp}
      />
    );
  });

  return (
    <CatalogWrapper>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/catalog-bg.png"} alt="" />
        <div className="content">
          <div>
            <div className="contentTitle">
              <h3>Il catalogo</h3>
            </div>

            <p style={{ marginBottom: 15 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
              nonumy eirmod tempor <strong>invidunt</strong>. Pisum dolor sit
              amet, <strong>consetetur sadipscing elitr</strong>, sed diam n
              diam nonumy eirmo umquam sed <strong>adiscipit nonumy</strong>{" "}
              eirmod tempor invidunt adiscipit nonumy eirmod.
            </p>
          </div>
        </div>
      </div>

      <div className="boxs">
        <div className="filter-box">
          <form onSubmit={formSubmitHandler}>
            {inputs.slice(0, 2)}
            <div className="filter-separator">
              <hr />
              <span>Fascia punti</span>
              <hr />
            </div>
            <div className="filter-half">{inputs.slice(2, 4)}</div>
            {inputs[4]}
            <div className="filter-buttons">
              <Button bg="#fff">Applica</Button>
            </div>
          </form>
        </div>

        <div className="wishlist-box">
          <div className="wishlist-header">
            <h4>Dalla tua wishlist</h4>
            <Link to="/wishlist">
              Guarda tutti <ChevronRight size={30} />
            </Link>
          </div>

          <div className="wishlist-body">
            <div className="wishlist-products">
              <div className="w-elements">
                <div className="w-products">
                  {wishlist.slice(0, 3).map((product) => (
                    <ProductBox product={product} key={product.id} />
                  ))}
                </div>
                <div className="w-add-to">
                  <div>
                    <span>Aggiungi qualcosa</span>
                    <p>tocca l'icona a forma di cuore sui premi</p>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/heart-big-icon.svg"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="products-list" id="catalog">
        <h3>Il catalogo</h3>

        <div className="product-list">
          {loading ? (
            <Loader show={loading} />
          ) : (
            <>
              {products.map((prod) => (
                <div className="single-product" key={prod.id}>
                  <ProductBox product={prod} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </CatalogWrapper>
  );
};

export default Catalog;

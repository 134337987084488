import React from "react";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { PlayCircle, ChevronRight } from "@styled-icons/boxicons-regular";
import { Link } from "react-router-dom";

const ProfileContainer = styled.div`
  .slide {
    z-index: 11;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 25px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 15px;
      `}

      .content-info {
        max-width: 650px;
        color: #1d241e;

        h3 {
          font-size: 44px;
          margin-bottom: 10px;
        }
      }

      .personal-awards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        .award-box {
          width: 230px;
          margin-top: 30px;
          margin-right: 30px;

          strong {
            font-size: 12px;
            color: #1d241e;
            text-transform: uppercase;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-size: 64px;
              color: #00852a;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;

const ELearning = (props) => {
  return (
    <>
      <ProfileContainer>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/elearning-bg.png"}
            alt=""
          />

          <div className="content">
            <div>
              <div className="content-info">
                <h3>E-learning</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                  nonumy eirmod tempor invidunt. Pisum dolor sit amet,
                  consetetur sadipscing elitr, sed diam n diam nonumy eirmo
                  umquam sed adiscipit nonumy eirmod tempor invidunt adiscipit
                  nonumy eirmod.
                </p>
              </div>
              <div className="personal-awards">
                <div className="award-box">
                  <strong>Corsi da fare</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/book-big-icon.svg"}
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
                <div className="award-box">
                  <strong>Corsi completati</strong>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/icons/book-completed-big-icon.svg"
                      }
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileContainer>

      <Courses>
        <h3>I tuoi corsi</h3>

        <div className="courses-list">
          <div className="course-box completed">
            <div className="course-left">
              <div className="course-info">
                <span>00/00/2022</span>
                <h5>Titolo e-learning</h5>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt.
                </p>
              </div>

              <div className="points-box">
                <span>Ottenuti</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div className="course-right">
              <img
                src={process.env.PUBLIC_URL + "/images/elearning.jpg"}
                alt=""
              />
              <PlayCircle size={46} />
              <Link to="/e-learning">COMPLETATO</Link>
            </div>
          </div>
          <div className="course-box">
            <div className="course-left">
              <div className="course-info">
                <span>00/00/2022</span>
                <h5>Titolo e-learning</h5>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt.
                </p>
              </div>

              <div className="points-box">
                <span>Puoi ottenere</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div className="course-right">
              <img
                src={process.env.PUBLIC_URL + "/images/elearning.jpg"}
                alt=""
              />
              <PlayCircle size={46} />
              <Link to="/e-learning/corso">
                Completa <ChevronRight size={30} />
              </Link>
            </div>
          </div>
          <div className="course-box">
            <div className="course-left">
              <div className="course-info">
                <span>00/00/2022</span>
                <h5>Titolo e-learning</h5>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt.
                </p>
              </div>

              <div className="points-box">
                <span>Puoi ottenere</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div className="course-right">
              <img
                src={process.env.PUBLIC_URL + "/images/elearning.jpg"}
                alt=""
              />
              <PlayCircle size={46} />
              <Link to="/e-learning/corso">
                Completa <ChevronRight size={30} />
              </Link>
            </div>
          </div>
          <div className="course-box">
            <div className="course-left">
              <div className="course-info">
                <span>00/00/2022</span>
                <h5>Titolo e-learning</h5>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt.
                </p>
              </div>

              <div className="points-box">
                <span>Puoi ottenere</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div className="course-right">
              <img
                src={process.env.PUBLIC_URL + "/images/elearning.jpg"}
                alt=""
              />
              <PlayCircle size={46} />
              <Link to="/e-learning/corso">
                Completa <ChevronRight size={30} />
              </Link>
            </div>
          </div>
        </div>
      </Courses>
    </>
  );
};

const Courses = styled.div`
  background-color: #fff;
  position: relative;
  padding: 50px 12px;

  ${respondTo.md`
    top: -30px;
    margin-bottom: -50px;
  `}

  h3 {
    color: #1d241e;
    font-size: 44px;
    margin-bottom: 15px;
  }

  .courses-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .course-box {
      width: 100%;
      border-radius: 24px;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      min-height: 320px;
      margin-bottom: 30px;

      ${respondTo.md`
        width: calc(33.333% - 15px);
      `}

      &.completed {
        .course-left {
          background-color: #47545d;

          .course-info {
            span,
            h5,
            p {
              color: #94a2ac;
            }
          }
        }
      }

      .course-left {
        width: 100%;
        padding: 16px;
        background-color: #00852a;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;

        .course-info {
          span {
            font-size: 12px;
            color: #fff;
          }

          h5 {
            font-size: 24px;
            color: #1bb410;
            margin-bottom: 25px;
          }

          p {
            color: #1d241e;
          }
        }

        .points-box {
          background-color: #1bb410;
          width: 105px;
          margin-top: 25px;
          border-radius: 16px;
          padding: 5px 12px;

          span {
            font-size: 12px;
            color: #00852a;
          }

          div {
            display: flex;
            align-items: center;

            strong {
              display: inline-block;
              margin-left: 5px;
              color: #fff;
            }
          }
        }
      }

      .course-right {
        position: relative;
        flex: 0 0 185px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.45);
          position: absolute;
        }

        svg {
          position: relative;
          z-index: 1;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        a {
          cursor: pointer;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          position: absolute;
          bottom: 10px;
          right: 20px;
          z-index: 1;
        }
      }
    }
  }
`;

export default ELearning;

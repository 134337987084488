import React from "react";
import styled from "styled-components";
import { Wrapper, Button, Typography } from "../../../Ui";

const ResultTitle = styled.p`
  color: ${(props) => (props.userWon ? "#3F943A" : "#b73e27")};
  font-size: 28px;
  font-weight: 300;
`;

const ResultDynamic = (props) => {
  const {
    userWon,
    dynamicData: { title, content },
  } = props;

  return (
    <ResultTitle userWon>
      {title}
      <br />
      {content}
    </ResultTitle>
  );
};

export default ResultDynamic;

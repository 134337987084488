import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { createBrowserHistory } from "history";
import { LastLocationProvider } from "react-router-last-location";

import AuthLayout from "../layouts/AuthLayout";
import BaseLayout from "../layouts/BaseLayout";

import ReactGA from "react-ga";
import styled from "styled-components";

import Login from "../views/Login";
import Register from "../views/Register";
import RecoveryPassword from "../views/RecoveryPassword";
import ResetPassword from "../views/ResetPassword";
import Profile from "../views/Profile";
import Initiative from "../views/Initiative";
import UserActivated from "../views/UserActivated";
import Game from "../views/Game";
import Oauth from "../views/Oauth";
import Panel from "../views/Panel";
import Awards from "../views/Awards";
import NotFound from "../views/NotFound";
import Contact from "../views/Contact";
import HowToWork from "../views/HowToWork";
import AreaPartner from "../views/AreaPartner";
import InviteFriend from "../views/InviteFriend/InviteFriend";
import Product from "../views/Product/Product";
import Catalog from "../views/Catalog/Catalog";
import Wishlist from "../views/Wishlist/Wishlist";
import Cart from "../views/Cart/Cart";
import SinglePartner from "../views/SinglePartner/SinglePartner";
import Surveys from "../views/Surveys/Surveys";
import WinMore from "../views/WinMore/WinMore";
import MissionDetails from "../views/MissionDetails/MissionDetails";
import Faq from "../views/Faq/Faq";

import { Link } from "react-router-dom";
import { Modal, Button } from "../components/Ui";
import FirstAccess from "../views/FirstAccess/FirstAccess";
import ELearning from "../views/E-learning/E-learning";
import Community from "../views/Community/Community";
import Questions from "../views/Questions/Questions";
import Course from "../views/Course/Course";

const history = createBrowserHistory();

const ConvalidContent = styled.div`
  text-align: center;

  p {
    line-height: 1.4;
  }

  a {
    margin: 15px 0;
    display: block;
  }
`;

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          (window.location = "/auth/login")
        )
      }
    />
  );
};

const Routes = (props) => {
  const { isLogged, ownLogin, multipromo, userWin, promoId, userInfo, inGame } =
    props;

  history.listen((location) => {
    let pathToGA = location.pathname;
    if (pathToGA === "/" && isLogged != null) {
      window.ga("set", "page", "/logged");
    } else {
      window.ga("set", "page", pathToGA);
    }
    window.ga("send", "pageview");
  });

  useEffect(() => {
    if (isLogged != null) {
      if (window.location.pathname === "/") {
        window.ga("set", "page", "/logged");
      }
    } else {
      window.ga("set", "page", window.location.pathname);
    }
    window.ga("send", "pageview");
  }, [isLogged]);

  const [showCM, setShowCM] = useState(false);

  useEffect(() => {
    if (userWin && !inGame) {
      setShowCM(true);
    }
  }, [userWin]);

  let ownRoutes = null;

  const closeCMHandler = () => {
    setShowCM(false);
  };

  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        <Route
          path="/auth/recovery-password"
          exact
          render={(route) => <AuthLayout cmp={RecoveryPassword} {...route} />}
        />
        <Route
          path="/auth/register"
          exact
          render={(route) => <AuthLayout cmp={Register} {...route} />}
        />
        <Route
          path="/activate/:id"
          exact
          render={(route) => <AuthLayout cmp={UserActivated} {...route} />}
        />
        <Route
          path="/reset-password/:onetimetoken"
          exact
          render={(route) => <AuthLayout cmp={ResetPassword} {...route} />}
        />
        <Route
          path="/auth/login"
          render={(route) => <AuthLayout cmp={Login} {...route} />}
        />
      </React.Fragment>
    );
  }

  return (
    <Router history={history}>
      {/* {showCM ? (
        <Modal show={showCM} close={() => closeCMHandler()}>
          <ConvalidContent>
            <p>Hai un premio da convalidare?</p>
            <Link
              to={`/promo/game/${promoId}`}
              onClick={() => closeCMHandler()}
            >
              <Button
                upper
                gradient={{ left: "#6CB967", right: "#96F290" }}
                hoverGradient={{ left: "#96F290", right: "#96F290" }}
              >
                Si, voglio convalidarlo
              </Button>
            </Link>
          </ConvalidContent>
        </Modal>
      ) : null} */}
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path="/"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Initiative}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/first-access"
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={FirstAccess}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/regole-del-gioco"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={HowToWork}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/e-learning/corso"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Course}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/area-partner"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={AreaPartner}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/catalogo"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Catalog}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/wishlist"
            exact
            component={Wishlist}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/carrello"
            exact
            component={Cart}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {/* <PrivateRoute
            path="/partecipa"
            exact
            component={WinMore}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          /> */}
          <PrivateRoute
            path="/missione"
            exact
            component={MissionDetails}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/community"
            exact
            component={Community}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/dicci-la-tua"
            exact
            component={Questions}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/e-learning"
            exact
            component={ELearning}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/prodotto/:id"
            exact
            component={Product}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/promo/:initiative"
            exact
            component={WinMore}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/sondaggi/:initiative"
            exact
            component={Surveys}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/partner/:id"
            exact
            component={SinglePartner}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {/* <Route
            path="/oauth/:connect"
            exact
            render={(route) => <Oauth {...route} />}
          />
          <Route
            path="/tags/:tag"
            exact
            render={(route) => <BaseLayout cmp={null} {...route} />}
          />
          <Route path="/panel" exact component={Panel} /> */}
          <PrivateRoute
            path="/promo/:initiative/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type="gamelayout"
          />
          <PrivateRoute
            path="/sondaggi/:initiative/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type="gamelayout"
          />
          <PrivateRoute
            path="/promo/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            isGame={true}
            type="gamelayout"
          />
          <PrivateRoute
            path="/user/profilo"
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/wishlist"
            exact
            component={Wishlist}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/sondaggi"
            exact
            component={Surveys}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/faq"
            exact
            component={Faq}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/user/awards"
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/contatti"
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {userInfo && userInfo.mgm_code && (
            <PrivateRoute
              path="/invite-friend"
              exact
              component={InviteFriend}
              layoutCmp={BaseLayout}
              isLogged={props.isLogged}
              ownLogin={ownLogin}
            />
          )}
          {ownRoutes}
          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    promoId: state.promo.viewer.id,
    userWin: state.user.user.has_pending_play,
    isLogged: state.auth.token,
    inGame: state.game.inGameFlow,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    userInfo: state.user.user.userinfo,
  };
};

export default connect(mapStateToProps)(Routes);

import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Logo, Button } from "../Ui";
import { Link } from "react-router-dom";
import {
  Menu,
  Heart,
  ChevronRight,
  Gift,
} from "@styled-icons/boxicons-regular";
import { respondTo } from "../../theme/mixin";
import * as actionCreators from "../../store/actions";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 12px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const HeaderSection = styled.div`
  background-color: #fff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 24px;
  width: 100%;
  max-width: 1314px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  .profile-box {
    display: none;
    align-items: center;

    ${respondTo.md`
      display: flex;
    `}

    .user-avatar {
      width: 56px;
      height: 56px;
      border: 2px solid #fff;
      background-color: #cccccc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    .user-info {
      margin-left: 16px;

      & > span {
        font-size: 18px;
        color: ${(props) => props.theme.primary};
        position: relative;
        top: 3px;
      }

      .user-boxs {
        display: flex;
        align-items: center;

        .user-box {
          display: flex;
          align-items: center;
          font-size: 14px;

          &:first-child {
            margin-right: 32px;
          }

          img {
            margin-right: 5px;
          }
        }
      }

      p {
        font-size: 12px;
      }
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;

  ${respondTo.md`
    margin-right: 15px;
  `}

  .menu-icons {
    position: relative;
    right: -15px;
    padding-left: 10px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;

    .menu-icon {
      cursor: pointer;
      position: relative;
      margin-left: 15px;

      .menu-image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        background-color: #ebebeb;
        border-radius: 50%;
      }

      .cart-numb {
        position: absolute;
        left: 5px;
        top: 7px;
        font-size: 11px;
        width: 16px;
        height: 16px;
        background-color: #1bb410;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      a {
        color: #3a3c42;
      }

      .open-menu-box {
        position: relative;

        &:hover .menu-box {
          display: block;
        }

        .menu-box {
          display: none;
          position: absolute;
          top: calc(100% - 15px);
          right: -15px;

          .menu-box-content {
            background-color: #ebebeb;
            width: 205px;
            padding: 30px 10px 10px 10px;
            border-radius: 24px;

            .exit {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #94a2ac;
              border-radius: 16px;
              height: 44px;
              margin-top: 10px;

              span {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
              }
            }
          }

          li {
            text-align: center;

            &.my-page a {
              display: flex;
              align-items: center;
              justify-content: center;
              color: ${(props) => props.theme.primary};
              margin-top: 5px;
            }

            a {
              font-weight: 600;
              font-size: 16px;
              color: #1d241e;
              display: block;
              padding: 2px 0;

              &:hover {
                color: ${(props) => props.theme.primary};
              }
            }
          }
        }
      }
    }
  }

  .menu-user {
    z-index: 1;
    position: relative;

    .user-avatar {
      width: 60px;
      height: 60px;
      border: 2px solid #fff;
      background-color: #cccccc;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    &:hover .profile-box-content {
      display: block;
    }

    .profile-box-content {
      padding-top: 15px;
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
    }

    .profile-box {
      width: 300px;
      background-color: #fff;
      padding: 15px 10px;
      border-radius: 10px;
      box-shadow: 0px 5px 15px #0000004c;

      &:after {
        content: "";
        position: absolute;
        box-shadow: #0000004c 1px 1px 2px;
        -moz-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        top: 7px;
        right: 22px;
        border-width: 8px;
        border-style: solid;
        border-radius: 0 0 2px 0;
        border-color: transparent #fff #fff transparent;
      }

      & > span {
        font-weight: 300;
        display: block;
        color: #adb1bc;
      }

      & > strong {
        font-size: 16px;
        text-transform: uppercase;
      }

      a {
        text-transform: uppercase;
        color: #3a3c42;
      }

      .point {
        margin-top: 5px;
        padding-top: 5px;
        margin-bottom: 30px;
        border-top: 1px solid #e4e7f1;
        display: flex;
        align-items: center;
        color: #e61274;

        strong {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }
`;

const MenuSection = styled.div`
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;

  ${respondTo.md`
    padding-right: 0;
  `}
`;

const Header = (props) => {
  const dispatch = useDispatch();
  const { isLogged } = props;

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const cart = useSelector((state) => state.user.user.cart);

  return (
    <HeaderWrapper>
      <HeaderSection>
        <div className="profile-box">
          <div className="user-avatar">{userInfo && userInfo.firstname[0]}</div>
          <div className="user-info">
            <span>{userInfo && userInfo.firstname}</span>
            <div className="user-boxs">
              <div className="user-box">
                <img
                  src={process.env.PUBLIC_URL + "/icons/trophy.svg"}
                  alt=""
                />
                <strong>7°</strong>
              </div>
              <div className="user-box">
                <img src={process.env.PUBLIC_URL + "/icons/gift.svg"} alt="" />
                <strong>{userInfo && userInfo.score} PT</strong>
              </div>
            </div>
            <p>
              10 giorni al termine del <strong>1° livello</strong>
            </p>
          </div>
        </div>
        <Logo logo={process.env.PUBLIC_URL + "/images/logo.svg"} />
        <MenuSection>
          {isLogged ? (
            <MenuWrapper>
              <div className="menu-icons">
                <div className="menu-icon">
                  <Link to="/">
                    <div className="menu-image-box">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/notification-icon.svg"
                        }
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="menu-icon">
                  <Link to="/carrello">
                    {cart.length > 0 && (
                      <div className="cart-numb">{cart.length}</div>
                    )}
                    <div className="menu-image-box">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/cart-icon.svg"}
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="menu-icon">
                  <div className="open-menu-box">
                    <div className="menu-image-box">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/menu-icon.svg"}
                        alt=""
                      />
                    </div>
                    <div className="menu-box">
                      <div className="menu-box-content">
                        <ul>
                          <li>
                            <Link to="/">Homepage</Link>
                          </li>
                          <li>
                            <Link to="/catalogo">Catalogo</Link>
                          </li>
                          <li>
                            <Link to="/wishlist">Wishlist</Link>
                          </li>
                          <li>
                            <Link to="/regole-del-gioco">Regole del gioco</Link>
                          </li>
                          <li>
                            <Link to="/community">Community</Link>
                          </li>
                          <li>
                            <Link to="/missione">Missioni</Link>
                          </li>
                          <li>
                            <Link to="/dicci-la-tua">Dicci la tua</Link>
                          </li>
                          <li>
                            <Link to="/e-learning">E-learning</Link>
                          </li>
                          <li>
                            <Link to="/contatti">Contatti</Link>
                          </li>
                          <li className="my-page">
                            <Link to="/user/profilo">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/icons/user-icon.svg"
                                }
                                alt=""
                              />
                              MY PAGE
                            </Link>
                          </li>
                          <div
                            className="exit"
                            onClick={() => dispatch(actionCreators.logout())}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/icons/exit-icon.svg"
                              }
                              alt=""
                            />
                            <span>Esci</span>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="menu-user">
                <div className="profile-box-content">
                  <div className="profile-box">
                    {userInfo && (
                      <>
                        <span>Ciao,</span>
                        <strong>{`${userInfo.firstname} ${userInfo.lastname}`}</strong>
                        <div className="point">
                          <Gift size={22} />
                          <strong>Hai {userInfo.score} Pt</strong>
                        </div>

                        <Link to="/user/profilo">
                          <strong>Vai al profilo</strong>
                          <ChevronRight size={25} />
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </MenuWrapper>
          ) : (
            <Link
              to={{
                pathname: "https://mylinkempage.linkem.com/User/Login",
              }}
              target="_blank"
            >
              <Button
                gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
                upper
                style={{ marginRight: 10 }}
              >
                Accedi
              </Button>
            </Link>
          )}
        </MenuSection>
      </HeaderSection>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func,
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
};

export default Header;

import React from "react";
import Avatar from "../../components/Avatar/Avatar";
import PartnerBox from "../../components/PartnerBox/PartnerBox";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const AreaPartner = () => {
  const partners = useSelector((state) => state.partner.partnerList);
  const userInfo = useSelector((state) => state.user.user.userinfo);

  const options = {
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PartnersWrapper = styled.div`
    .slide {
      position: relative;
      padding: 90px 15px 0 15px;

      ${respondTo.md`
        padding: 0;
      `}

      .content {
        z-index: 10;
        display: flex;
        align-items: center;
        margin-top: 15px;

        .contentTitle {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }

        ${respondTo.md`
          top: 0;
          position: absolute;
          width: 100%;
          max-width: 270px;
          height: 100%;
          margin-top: 0;
        `}

        h3 {
          font-size: 28px;
          margin-right: 10px;
        }
      }
    }

    .partners-vetrina {
      margin-top: 50px;
      padding: 15px;
      border-radius: 15px;
      background-color: #fdca46;

      .vetrina-header {
        display: flex;
        justify-content: space-between;

        .left {
          color: #fff;

          strong {
            font-size: 21px;
            display: block;
          }

          span {
            font-size: 18px;
            font-weight: 300;
          }
        }

        .right {
        }
      }

      .vetrina-list {
        margin-top: 20px;

        .single-partner-vetrina {
          padding: 0 15px;
        }
      }
    }

    .partners-list {
      margin-top: 50px;

      h3 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 25px;
      }

      .partners-list-wrapper {
        display: flex;
        flex-wrap: wrap;

        ${respondTo.md`
          margin: 0 -15px;
        `}

        .single-partner {
          width: 100%;
          padding: 0 15px;
          margin-top: 20px;
          margin-bottom: 30px;

          ${respondTo.md`
            width: 25%;
            margin-top: 0;
          `}
        }
      }
    }
  `;

  return (
    <PartnersWrapper>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/slide-club.jpg"} alt="" />
        <div className="content">
          <div>
            <div className="contentTitle">
              <h3>Club</h3>
              <img
                src={process.env.PUBLIC_URL + "/icons/club-icon.svg"}
                alt=""
              />
            </div>

            <p style={{ marginBottom: 15 }}>
              <strong>Sconti, privilegi, offerte:</strong> far parte del{" "}
              <strong>Club +</strong> vuol dire avere sempre di +.
              <br />
              <br />
              Non hai bisogno di punti: puoi subito accedere{" "}
              <strong>alla nostra selezione di partner</strong> per usufruire di{" "}
              <strong>vantaggi esclusivi.</strong>
              <br />
              <br /> Torna a trovarci per restare sempre aggiornato sulle
              promozioni che ti abbiamo riservato.
            </p>
          </div>
        </div>
      </div>

      <div className="partners-vetrina">
        <div className="vetrina-header">
          <div className="left">
            <strong>Le novità del club</strong>
            <span>luglio/settembre</span>
          </div>
          {/* <div className="right">
            {userInfo && (
              <>
                <Avatar firstname={userInfo.firstname} />
                <div className="avatar-info">
                  {userInfo.firstname}
                </div>
              </>
            )}
          </div> */}
        </div>
        <div className="vetrina-list">
          <Slider {...options}>
            {partners
              .filter((p) => p.vetrina === 1)
              .map((partner) => (
                <div className="single-partner-vetrina" key={partner.id}>
                  <PartnerBox partner={partner} />
                </div>
              ))}
          </Slider>
        </div>
      </div>

      <div className="partners-list">
        <h3>I partner</h3>

        <div className="partners-list-wrapper">
          {partners
            .filter((p) => p.vetrina !== 1)
            .map((partner) => (
              <div className="single-partner" key={partner.id}>
                <PartnerBox partner={partner} />
              </div>
            ))}
        </div>
      </div>
    </PartnersWrapper>
  );
};

export default AreaPartner;

import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getSlug } from "../../utils/utils";
import { Link, withRouter } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import * as actionCreators from "../../store/actions";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import SurveysList from "../../components/SurveysList/SurveysList";

const SurveysWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }
`;

const Surveys = (props) => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    isLogged,
    logo,
    promoinfo,
    viewer,
    getGames,
    games,
    oauths,
    ownLogin,
    oauthInfo,
    location: { pathname },
  } = props;

  let slug = "get";

  if (multipromo) {
    slug = getSlug();
  }

  let idgame = null;

  if (games.length > 0 && games.length === 1) {
    idgame = games[0].id;
  }

  useEffect(() => {
    getGames(slug, isLogged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <SurveysWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/sondaggi-background.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <h3>Conosciamoci di più</h3>
            <p>
              In questa sezione trovi l’elenco dei{" "}
              <strong>sondaggi da sbloccare</strong>: rispondi alle domande e
              accumula punti da spendere nel nostro catalogo. Puoi iniziare a
              rispondere a un sondaggio e decidere se finirlo subito oppure
              completarlo più tardi.
              <br />
              <br />
              Nel box in basso puoi controllare i{" "}
              <strong>sondaggi completati</strong>, quelli iniziati e avere
              un’anteprima di quelli in arrivo.
              <br />
              <br />
              Rispondi subito alle domande e{" "}
              <strong>inizia ad accumulare punti!</strong>
            </p>
          </div>
        </div>
      </div>
      <SurveysList />
    </SurveysWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user.userinfo,
    partners: state.partner.partnerList,
    wishlist: state.user.user.wishlist,
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
    products: state.products.allProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Surveys)
);

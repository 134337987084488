import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  g g {
    fill: ${(props) => props.theme.primary};
  }
`;

const Close = (props) => {
  const { clicked } = props;

  return (
    <div onClick={clicked}>
      <Icon width="28px" height="28px" viewBox="0 0 28 28" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill-rule="nonzero">
            <rect
              transform="translate(14.460175, 13.617029) rotate(45.000000) translate(-14.460175, -13.617029) "
              x="-0.539825407"
              y="9.61702884"
              width="30"
              height="8"
            ></rect>
            <rect
              opacity="0.4"
              transform="translate(13.788582, 13.788272) rotate(-45.000000) translate(-13.788582, -13.788272) "
              x="-1.71141777"
              y="9.78827202"
              width="31"
              height="8"
            ></rect>
          </g>
        </g>
      </Icon>
    </div>
  );
};

export default Close;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { ChevronUp } from "@styled-icons/boxicons-regular";

const FooterWrapper = styled.div`
  text-align: center;
  padding: 0 15px;

  ${respondTo.md`
    padding: 0;
  `}

  .legal-notes {
    margin-top: 10px;
    font-size: 8px;
    color: #adb1bc;

    a {
      color: #eb5a3f;
      text-decoration: underline;
    }
  }
`;

const FooterContainer = styled.footer`
  overflow: hidden;
  background-color: #47545d;
  flex-shrink: 0;
  border-radius: 15px 15px 0 0;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
    background-color: ${(props) => props.theme.primary};
  }
`;

const MainBar = styled.div`
  padding-top: 30px;
  padding-bottom: 5px;

  .logoContent {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .MainBarContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;

    ${respondTo.md`
      flex-wrap: nowrap;
    `}

    .logo-footer {
      max-width: 150px;
    }

    .MainBarText {
      color: #fff;
      text-align: center;
      font-size: 12px;
      margin-top: 20px;

      .links {
        margin-top: 2px;
        text-align: center;

        a {
          color: #eb5a3f;
          text-decoration: underline;
          font-size: 10px;
          margin: 0 7px;
        }
      }

      ${respondTo.md`
        margin-top: 0;
      `}
    }

    .MainBarRight {
      color: #fff;
      cursor: pointer;
      position: absolute;
      bottom: 15px;
      right: 5px;

      ${respondTo.md`
        margin: 0;
      `}
    }
  }
`;

const Footer = (props) => {
  return (
    <div className="container" style={{ zIndex: 10 }}>
      <FooterWrapper>
        <FooterContainer>
          <MainBar>
            <div className="logoContent">
              <img
                src={process.env.PUBLIC_URL + "/images/logo-white.svg"}
                alt=""
              />
            </div>
            <div className="MainBarContent">
              <div className="MainBarText">
                (C) 2022 - Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy eirmod.
              </div>
              <div
                className="MainBarRight"
                onClick={() => window.scrollTo(0, 0)}
              >
                <ChevronUp size={30} />
              </div>
            </div>
          </MainBar>
        </FooterContainer>
      </FooterWrapper>
    </div>
  );
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;

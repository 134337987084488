import React, { useEffect, useState } from "react";
import { CustomContainer } from "../../components/Ui";
import { connect } from "react-redux";
import { getSlug } from "../../utils/utils";
import Seo from "../../components/Seo";
import NotFound from "../NotFound";
import * as actionCreators from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import { ChevronRight, ChevronDown } from "@styled-icons/boxicons-regular";
import "slick-carousel/slick/slick.css";
import FromCatalog from "../../components/FromCatalog/FromCatalog";

const Situation = styled.div`
  position: relative;
  top: 0;
  padding: 50px 12px 50px 12px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;

  ${respondTo.md`
    padding: 50px 12px 0 12px;
    top: -30px;
  `}

  h3 {
    font-size: 38px;
    color: #1d241e;
  }

  .box-left {
    width: 100%;

    ${respondTo.md`
      width: 60%;
    `}

    .box-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        font-size: 18px;
        font-weight: 600;
        color: #1bb410;
      }
    }

    .box-subheader {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;

      & > div {
        display: flex;
        align-items: center;

        span {
          color: ${(props) => props.theme.primary};
          font-size: 64px;
        }

        small {
          font-size: 12px;
          font-weight: 800;
          line-height: 1;
          text-transform: uppercase;
          color: #707070;
          display: block;
          margin-left: 7px;
        }
      }
    }

    .separator {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      p {
        flex: 0 0 auto;
        padding: 0 10px;
      }

      span {
        width: 100%;
        height: 1px;
        background-color: #ebebeb;
        display: block;
      }
    }

    .circular-boxs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 50px;

      .single-circ-content {
        position: relative;
        top: 15px;
        text-align: center;

        & > small {
          font-size: 12px;
          line-height: 0.3;
          text-transform: uppercase;
          font-weight: 800;
          color: #707070;
        }

        & > span {
          font-size: 24px;
          color: ${(props) => props.theme.primary};
        }

        span,
        small,
        strong {
          display: block;
        }

        div {
          display: flex;
          justify-content: center;
          font-weight: 700;

          span {
            display: inline-block;
            margin-left: 8px;
            color: ${(props) => props.theme.primary};
          }
        }
      }

      .single-circ {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        position: relative;
        background-color: #ebebeb;
        border: 10px solid #d8d8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;

        ${respondTo.md`
          margin: 0;
        `}

        .single-circ-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: ${(props) => props.theme.primary};
          position: absolute;
          top: -15px;
          right: -15px;
        }
      }
    }
  }

  .box-right {
    width: 100%;

    ${respondTo.md`
      width: 30%;
    `}

    .box-right-header {
      text-align: right;
    }

    .question-box {
      position: relative;
      display: flex;
      align-items: flex-start;
      background-color: #ebebeb;
      padding: 15px;
      border-radius: 24px;
      margin-top: 20px;
      padding-bottom: 110px;

      .question-content {
        padding-right: 30px;
        margin-left: 8px;

        span {
          font-size: 12px;
        }

        p {
          font-size: 24px;
          color: ${(props) => props.theme.primary};
        }

        strong {
          display: block;
          margin-top: 20px;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .question-points {
        position: absolute;
        left: 12px;
        bottom: 12px;
        background-color: #1bb410;
        border-radius: 16px;
        padding: 5px 12px;

        span {
          font-size: 12px;
          color: #00852a;
        }

        div {
          display: flex;
          align-items: center;

          strong {
            display: inline-block;
            margin-left: 5px;
            color: #fff;
          }
        }
      }
    }
  }
`;

const FromCommunity = styled.div`
  background-color: #ebebeb;
  border-radius: 24px;
  position: relative;
  top: 0;
  z-index: 12;
  padding: 50px 12px;

  ${respondTo.md`
    top: -50px;
  `}

  h3 {
    font-size: 44px;
    color: #1d241e;
  }

  .comm-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
      font-size: 18px;
      font-weight: 600;
    }

    a {
      font-size: 18px;
      font-weight: 600;
      color: #1bb410;
    }
  }

  .community-boxs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .single-box {
      width: 100%;
      border-radius: 24px;
      margin-bottom: 16px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(33.333% - 15px);
      `}

      .champ-box {
        position: relative;
        display: flex;
        align-items: center;
        padding: 30px 12px;
        border-radius: 24px;

        &.diesel {
          background-color: #1bb410;

          .info div strong {
            color: #00852a;
          }
        }
        &.level {
          background-color: #b19f00;

          .info div strong {
            color: #dec809;
          }
        }

        .image {
          width: 200px;
        }

        .info {
          padding-left: 16px;

          p {
            color: #1d241e;
            font-size: 12px;
            font-weight: 700;
          }

          span {
            font-size: 12px;
            color: #1d241e;
          }

          h5 {
            font-size: 24px;
            color: #fff;
          }

          div {
            font-size: 12px;
            display: flex;
            align-items: center;

            small {
              color: #fff;
              padding-left: 8px;
            }
          }
        }

        .champ-date {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 12px;
          color: #fff;
        }

        .image {
          position: relative;

          .circ-image {
            position: absolute;
            top: 40px;
            left: 38px;
          }
        }
      }

      .interview-box {
        overflow: hidden;
        background-color: #fff;
        border-radius: 24px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .info {
          display: flex;
          align-items: center;
          position: relative;
          padding: 15px;
          width: 100%;

          strong {
            display: block;
            font-size: 12px;
            font-weight: 700;
            color: #1d241e;
          }

          span {
            font-size: 12px;
          }

          h5 {
            color: ${(props) => props.theme.primary};
            font-size: 24px;
            line-height: 1;
            margin-bottom: 15px;
          }

          .interview-date {
            color: ${(props) => props.theme.color};
            position: absolute;
            right: 10px;
            bottom: 10px;
          }
        }

        .image {
          flex: 0 0 183px;
          height: 315px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`;

const Initiative = (props) => {
  const [isValid, setIsValid] = useState(true);
  const [showPromo, setShowPromo] = useState(false);

  const {
    appConfig: {
      settings: { multipromo },
    },
    products,
    isLogged,
    logo,
    promoinfo,
    getPromoViewer,
    loadingviewer,
    viewer,
    errorviewer,
    getCmsIntro,
    intro,
    loadingintro,
    errorintro,
    getGames,
    games,
    gamesError,
    loadingGames,
    oauths,
    ownLogin,
    oauthInfo,
    getPromoInfo,
    error404,
    partners,
    location: { pathname },
    wishlist,
    userInfo,
    highlights,
  } = props;

  let slug = "get";

  if (multipromo) {
    slug = getSlug();
  }

  let seo = null;

  if (Object.entries(promoinfo).length !== 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = promoinfo;
    seo = <Seo title={title} description={description} keywords={keywords} />;
  }

  useEffect(() => {
    setIsValid(true);
    setShowPromo(true);

    localStorage.setItem("lasturl", window.location.href);
  }, []);

  useEffect(() => {
    if (error404.length === 1) {
      setIsValid(false);
    }
  }, [error404]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  let content = null;

  const options = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  content = (
    <React.Fragment>
      <HomeSlider />
      {seo}

      <Situation>
        <div className="box-left">
          <div className="box-header">
            <h3>La tua situazione</h3>
            <Link to="/user/profilo">
              Dettagli <ChevronRight size={30} />
            </Link>
          </div>
          <div className="box-subheader">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/icons/trophy-big-icon.svg"}
                alt=""
              />
              <span>00°</span>
              <small>
                classifica
                <br />
                generale
              </small>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/icons/award-big-icon.svg"}
                alt=""
              />
              <span>5/10</span>
              <small>
                riconoscimenti
                <br />
                ottenuti
              </small>
            </div>
          </div>
          <div className="separator">
            <span />
            <p>
              000 giorni al termine del <strong>1° livello</strong>
            </p>
            <span />
          </div>

          <div className="circular-boxs">
            <div className="single-circ">
              <div className="single-circ-content">
                <small>Missione</small>
                <span>Postepay</span>
                <div>
                  60% <span>00˚</span>
                </div>
                <ChevronRight size={25} />
              </div>

              <div className="single-circ-icon">
                <img
                  src={process.env.PUBLIC_URL + "/icons/postepay-icon.svg"}
                  alt=""
                />
              </div>
            </div>

            <div className="single-circ">
              <div className="single-circ-content">
                <small>Missione</small>
                <span>Servito</span>
                <div>
                  60% <span>00˚</span>
                </div>
                <ChevronRight size={25} />
              </div>

              <div className="single-circ-icon">
                <img
                  src={process.env.PUBLIC_URL + "/icons/postepay-icon.svg"}
                  alt=""
                />
              </div>
            </div>

            <div className="single-circ">
              <div className="single-circ-content">
                <small>Missione</small>
                <span>Diesel +</span>
                <div>
                  60% <span>00˚</span>
                </div>
                <ChevronRight size={25} />
              </div>

              <div className="single-circ-icon">
                <img
                  src={process.env.PUBLIC_URL + "/icons/servito-icon.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="box-right">
          <div className="box-right-header">
            <h3>Dicci la tua</h3>
          </div>
          <div className="question-box">
            <img
              src={process.env.PUBLIC_URL + "/icons/diesel-icon.svg"}
              alt=""
            />
            <div className="question-content">
              <span>Piacere di conoscerti</span>
              <p>
                Testo della domanda lorem ipsum dolor sit amet sed adiscipit
                elit numquam?
              </p>
              <strong>
                Rispondi <ChevronRight size={30} />
              </strong>
            </div>
            <div className="question-points">
              <span>Puoi ottenere</span>
              <div>
                <img src={process.env.PUBLIC_URL + "/icons/gift.svg"} alt="" />
                <strong>000 PT</strong>
              </div>
            </div>
          </div>
        </div>
      </Situation>

      <FromCommunity>
        <div className="comm-header">
          <span style={{ width: 200 }}></span>
          <h3>Ultime dalla community</h3>
          <Link to="/community">
            Guarda tutti <ChevronRight size={30} />
          </Link>
        </div>

        <div className="community-boxs">
          <div className="single-box">
            <div className="champ-box diesel">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-green.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-diesel.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>Diesel +</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box level">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-yellow.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-level.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>Diesel +</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="interview-box">
              <div className="info">
                <div>
                  <strong>INTERVISTIAMO</strong>
                  <span>il campione</span>
                  <h5>Nickname</h5>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/interview-icon.svg"}
                    alt=""
                  />
                </div>
                <small className="interview-date">00/00/2022</small>
              </div>
              <div className="image">
                <img src={process.env.PUBLIC_URL + "/images/man.jpg"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </FromCommunity>

      <FromCatalog />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isValid ? (
        showPromo ? (
          <CustomContainer>{content}</CustomContainer>
        ) : (
          <CustomContainer></CustomContainer>
        )
      ) : (
        <NotFound internal type="promo" />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user.userinfo,
    partners: state.partner.partnerList,
    wishlist: state.user.user.wishlist,
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
    products: state.products.allProducts,
    highlights: state.promo.hgpromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCmsIntro: (slug) => dispatch(actionCreators.getCmsIntro(slug)),
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
    getPromoInfo: (slug, cid) =>
      dispatch(actionCreators.getPromoInfo(slug, cid)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Initiative)
);

import React, { useState } from "react";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import Parse from "html-react-parser";

const Wrapper = styled.div`
  padding: 15px 0;
  border-radius: 15px;
  background-color: ${(props) => (props.even ? "transparent" : "#ECF0FA")};

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;

    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .answer {
    padding: 0 15px;
  }
`;

const SingleFaq = ({ question, answer, even }) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper even={even}>
      <div className="question" onClick={() => setOpen(!open)}>
        <span>{question}</span>
        {open ? <ChevronUp size={30} /> : <ChevronDown size={30} />}
      </div>
      {open && (
        <div className="answer">
          <p>{Parse(answer)}</p>
        </div>
      )}
    </Wrapper>
  );
};

export default SingleFaq;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HamburgerContainer = styled.div`
  cursor: pointer;
`;

const Icon = styled.svg`
  g g {
    fill: ${(props) => props.theme.primary};
  }
`;

const Hamburger = (props) => {
  const { clicked } = props;

  return (
    <HamburgerContainer onClick={clicked}>
      <Icon width="30px" height="21px" viewBox="0 0 30 21" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill-rule="nonzero">
            <rect x="0" y="0" width="30" height="8"></rect>
            <rect opacity="0.4" x="0" y="13" width="23" height="8"></rect>
          </g>
        </g>
      </Icon>
    </HamburgerContainer>
  );
};

Hamburger.propTypes = {
  clicked: PropTypes.func,
};

export default Hamburger;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Code from "../Code";
import Prize from "../Prize";
import Form from "../../Form";
import { useSelector } from "react-redux";
import ResultDynamic from "../ResultDynamic";
import Fade from "../../../Fade";
import { Button } from "../../../Ui";
import { getElement } from "../../../../utils/utils";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { ChevronRight, X } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../../../theme/mixin";

const ResultContainer = styled.div`
  width: ${(props) =>
    props.type === "back-to-school" ? "auto" : "calc(100% - 20px)"};
  background: ${(props) =>
    props.type === "back-to-school"
      ? "transparent"
      : "rgba(255, 255, 255, 0.7)"};
  position: ${(props) =>
    props.type === "back-to-school" ? "relative" : "absolute"};
  left: ${(props) => (props.type === "back-to-school" ? "auto" : 0)};
  right: ${(props) => (props.type === "back-to-school" ? "0" : "auto")};
  top: 0;
  padding-top: ${(props) => (props.type === "back-to-school" ? 0 : "70px")};
  padding-right: 10px;
  width: ${(props) =>
    props.type === "back-to-school" ? "235px" : "calc(100% - 20px)"};
  text-align: ${(props) =>
    props.type === "back-to-school" ? "left" : "center"};
  height: ${(props) =>
    props.type === "back-to-school" ? "auto" : "calc(100% - 100px)"};
  z-index: 1;

  ${respondTo.md`
    right: ${(props) => (props.type === "back-to-school" ? "20px" : "auto")};
    padding-top: 70px;
    position: absolute;
  `}
`;

const GameWrapper = styled.div`
  margin: 20px 0;

  ${respondTo.md`
    margin: 0;
  `}
`;

const GameWin = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.type === "back-to-school" ? "flex-start" : "center"};
  margin-top: 10px;

  button {
    color: #fff;
  }

  .thunders {
    position: absolute;
    bottom: 0;
    opacity: 0;
    animation: thunders-move 0.3s 1s linear forwards;

    ${respondTo.md`
      right: 200px;
    `}
  }

  @keyframes thunders-move {
    from {
      opacity: 0;
      margin-bottom: 20px;
    }
    to {
      opacity: 1;
      margin-bottom: -25px;
    }
  }

  .bike {
    display: none;
    position: absolute;
    left: 100%;
    margin-left: -150px;
    animation: bike-move 1s linear;

    ${respondTo.md`
      display: block;
    `}
  }

  @keyframes bike-move {
    from {
      left: 50%;
    }
    to {
      left: 100%;
    }
  }
`;

const GameImage = styled.div`
  display: flex;
  justify-content: center;

  .thunder {
    position: absolute;
    margin-top: 60px;
    margin-left: 20px;
    width: 40px;

    ${respondTo.md`
      width: 55px;
    `}
  }

  .bike {
    width: 300px;

    ${respondTo.md`
      width: 370px;
    `}
  }
`;

const FormContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-form {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #6cb967;
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 5px 15px #00000026;
  }

  .form-content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 30px;

    ${respondTo.md`
      max-width: 80%;
      height: 80%;
      max-height: 90%;
      border-radius: 15px;
    `}
  }
`;

const ResultGame = (props) => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      log: { steps, won, code, error, form: formResponse },
      i18l,
      form,
    },
    lang,
    played,
    action,
    action: { cleanData },
    type,
    retry,
    remaining_attempts,
  } = props;

  useEffect(() => {
    return () => {
      cleanData(id);
    };
  }, []);

  const has_pending_play = useSelector(
    (state) => state.user.user.has_pending_play
  );
  const pending_list = useSelector((state) => state.user.user.wins);

  const [gamePending, setGamePending] = useState(false);

  useEffect(() => {
    if (pending_list && id) {
      const findGamePending = pending_list.find(
        (win) => win.game_id === id && win.pending === true
      );

      if (findGamePending) {
        setGamePending(true);
      }
    }
  }, [pending_list, id]);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};

  if (played) {
    theData = steps.interaction.data;
  } else {
    theData = theProfileData.data ? theProfileData.data : null;
  }

  let rulesData = JSON.parse(rules);
  let logData = theData ? JSON.parse(theData) : null;

  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
    };
  } else {
    theCodeData = { code: code, error: error };
  }

  let playslog = theProfileData ? theProfileData.playslog : log.playslog;
  let userWon = theProfileData ? theProfileData.won : won;
  let thePrize = theProfileData ? theProfileData.prize_image : log.prize_image;
  let confirm = theProfileData ? theProfileData.confirm : log.confirm;
  let withcode = theProfileData ? theProfileData.withcode : log.withcode;

  const {
    settings: {
      //aspect: { bgresult, bgresultcolor, loosecard, wincard },
      socialShare,
      summary,
    },
  } = rulesData;

  let resultDynamic = null;
  if (userWon) {
    resultDynamic = "win";
  } else {
    resultDynamic = "loose";
  }

  useEffect(() => {
    if (userWon) {
      if (userWon === 0) {
        ReactGA.event({
          category: "Game",
          action: "Perdita",
        });
      } else if (userWon === 1) {
        ReactGA.event({
          category: "Game",
          action: "Vincita",
        });
      }
    }
  }, [userWon]);

  let dynamicData = i18l[resultDynamic];

  let profileData = logData
    ? {
        socialShare,
        summary,
        profile: logData.profile,
        attempts: logData.attempts,
        nAttempts: logData.nAttempts,
      }
    : {};

  const [isConvalidaOpen, setIsConvalidaOpen] = useState(false);

  return (
    <>
      {isConvalidaOpen && userWon && form.type === "post" ? (
        <FormContainer>
          <div className="close-form" onClick={() => setIsConvalidaOpen(false)}>
            <X size={30} />
          </div>
          <div className="form-content">
            <Form
              has_pending_play={has_pending_play}
              form={form}
              formResponse={formResponse}
              lang={lang}
              id={id}
              playslog={playslog}
              played={played}
            />
          </div>
        </FormContainer>
      ) : (
        <ResultContainer type={type}>
          <GameWrapper>
            {won !== null || theProfileData.won !== null ? (
              <ResultDynamic dynamicData={dynamicData} userWon={userWon} />
            ) : null}
            {userWon ? (
              <GameWin type={type}>
                {gamePending && (
                  <Button
                    upper
                    gradient={{ left: "#6CB967", right: "#96F290" }}
                    hoverGradient={{ left: "#96F290", right: "#96F290" }}
                    onClick={() => setIsConvalidaOpen(true)}
                  >
                    {type === "back-to-school"
                      ? "Richiedili"
                      : dynamicData.extra}
                    <ChevronRight size={30} />
                  </Button>
                )}

                {type !== "back-to-school" && (
                  <>
                    <img
                      className="thunders"
                      src={process.env.PUBLIC_URL + "/icons/thunders-icon.svg"}
                      alt=""
                    />
                    <img
                      className="bike"
                      src={process.env.PUBLIC_URL + "/icons/bike-play-icon.svg"}
                      alt=""
                    />
                  </>
                )}
              </GameWin>
            ) : (
              <>
                {type !== "back-to-school" ? (
                  <GameImage>
                    <img
                      className="thunder"
                      src={process.env.PUBLIC_URL + "/icons/thunder-icon.svg"}
                      alt=""
                    />
                    <img
                      className="bike"
                      src={process.env.PUBLIC_URL + "/icons/bike-play-icon.svg"}
                      alt=""
                    />
                  </GameImage>
                ) : (
                  <>
                    {remaining_attempts > 0 && (
                      <Button
                        upper
                        style={{ marginTop: 10 }}
                        gradient={{ left: "#6CB967", right: "#96F290" }}
                        hoverGradient={{ left: "#96F290", right: "#96F290" }}
                        onClick={retry}
                      >
                        Ritenta
                        <ChevronRight size={30} />
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </GameWrapper>
        </ResultContainer>
      )}
    </>
  );
};

export default ResultGame;

import React from "react";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import { PlusCircle } from "@styled-icons/boxicons-regular";
import { Link } from "react-router-dom";

const ProfileContainer = styled.div`
  .slide {
    z-index: 11;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 25px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 15px;
      `}

      .content-info {
        max-width: 540px;
        color: #1d241e;

        h3 {
          font-size: 44px;
          margin-bottom: 10px;
        }
      }

      .personal-awards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        .award-box {
          width: 230px;
          margin-top: 30px;
          margin-right: 30px;

          strong {
            font-size: 12px;
            color: #1d241e;
            text-transform: uppercase;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-size: 64px;
              color: #00852a;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;

const Community = (props) => {
  return (
    <>
      <ProfileContainer>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/community-bg.png"}
            alt=""
          />

          <div className="content">
            <div>
              <div className="content-info">
                <h3>Dalla nostra community</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                  nonumy eirmod tempor invidunt. Pisum dolor sit amet,
                  consetetur sadipscing elitr, sed diam n diam nonumy eirmo
                  umquam sed adiscipit nonumy eirmod tempor invidunt adiscipit
                  nonumy eirmod.
                </p>
              </div>
              <div className="personal-awards">
                <div className="award-box">
                  <strong>Novità dalla community</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/share-big-icon.svg"}
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileContainer>

      <YourShare>
        <h3>Le tue condivisioni</h3>

        <div className="your-boxs">
          <div className="single-box">
            <div className="champ-box diesel">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-lightgreen.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-book.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>ha completato il</span>
                <h5>1˚ corso</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box single-mypage">
            <div>
              <strong>Condividi qualcosa</strong>
              <span>Vai alla My Page</span>
              <PlusCircle size={100} />

              <Link to="/user/profilo">
                <Button bg="#fff" color="#000">
                  My Page
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </YourShare>

      <SocialWall>
        <h3>Il social wall</h3>

        <div className="your-boxs">
          <div className="single-box">
            <div className="champ-box diesel">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-green.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-diesel.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>Diesel +</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box level">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-yellow.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-level.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>1˚ livello</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="interview-box">
              <div className="info">
                <div>
                  <strong>INTERVISTIAMO</strong>
                  <span>il campione</span>
                  <h5>Nickname</h5>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/interview-icon.svg"}
                    alt=""
                  />
                </div>
                <small className="interview-date">00/00/2022</small>
              </div>
              <div className="image">
                <img src={process.env.PUBLIC_URL + "/images/man.jpg"} alt="" />
              </div>
            </div>
          </div>

          <div className="single-box">
            <div className="interview-box order-box">
              <div className="info">
                <div>
                  <strong>NICKNAME</strong>
                  <span>ha ordinato</span>
                  <h5>Nome Premio</h5>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/order-box-icon.svg"}
                    alt=""
                  />
                </div>
                <small className="interview-date">00/00/2022</small>
              </div>
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/images/mouse.jpeg"}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box servito">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-gray.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/icons/champion-hand-icon.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>ha conquistato il badge</span>
                <h5>Servito</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box servito">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-gray.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/icons/champion-hand-icon.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>ha conquistato il badge</span>
                <h5>Servito</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box level">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-yellow.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-level.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>1˚ livello</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box servito">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-gray.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/icons/champion-hand-icon.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>ha conquistato il badge</span>
                <h5>Servito</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>

          <div className="single-box">
            <div className="champ-box diesel">
              <div className="image">
                <img
                  src={process.env.PUBLIC_URL + "/icons/circle-green.svg"}
                  alt=""
                />
                <img
                  className="circ-image"
                  src={process.env.PUBLIC_URL + "/images/champion-diesel.svg"}
                  alt=""
                />
              </div>
              <div className="info">
                <p>NICKNAME</p>
                <span>è il campione di</span>
                <h5>Diesel +</h5>
                <div>
                  <strong>000 PT</strong>
                  <small>#HASTAG_SCELTO</small>
                </div>
              </div>
              <span className="champ-date">00/00/2022</span>
            </div>
          </div>
        </div>
      </SocialWall>
    </>
  );
};

const SocialWall = styled.div`
  background-color: #ebebeb;
  border-radius: 24px;
  z-index: 11;
  position: relative;
  padding: 50px 12px;

  h3 {
    text-align: center;
    font-size: 44px;
    color: #1d241e;
    margin-bottom: 15px;
  }

  .your-boxs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .single-box {
      width: 100%;
      border-radius: 24px;
      margin-bottom: 24px;

      ${respondTo.md`
        width: calc(33.333% - 15px);
      `}

      .champ-box {
        position: relative;
        display: flex;
        align-items: center;
        padding: 30px 12px;
        border-radius: 24px;

        &.share {
          background-color: #00852a;
        }

        &.servito {
          background-color: #94a2ac;
        }

        &.diesel {
          background-color: #1bb410;

          .info div strong {
            color: #00852a;
          }
        }
        &.level {
          background-color: #b19f00;

          .info div strong {
            color: #dec809;
          }
        }

        .image {
          width: 200px;
        }

        .info {
          padding-left: 16px;

          p {
            color: #1d241e;
            font-size: 12px;
            font-weight: 700;
          }

          span {
            font-size: 12px;
            color: #1d241e;
          }

          h5 {
            font-size: 24px;
            color: #fff;
          }

          div {
            font-size: 12px;
            display: flex;
            align-items: center;

            small {
              color: #fff;
              padding-left: 8px;
            }
          }
        }

        .champ-date {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 12px;
          color: #fff;
        }

        .image {
          position: relative;

          .circ-image {
            position: absolute;
            top: 40px;
            left: 38px;
          }
        }
      }

      .interview-box {
        overflow: hidden;
        background-color: #fff;
        border-radius: 24px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .info {
          display: flex;
          align-items: center;
          position: relative;
          padding: 15px;
          width: 100%;

          strong {
            display: block;
            font-size: 12px;
            font-weight: 700;
            color: #1d241e;
          }

          span {
            font-size: 12px;
          }

          h5 {
            color: ${(props) => props.theme.primary};
            font-size: 24px;
            line-height: 1;
            margin-bottom: 15px;
          }

          .interview-date {
            color: ${(props) => props.theme.color};
            position: absolute;
            right: 10px;
            bottom: 10px;
          }
        }

        .image {
          flex: 0 0 183px;
          height: 315px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`;

const YourShare = styled.div`
  margin-bottom: -50px;
  background-color: #fff;
  position: relative;
  padding: 50px 12px;

  ${respondTo.md`
    top: -30px;
  `}

  h3 {
    color: #1d241e;
    font-size: 44px;
    margin-bottom: 15px;
  }

  .your-boxs {
    display: flex;
    flex-wrap: wrap;

    .single-mypage {
      background-color: #ebebeb;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 30px 0;
      padding: 24px 0;

      ${respondTo.md`
        padding: 0;
        margin: 0 0 0 24px;
      `}

      & > div {
        text-align: center;

        button {
          margin: 15px auto 0 auto;
        }

        svg {
          color: #fff;
        }

        strong,
        span {
          display: block;
          text-align: center;
          color: #94a2ac;
        }

        strong {
          font-size: 12px;
          text-transform: uppercase;
        }

        span {
          font-size: 24px;
          margin-bottom: 15px;
        }
      }
    }

    .single-box {
      width: 100%;
      border-radius: 24px;

      ${respondTo.md`
        width: calc(33.333% - 15px);
      `}

      .champ-box {
        position: relative;
        display: flex;
        align-items: center;
        padding: 30px 12px;
        border-radius: 24px;

        &.diesel {
          background-color: #00852a;

          .info div strong {
            color: #00852a;
          }
        }

        .image {
          width: 200px;
        }

        .info {
          padding-left: 16px;

          p {
            color: #1d241e;
            font-size: 12px;
            font-weight: 700;
          }

          span {
            font-size: 12px;
            color: #1d241e;
          }

          h5 {
            font-size: 24px;
            color: #fff;
          }

          div {
            font-size: 12px;
            display: flex;
            align-items: center;

            small {
              color: #fff;
              padding-left: 8px;
            }
          }
        }

        .champ-date {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 12px;
          color: #fff;
        }

        .image {
          position: relative;

          .circ-image {
            position: absolute;
            top: 40px;
            left: 38px;
          }
        }
      }
    }
  }
`;

export default Community;

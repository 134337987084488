import React, { useEffect, useState } from "react";
import * as actionCreators from "../../store/actions";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import "slick-carousel/slick/slick.css";
import { Link } from "react-router-dom";
import { PlayCircle, ChevronRight } from "@styled-icons/boxicons-regular";
import ProductBox from "../ProductBox/ProductBox";

const FromCatalogWrapper = styled.div`
  background-color: #fff;
  position: relative;
  top: 0;
  margin-bottom: 0;
  padding: 50px 12px 70px 12px;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${respondTo.md`
    top: -70px;
    margin-bottom: -80px;
  `}

  h3 {
    font-size: 44px;
    margin-bottom: 20px;
  }

  .elearning-wrapper {
    width: 100%;

    ${respondTo.md`
      width: calc(33.333% - 15px);
    `}

    h3 {
      color: ${(props) => props.theme.primary};
    }

    .elearning-content {
      background-color: #00852a;
      border-radius: 24px;
      padding: 16px;

      .e-header {
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          color: #fff;
        }

        h5 {
          font-size: 24px;
          color: #1bb410;
        }
      }

      .e-body {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        & > div {
          width: calc(50% - 10px);
        }

        p {
          color: #1d241e;
          font-size: 14px;
        }

        .progress-wrap {
          width: 100%;
          height: 12px;
          background-color: #fff;
          border-radius: 10px;
          position: relative;
          margin-top: 15px;
          margin-bottom: 5px;

          &:after {
            content: "";
            display: block;
            width: 70px;
            height: 8px;
            position: absolute;
            background-color: #1bb410;
            border-radius: 10px;
            top: 2px;
            left: 2px;
          }
        }

        .points-box {
          background-color: #1bb410;
          width: 105px;
          margin-top: 25px;
          border-radius: 16px;
          padding: 5px 12px;

          span {
            font-size: 12px;
            color: #00852a;
          }

          div {
            display: flex;
            align-items: center;

            strong {
              display: inline-block;
              margin-left: 5px;
              color: #fff;
            }
          }
        }

        span {
          color: #fff;
          font-size: 12px;
        }

        .play-video {
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          width: auto;
          height: 120px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;

          ${respondTo.md`
            width: 185px;
          `}

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          div {
            position: relative;
          }
        }

        a {
          display: block;
          margin-top: 75px;
          text-align: right;
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }

  .catalog-wrapper {
    width: 100%;

    ${respondTo.md`
      width: calc(66.666% - 15px);
    `}

    .catalog-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      h3 {
        color: #1d241e;
      }

      a {
        color: #1bb410;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .catalog-content {
      padding: 15px;
      border-radius: 24px;
      background-color: #ebebeb;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cat-box {
        width: 100%;

        ${respondTo.md`
          width: calc(25% - 12px);
        `}
      }
    }
  }
`;

const FromCatalog = (props) => {
  const products = useSelector((state) => state.products.products);

  return (
    <FromCatalogWrapper>
      <div className="elearning-wrapper">
        <h3>E-learning</h3>
        <div className="elearning-content">
          <div className="e-header">
            <img
              src={process.env.PUBLIC_URL + "/icons/elearning-book-icon.svg"}
              alt=""
            />
            <div>
              <span>E-learning</span>
              <h5>Titolo e-learning</h5>
            </div>
          </div>
          <div className="e-body">
            <div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </p>
              <div className="progress-wrap" />
              <span>
                <strong>1 di 5</strong> corsi completati
              </span>
              <div className="points-box">
                <span>Puoi ottenere</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div>
              <div className="play-video">
                <img src={process.env.PUBLIC_URL + "/images/man.jpg"} alt="" />
                <div>
                  <PlayCircle size={42} />
                </div>
              </div>
              <Link to="/e-learning">
                Completa <ChevronRight size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="catalog-wrapper">
        <div className="catalog-header">
          <h3>Dal nostro catalogo</h3>
          <Link to="/catalogo">
            Guarda tutti <ChevronRight size={30} />
          </Link>
        </div>

        <div className="catalog-content">
          {products.slice(0, 4).map((prod) => (
            <ProductBox product={prod} />
          ))}
        </div>
      </div>
    </FromCatalogWrapper>
  );
};

export default FromCatalog;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { firstAccessForm } from "../../formsConfig/formsConfig";
import { Input, Button, Loader } from "../../components/Ui";
import { ChevronRight, ChevronLeft } from "@styled-icons/boxicons-regular";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { authInit } from "../../store/actions";
import { respondTo } from "../../theme/mixin";
import { activateUser } from "../../store/actions/auth";

const FirstAccess = (props) => {
  const dispatch = useDispatch();

  //const [step, setStep] = useState(1);

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const firstAccess = useSelector((state) => state.user.user.first_access_done);
  const loading = useSelector((state) => state.user.firstAccessLoading);

  const activateUserHanlder = () => {
    dispatch(activateUser());
  };

  useEffect(() => {
    if (firstAccess !== undefined && firstAccess) {
      props.history.push("/");
    }
  }, [firstAccess, props.history]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(activateUserHanlder, firstAccessForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });

  return (
    <div className="container">
      <Wrapper>
        <ContentBorderBox color="#EB5A3F">
          <div className="sides-wrapper">
            <div className="bg-image">
              <img
                src={process.env.PUBLIC_URL + "/images/banner-first-access.png"}
                alt=""
              />
            </div>
            <div className="left-side">
              <div className="access-header">
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
              </div>
              <h3>Ciao, {userInfo ? userInfo.firstname : null}</h3>

              <p>
                Ecco il nuovo MYLINKPLUS: scopri tutte le novità, i vantaggi e
                le tante <br />
                occasioni per vincere.
              </p>

              <strong>
                Che aspetti?
                <br />
                Completa subito la tua iscrizione e accedi!
              </strong>
            </div>

            <div className="right-side">
              <h4>Consensi</h4>

              <p>
                <strong>Per completare l'iscrizione</strong> e aderire al
                programma fedeltà <strong>MYLINKPLUS 2021/22</strong>, dichiaro
                di aver <strong>letto</strong> e <strong>accettato</strong>:
              </p>

              <div className="single-wrapper">
                <div className="single-consens">{inputs[0]}</div>
                <div className="single-consens">{inputs[1]}</div>
              </div>
              <form onSubmit={formSubmitHandler}>
                <Button
                  center
                  fullwidth
                  gradient={{ left: "#eb5c3f", right: "#FCC546" }}
                  upper
                  disabled={loading}
                >
                  {loading ? (
                    <Loader show={loading} color="#fff" inBtn />
                  ) : (
                    <>
                      Iscriviti
                      <ChevronRight size={30} />
                    </>
                  )}
                </Button>
                <small>ATTENZIONE: Tutti i campi sono obbligatori.</small>
              </form>
            </div>
          </div>
        </ContentBorderBox>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin-top: 30px;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;

  ${respondTo.sm`
    padding: 0;
    margin-top: 80px;
  `}

  .bg-image {
    position: absolute;
    right: -35px;
    top: -15px;
    height: calc(100% + 30px);
    width: auto;
    display: none;

    img {
      width: 100%;
      height: 100%;
    }

    ${respondTo.sm`
      display: block;
    `};
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 50px;

    .single-consens {
      width: 100%;
      box-shadow: 0px 5px 15px #00000026;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 15px;
      height: 44px;
      padding: 0 15px;
      line-height: 44px;

      a {
        color: #eb5a3f;
        font-weight: 700;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${respondTo.sm`
      flex-wrap: nowrap;
    `};

    .left-side {
      width: 260px;

      h3 {
        font-size: 21px;
        font-weight: 700;
        margin-top: 25px;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #f2a55b;
      }

      strong {
        font-size: 18px;
        color: #f2a55b;
      }
    }

    .right-side {
      width: 270px;
      z-index: 1;

      h4 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 15px;
      }

      p {
        font-size: 12px;
        margin-bottom: 25px;
      }

      button {
        justify-content: center;
      }

      small {
        color: #ffcb98;
        font-weight: 700;
        margin-top: 15px;
        font-size: 10px;
        display: block;
        text-align: center;
      }
    }
  }
`;

export default FirstAccess;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { MechanicGame, MechanicGoToGame, MechanicBox } from "./Mechanics";
import { gameFlowStart, gameFlowEnd } from "../../store/actions";

const RouteGames = (props) => {
  const {
    g: {
      ingame,
      interaction: { page },
    },
  } = props;

  let content = null;

  if (ingame) {
    content = <MechanicGame {...props} />;
  } else {
    if (page) {
      content = <MechanicGoToGame {...props} />;
    } else {
      content = <MechanicBox {...props} />;
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gameFlowStart());

    return () => {
      dispatch(gameFlowEnd());
    };
  }, [dispatch]);

  return <React.Fragment>{content}</React.Fragment>;
};

RouteGames.propTypes = {
  withpage: PropTypes.bool,
  ingame: PropTypes.bool,
};

export default RouteGames;

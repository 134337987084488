import React, { useState, useEffect } from "react";
import CardElement from "./CardElement";
import { getSlug } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import styled from "styled-components";
import { Button } from "../../../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const GameContent = styled.div`
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;

  .back-button {
    position: absolute;
    left: 200px;
    bottom: -10px;
  }
`;

const Card = (props) => {
  const {
    type,
    onShowResult,
    typeInteraction,
    g: {
      id,
      i18l,
      interaction,
      preinteraction,
      log: { sid },
    },
    action: { data: dataAction, loading },
    canPlay,
  } = props;

  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState(false);
  const [loader, setLoader] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  let {
    settings: {
      numberofcards,
      aspect: { brand, bggamecolor, bgresultcolor },
    },
  } = rules;

  if (type === "back-to-school") {
    numberofcards = 1;
    brand = "";
  }

  useEffect(() => {
    let data = [];
    for (let i = 0; i < numberofcards; i++) {
      data.push({
        id: i,
        open: false,
        brand,
      });
    }
    setFinalCards(data);
  }, []);

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 200);
    }
  }, [dataAction]);

  const clickCardHandler = (index) => {
    finalCards[index].open = true;
    setAction(true);
  };

  return (
    <>
      <GameContent>
        {finalCards.map((card, index) => {
          return (
            <CardElement
              remaining_attempts={props.remaining_attempts}
              key={card.id}
              index={index}
              brand={card.brand}
              isOpen={card.open}
              result={dataAction}
              idGame={id}
              clicked={() => clickCardHandler(index)}
            />
          );
        })}
      </GameContent>
      <span className="extra">{i18l.info.extra}</span>

      {action ? (
        <Dynamic
          id={id}
          sid={sid}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          {...props}
        />
      ) : null}
    </>
  );
};

Card.propTypes = {};

export default Card;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { Button, Loader, Modal } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../theme/mixin";
import { ChevronLeft } from "@styled-icons/boxicons-regular";
import { getSingleProduct, openOrderModal } from "../../store/actions/products";
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
} from "../../store/actions/wishlist";
import parse from "html-react-parser";

const ProductWrapper = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  ${respondTo.md`
    flex-wrap: nowrap;
  `}

  .product-image {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 24px;
    padding: 30px;
    overflow: hidden;
    height: 250px;
    margin: 0 15px;

    ${respondTo.md`
      width: calc(40% - 15px);
      height: auto;
      margin: 0;
    `}

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .add-to-wishlist {
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${(props) => props.theme.primary};
      }
    }
  }

  .product-info {
    width: 100%;
    padding: 0 15px;
    margin-top: 40px;
    margin-bottom: 30px;

    ${respondTo.md`
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: calc(60% - 15px);
    `}

    .prod-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .info-box-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
      `}

      .header-box {
        display: flex;
        width: 100%;
        margin: 7px 0;

        ${respondTo.md`
          margin: 0;
          width: auto;
        `}

        .box-info {
          margin-left: 5px;

          p {
            font-size: 12px;
            color: #94a2ac;
            text-transform: uppercase;
            font-weight: 700;
          }

          strong {
            color: #adb1bc;
            font-weight: 400;
            font-size: 24px;
            line-height: 1;
          }

          &.primary {
            p {
              color: #1bb410;
            }

            strong {
              color: #00852a;
            }
          }
        }
      }
    }

    h3 {
      font-size: 44px;
      color: #1d241e;
      margin-bottom: 10px;
    }

    .product-shipment {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 30px;

      p {
        font-size: 14px;
        color: #ff7db9;
        margin-left: 5px;
      }
    }

    .product-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .product-back {
        font-size: 18px;
        color: #47545d;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
`;

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [inWishlist, setInWishlist] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);

  const wishlist = useSelector((state) => state.user.user.wishlist);
  const product = useSelector((state) => state.product.product);
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.product.loading);
  const isLogged = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find((el) => el.id === product.mProductId);
      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct(id));
    }
  }, [dispatch, id]);

  const openOrderModalHandler = () => {
    const selectedProd = products.find(
      (prod) => prod.id === product.mProductId
    );

    if (selectedProd) {
      dispatch(openOrderModal(selectedProd));
    }
  };

  const addToWishlistHandler = (productId) => {
    dispatch(addToWishlist(productId));
  };

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  return (
    <>
      <ProductWrapper>
        {loading ? (
          <Loader show={loading} />
        ) : (
          <>
            <div className="product-image">
              <img src={product.mImg} alt="" />
              <div
                className="add-to-wishlist"
                onClick={() => {
                  if (!isLogged) {
                    setModalAccess(true);
                  } else if (inWishlist) {
                    removeFromWishlistHandler(product.mProductId);
                  } else {
                    addToWishlistHandler(product.mProductId);
                  }
                }}
              >
                {inWishlist ? (
                  <img
                    src={process.env.PUBLIC_URL + "/icons/heart-full-icon.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/icons/heart-icon.svg"}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="product-info">
              <ContentBorderBox position="right" fullheight>
                <div>
                  <div className="info-box-header">
                    <div className="header-box">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/product-points-icon.svg"
                        }
                        alt=""
                      />
                      <div className="box-info primary">
                        <p>Punti</p>
                        <strong>{product.mProductPoints}</strong>
                      </div>
                    </div>
                    <div className="header-box">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/product-category-icon.svg"
                        }
                        alt=""
                      />
                      <div className="box-info">
                        <p>Categoria</p>
                        <strong>{product.mCategoria}</strong>
                      </div>
                    </div>
                    <div className="header-box">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/product-code-icon.svg"
                        }
                        alt=""
                      />
                      <div className="box-info">
                        <p>Codice prodotto</p>
                        <strong>{product.mCodiceEvasione}</strong>
                      </div>
                    </div>
                  </div>

                  <span>{product.mProductBrand}</span>

                  <h3>{parse(product.mProductShortDescription || "")}</h3>

                  <p>{parse(product.mProductDescription || "")}</p>
                </div>

                <div className="product-buttons">
                  <div
                    className="product-back"
                    onClick={() => history.goBack()}
                  >
                    <ChevronLeft size={24} /> Indietro
                  </div>

                  <Button
                    color="#fff"
                    onClick={() => dispatch(addToCart(product.mProductId))}
                  >
                    Metti nel carrello
                  </Button>
                </div>
              </ContentBorderBox>
            </div>
          </>
        )}
      </ProductWrapper>
      <Modal show={modalAccess} close={() => setModalAccess(false)}>
        Devi eseguire l'accesso prima di inserire prodotti in wishlist.
        <Link
          to={{
            pathname: "https://mylinkempage.linkem.com/User/Login",
          }}
          target="_blank"
        >
          <Button
            upper
            gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
            style={{ marginTop: 20 }}
          >
            Accedi
            <ChevronRight size={30} />
          </Button>
        </Link>
      </Modal>
    </>
  );
};

export default Product;

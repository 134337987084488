import styled from "styled-components";
import PropTypes from "prop-types";
import { css } from "styled-components";
import { darken } from "polished";

const Button = styled.button`
  border-radius: 24px;
  border: 0;
  display: inline-flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  outline: none;
  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.small ? "0 1rem" : "0 1.5625rem")};
  min-width: ${(props) => (props.fullwidth ? "100%" : "")};
  background-color: ${(props) => (props.bg ? props.bg : "#1bb410")};
  height: ${(props) => (props.small ? "30px" : "64px")};
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? ".5" : "1")};

  &:hover {
    color: #00852a;
  }
`;

Button.defaultProps = {
  color: "#000",
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
};

export default Button;

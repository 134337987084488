import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { registrationform } from "../../formsConfig/formsConfig";
import { Button, Input, Loader, Modal } from "../Ui";
import ErrorInModal from "../ErrorInModal";
import RegisterFormSuccess from "./RegisterFormSuccess";
import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import parse from "html-react-parser";

const AccediBtnContainer = styled.div`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 20px 0 20px;
`;

const Disclaimer = styled.div`
  margin-top: 20px;
  color: #6366697f;
`;

const AccessWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 6px #63666919;
  border-radius: 20px;
  padding: 20px;

  & input {
    background-color: #63666919;
  }

  input::placeholder {
    color: #6366697f;
  }

  & label {
    color: #6366697F;
    font-size: 18px;
    text-align: left;
    align-items: flex-start;

    a {
      color: ${(props) => props.theme.primary}
      text-decoration: underline;
    }

    div {
      color: ${(props) => props.theme.secondary};
    }
  }

  .troll {
    color: red;
  }
`;

const RegisterForm = (props) => {
  const {
    signUpInit,
    loadingSignup,
    signup,
    clearError,
    error,
    lang,
    own_login_adult,
    fields,
    notes,
    extrafield: { label, visible, required, regex },
  } = props;

  let code = {};
  if (visible) {
    code = {
      code: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "code",
          id: "code",
          placeholder: "Codice cliente",
        },
        label: "",
        value: "",
        validation: {
          isCustomCode: true,
          regex: regex,
          required: required,
          touched: false,
        },
        valid: !required,
      },
    };
  }

  const [showModal, setShowModal] = useState(false);
  const [t] = useTranslation();
  const inputRef = useRef();
  const registerForm = registrationform(
    t("auth.email"),
    t("auth.firstname"),
    t("auth.lastname"),
    t("auth.psw"),
    t("auth.pswrepeat"),
    t("auth.pswerror"),
    t("auth.pswconfirmerror"),
    t("auth.fiscalcode"),
    t("auth.phone"),
    t("auth.district"),
    t("auth.city"),
    t("auth.zipcode"),
    t("auth.company"),
    t("auth.mgm"),
    inputRef
  );

  const registerFormfiltered = Object.keys(registerForm)
    .filter((key) => fields.includes(key))
    .reduce((obj, key) => {
      obj[key] = registerForm[key];
      return obj;
    }, {});

  let sortedObj = {};
  fields.forEach((item) => {
    sortedObj[item] = registerFormfiltered[item];
  });

  const finalForm = { ...sortedObj, ...code };

  const register = () => {
    const data = {};
    for (const property in formData) {
      if (formData[property].valuetosend) {
        data[property] = formData[property].valuetosend;
      } else {
        data[property] = formData[property].value;
      }
    }
    signUpInit(data);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
    clearError();
  };

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);

  let datePickerNames = [];

  for (var prop in finalForm) {
    if (finalForm[prop].elementType === "datepicker") {
      datePickerNames.push(finalForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    districts,
    cities,
    zipcode,
  } = useForm(register, finalForm, datePickerNames);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        lang={lang}
        own_login_adult={own_login_adult}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        {...inp}
      />
    );
  });

  return (
    <React.Fragment>
      {signup ? (
        <RegisterFormSuccess />
      ) : (
        <React.Fragment>
          <AccessWrapper>
            <form onSubmit={formSubmitHandler}>
              {inputs}
              <AccediBtnContainer>
                <Button type="submit" upper fullwidth disabled={loadingSignup}>
                  {loadingSignup ? (
                    <Loader inBtn show={true} />
                  ) : (
                    t("auth.registerlabel")
                  )}
                </Button>
              </AccediBtnContainer>
            </form>
          </AccessWrapper>

          <Disclaimer>{parse(notes)}</Disclaimer>
        </React.Fragment>
      )}
      <Modal show={showModal} close={modalHandler}>
        <ErrorInModal error={error} />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingSignup: state.auth.loadingSignup,
    signup: state.auth.signup,
    error: state.auth.errorSignup,
    lang: state.app.config.lang,
    own_login_adult: state.app.config.ownlogin.adult,
    fields: state.app.config.ownlogin.fields,
    notes: state.app.config.ownlogin.notes,
    extrafield: state.app.config.ownlogin.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUpInit: (data) => dispatch(actionCreators.signUpInit(data)),
    clearError: () => dispatch(actionCreators.signupClearError()),
  };
};

RegisterForm.propTypes = {
  signUpInit: PropTypes.func,
  loadingSignup: PropTypes.bool,
  signup: PropTypes.bool,
  clearError: PropTypes.func,
  error: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

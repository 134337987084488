import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { signinform } from "../../formsConfig/formsConfig";
import { Button, SmallLink, Input, Modal, Loader } from "../Ui";
import ErrorInModal from "../ErrorInModal";
import { MoreOauths } from "../Oauths";
import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import { getSlug } from "../../utils/utils";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { respondTo } from "../../theme/mixin";
import parse from "html-react-parser";

const AccediBtnContainer = styled.div``;

const AccessWrapper = styled.div`
  background-image: url("/images/login-bg.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  box-shadow: 0 3px 6px #63666919;
  border-radius: 24px;
  padding: 15px;
  min-height: 640px;
  margin: 30px 15px;

  ${respondTo.sm`
    margin: 60px 0 0 0;
    padding: 24px;
  `}

  form {
    background-color: #fff;
    width: 100%;
    border-radius: 24px;
    padding: 16px;

    ${respondTo.sm`
      width: 520px;
    `}

    .accessLogo {
      text-align: center;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 44px;
    }

    h3,
    p {
      color: #1d241e;
    }

    p {
      margin-bottom: 30px;
    }
  }

  input {
    background-color: #63666919;
    margin-bottom: 20px;
  }

  input::placeholder {
    color: #6366697f;
  }
`;

const RecoverPassword = styled.div`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 0 5px 0;

  a {
    font-size: 16px;
    color: #47545d;

    span {
      text-decoration: underline;
      color: ${(props) => props.theme.primary};
    }
  }
`;

const SigninForm = (props) => {
  const {
    inModal,
    error,
    clearError,
    signinInit,
    loading,
    redirect,
    whereToGo,
    oauths,
    oauthInfo,
    isOauth,
    oauthSendToken,
    notes,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const inputRef = useRef();
  const lastLocation = useLastLocation();
  const signInForm = signinform(inputRef, inModal);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    if (error !== null) {
      // if (error.status) {
      //   setShowModal(true);
      // }
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);

  const login = () => {
    signinInit(formData.email.value, formData.password.value);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit } =
    useForm(login, signInForm);

  const [t] = useTranslation();

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
      />
    );
  });

  let oauthsContent = null;
  if (oauths !== undefined) {
    if (oauths.length > 0) {
      oauthsContent = (
        <MoreOauths
          oauths={oauths}
          oauthInfo={oauthInfo}
          inModal={inModal}
          whereToGo={whereToGo}
          isOauth={isOauth}
          oauthSendToken={oauthSendToken}
          lastLocation={lastLocation}
          redirect={redirect}
        />
      );
    }
  }

  if (redirect) {
    let to = whereToGo;
    if (lastLocation !== null) {
      if (
        lastLocation.pathname.split("/")[1] === "activate" ||
        lastLocation.pathname.split("/")[1] === "reset-password" ||
        lastLocation.pathname.split("/")[1] === "auth"
      ) {
        to = "/";
      } else {
        to = lastLocation.pathname;
      }
    }
    if (inModal) {
      to = `/${getSlug()}`;
    }
    return <Redirect to={to} />;
  } else {
    return (
      <React.Fragment>
        <AccessWrapper>
          <form onSubmit={formSubmitHandler}>
            <div className="accessLogo">
              <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
            </div>
            <h3>Benvenuti</h3>
            <p>
              Stet clita kasd gubergren, no sea <strong>takimata</strong>{" "}
              sanctus est Lorem <strong>ipsum dolor</strong> sit amet.
            </p>
            {inputs}
            <AccediBtnContainer>
              <Button type="submit" disabled={loading}>
                {loading ? <Loader inBtn show={true} /> : t("auth.signinlabel")}
              </Button>
            </AccediBtnContainer>
            <RecoverPassword>
              <Link to="/auth/recovery-password">
                Password dimenticata? <span>Clicca qui</span>
              </Link>
            </RecoverPassword>
          </form>
        </AccessWrapper>

        {oauthsContent}
        <Modal show={showModal} close={modalHandler}>
          <ErrorInModal error={error} />
        </Modal>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.token,
    redirect: state.auth.redirect,
    error: state.auth.errorsignin,
    loading: state.auth.loadingsignin,
    oauths: state.app.config.oauths,
    oauthInfo: state.app.oauthInfo,
    isOauth: state.auth.isOauth,
    notes: state.app.config.ownlogin.notes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signinInit: (email, password) =>
      dispatch(actionCreators.signinInit(email, password)),
    clearError: () => dispatch(actionCreators.signinClearError()),
    oauthSendToken: (code, connect, data) =>
      dispatch(actionCreators.oauthSendToken(code, connect, data)),
  };
};

SigninForm.defaultProps = {
  inModal: false,
};

SigninForm.propTypes = {
  inModal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.number,
  redirect: PropTypes.bool,
  whereToGo: PropTypes.string,
  clearError: PropTypes.func,
  signinInit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTransition, animated, useChain } from "react-spring";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Typography, Close, Modal } from "../../Ui";
import useNavigation from "../../../hooks/useNavigation";
import useRouter from "../../../hooks/useRouter";
import ErrorInModal from "../../ErrorInModal";
import ArrowIcon from "./ArrowIcon";

const SideBarContainer = styled(animated.aside)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 98;
`;

const SHeader = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
  border-top: 3px solid ${(props) => props.theme.primary};
  border-bottom: 1px solid #c5cbd1;
  padding-left: 35px;
`;

const SMenuContainer = styled(animated.nav)``;

const SMenu = styled.ul`
  padding: 15px 0;
`;

const SMenuItem = styled(animated.li)`
  list-style-type: none;
  padding: 0 35px;
`;

const NavLinkCustom = styled(NavLink)`
  font-size: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.text.primary};
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #63666919;
`;

const ProfileExt = styled.a`
  font-size: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.text.primary};
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #63666919;
`;

const SideBar = (props) => {
  const {
    isLogged,
    logout,
    show,
    toggleSidebar,
    langs,
    bootApp,
    ownLogin,
    oauths,
    oauthInfo,
    oauth_profile_url,
  } = props;
  const [it, setIt] = useState([]);
  const [open, setOpen] = useState(false);
  const [isWedModal, setIsWedModal] = useState(false);

  const [t] = useTranslation();
  const router = useRouter();

  const handleClick = () => {
    toggleSidebar();
    logout();
  };

  const closeSidebar = () => {
    toggleSidebar();
  };
  const { navMobile } = useNavigation(
    isLogged,
    logout,
    langs,
    bootApp,
    ownLogin,
    oauths,
    oauthInfo,
    oauth_profile_url,
    handleClick
  );

  useEffect(() => {
    setOpen(true);
    return () => {
      setOpen(false);
    };
  }, [open]);

  useEffect(() => {
    if (show) {
      toggleSidebar();
    }
  }, [router.location.pathname]);

  const checkIsWed = (e) => {
    e.preventDefault();
    setIsWedModal(true);
  };

  const closeWedModal = () => {
    setIsWedModal(false);
  };

  useEffect(() => {
    return () => {
      setIsWedModal(false);
    };
  }, []);

  const ite = navMobile.map((n) => {
    if (n.type === "link") {
      if (n.cta === "ext") {
        return {
          key: n.key,
          content: (
            <ProfileExt key={n.key} href={n.path}>
              <Typography type="bodyCopy">{n.label}</Typography>
              <ArrowIcon />
            </ProfileExt>
          ),
        };
      }
      return {
        key: n.key,
        content: (
          <NavLinkCustom
            key={n.key}
            to={n.path}
            insidebar="true"
            onClick={n.isWed ? (e) => checkIsWed(e) : null}
          >
            <Typography type="bodyCopy">{n.label}</Typography>
            <ArrowIcon />
          </NavLinkCustom>
        ),
      };
    } else {
      return {
        key: n.key,
        content: n.label,
      };
    }
  });

  const transitionRef = useRef();
  const trailRef = useRef();

  const transition = useTransition(show, null, {
    ref: transitionRef,
    from: { transform: "translateX(-100%)" },
    enter: { transform: "translateX(0)" },
    leave: { transform: "translateY(-100%)" },
  });

  const trail = useTransition(show ? ite : [], (item) => item.key, {
    ref: trailRef,
    unique: true,
    trail: 400 / ite.length,
    from: { opacity: 0, transform: "translateX(-40px)" },
    enter: { opacity: 1, transform: "translateX(0)" },
    leave: { opacity: 0, transform: "translateX(-40px)" },
  });

  useChain(show ? [transitionRef, trailRef] : [trailRef, transitionRef], [
    0,
    show ? 0.1 : 0.6,
  ]);

  return transition.map(({ item, key, props }) =>
    item ? (
      <React.Fragment key={key}>
        <SideBarContainer style={props}>
          <SHeader>
            <div>
              <Close clicked={closeSidebar} />
            </div>
          </SHeader>
          <div>
            <SMenuContainer>
              <SMenu>
                {trail.map(({ item, key, props }) => {
                  return item ? (
                    <SMenuItem key={key} style={props}>
                      {item.content}
                    </SMenuItem>
                  ) : null;
                })}
              </SMenu>
            </SMenuContainer>
          </div>
        </SideBarContainer>
        {isWedModal && (
          <Modal show={isWedModal} close={closeWedModal}>
            <ErrorInModal
              error={"Attendi il mercoledì per provare a vincere!"}
            />
          </Modal>
        )}
      </React.Fragment>
    ) : null
  );
};

SideBar.propTypes = {
  show: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  logout: PropTypes.func,
  close: PropTypes.func,
};

export default SideBar;

import React from "react";
import { useSelector } from "react-redux";
import { CustomContainer } from "../../components/Ui";
import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos/";
import { Copy } from "@styled-icons/boxicons-regular/";
import { respondTo } from "../../theme/mixin";

const WhatsappIcon = styled(Whatsapp)`
  color: #fff;
  width: 35px;
`;

const CopyIcon = styled(Copy)`
  color: #fff;
  width: 30px;
`;

const ContactWrapper = styled.div`
  margin-top: 20px;
  padding: 0 15px;

  ${respondTo.sm`
    margin: 40px auto 0 auto;
    width: 100%;
    max-width: 750px;
	`}

  .content {
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 3px 6px #63666919;

    h1 {
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    & > p {
      margin: 0 -20px;
      padding: 0 20px 20px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      color: #636669;
    }

    .invite-code {
      position: relative;
      margin-top: 20px;
      background-color: #e4eaef;
      padding: 15px 20px 35px 20px;
      border-radius: 100px;
      text-align: center;
      margin-bottom: 20px;

      strong {
        color: #989898;
        text-transform: uppercase;
        font-size: 13px;
      }

      p {
        margin-top: 5px;
        color: ${(props) => props.theme.primary};
        font-size: 40px;
        font-weight: 700;
      }

      .share {
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background-color: ${(props) => props.theme.primary};
          border-radius: 50%;
          margin: 0 7px;
        }
      }
    }
  }
`;

const InviteFriend = () => {
  const userInfo = useSelector((state) => state.user.user.userinfo);
  return (
    <CustomContainer>
      <div className="container">
        <ContactWrapper>
          <div className="content">
            <h1>Invita un amico</h1>
            <p>
              Copia il codice che vedi qui sotto e invialo ad un tuo amico, se si registra e partecipa anche lui a "Germogliano i premi", ottenete entrambi una possibilità in più di vittoria.
            </p>
            <div className="invite-code">
              <strong>Codice invito</strong>
              <p>{userInfo.mgm_code}</p>
              <div className="share">
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(userInfo.mgm_code);
                  }}
                >
                  <a href={`whatsapp://send?text=${userInfo.mgm_code}`}>
                    <WhatsappIcon />
                  </a>
                </span>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(userInfo.mgm_code);
                  }}
                >
                  <CopyIcon />
                </span>
              </div>
            </div>
          </div>
        </ContactWrapper>
      </div>
    </CustomContainer>
  );
};

export default InviteFriend;

import React from "react";
import styled from "styled-components";

const AvatarWrapper = styled.header`
  width: ${(props) => (props.width ? props.width : 38)}px;
  height: ${(props) => (props.width ? props.width : 38)}px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.size ? props.size : 14)}px;
`;

const Avatar = ({ firstname, width, size }) => {
  return (
    <AvatarWrapper width={width} size={size}>
      {firstname[0]}
    </AvatarWrapper>
  );
};

export default Avatar;

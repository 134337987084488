import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
  }
  body {
    font-family: ${(props) => props.font}, sans-serif;
    font-size: 100%;
    height: 100%;
    color: #47545D;
    font-size: 14px;
    background-color: #EBEBEB;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
  }
  .container {
    position: relative;
    max-width: 1344px;
    width: 100%;
    margin: 0 auto;
  }
  .slick-disabled {
    opacity: 0.5;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  p {
    line-height: 1.4;
  }
  ul {
    list-style-type: none;
  }
  textarea {
    font-family: ${(props) => props.font}, sans-serif;
  }
`;

export default GlobalStyle;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Typography } from "../../../../../Ui";
import { darken, lighten } from "polished";
import { respondTo } from "../../../../../../theme/mixin";

const SingleAnswer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;

  ${respondTo.md`
    margin-bottom: 0;
  `};
`;

const AnswerButton = styled.div`
  color: ${(props) => (props.isSelected ? "#00ABE5" : "#ADB1BC")};
  border-radius: 10px;
  padding: 7px;
  box-shadow: 0px 5px 15px #00000026;
  border: 2px solid ${(props) => (props.isSelected ? "" : "")};
  opacity: ${(props) => (props.isSelected ? "1" : "0.8")};
  cursor: pointer;
  font-size: 16px;

  &:hover {
    opacity: 1;
    background-color: #00abe5;
    color: #fff;
    border-color: #00abe5;
  }
`;

const Answer = (props) => {
  //const [dis, setDis] = useState(false);
  //const [isValid, setIsValid] = useState(false);
  //const [isSelected, setIsSelected] = useState(false);

  const answerRef = useRef(null);

  const {
    value,
    id,
    idQuestion,
    change,
    disabled,
    points,
    children,
    clicked,
    instantcheck,
    selectedcheck,
    isSelected,
  } = props;

  const handleClick = (e) => {
    //setDis(true);
    //clicked();
    change();

    //setIsSelected(true);

    let answ = {};
    answ.result = value.toString();
    answ.questionid = idQuestion.toString();
    answ.answerid = id.toString();
    answ.points = points.toString();

    clicked(e, answ);

    // answ.result = e.currentTarget.getAttribute('value');
    // answ.questionid = e.currentTarget.getAttribute('idquestion');
    // answ.answerid = e.currentTarget.getAttribute('id');
    // answ.points = e.currentTarget.getAttribute('points');

    /* setTimeout(() => {
      if (instantcheck) {
        if (selectedcheck) {
          answerRef.current.setAttribute(
            "style",
            `background-color: ${
              value === 1 ? props.colorWin : props.colorLoose
            };`
          );
        }
        clicked(e, answ);
      } else {
        clicked(e, answ);
      }
    }, 600); */

    /* answerRef.current.setAttribute(
      "style",
      `background-color: ${darken(0.1, props.colorAnswers)};`
    ); */
  };

  return (
    <SingleAnswer {...props}>
      <AnswerButton
        ref={answerRef}
        type="answer"
        name={`radio${idQuestion}`}
        id={id}
        idquestion={idQuestion}
        points={points}
        value={value}
        onClick={handleClick}
        isSelected={isSelected}
        {...props}
      >
        {children}
      </AnswerButton>
    </SingleAnswer>
  );
};

Answer.propTypes = {
  change: PropTypes.func,
  value: PropTypes.number,
  id: PropTypes.number,
  idQuestion: PropTypes.number,
  points: PropTypes.number,
  isvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Answer;

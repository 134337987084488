import React from 'react';

export const Image = (props) => {

    const { src } = props;
    
    return (
        <img src={src} />
    )
}

export default Image;
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const WishlistWrapper = styled.div`
  .slide {
    z-index: 10;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 25px;

      ${respondTo.md`
        padding: 0 16px;
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 535px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 44px;
        margin-bottom: 20px;
      }

      h3,
      p {
        color: #1d241e;
      }

      button {
        margin-top: 40px;
      }
    }
  }
`;

const HowToWork = (props) => {
  return (
    <>
      <WishlistWrapper>
        <div className="slide">
          <img src={process.env.PUBLIC_URL + "/images/regole-bg.png"} alt="" />

          <div className="content">
            <div>
              <h3>Regole del gioco</h3>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                nonumy eirmod tempor <strong>invidunt</strong>. Pisum dolor sit
                amet, <strong>consetetur sadipscing elitr</strong>, sed diam n
                diam nonumy eirmo umquam sed adiscipit nonumy eirmod tempor
                invidunt <strong>adiscipit nonumy</strong> eirmod.
              </p>
              <Button bg="#fff" color="#1D241E">
                Scarica il regolamento
              </Button>
            </div>
          </div>
        </div>
      </WishlistWrapper>

      <How>
        <h3>Come funziona</h3>

        <div className="h-row">
          <div className="h-col">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum.
              <br />
              <br />
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr.
            </p>
            <Link to="/user/profilo">
              <Button color="#fff" bg="#1BB410">
                My Page
              </Button>
            </Link>
          </div>
          <div className="h-col h-boxs">
            <div className="h-box active">
              <strong>Livello 1</strong>
              <div className="dal">
                <strong>DAL</strong>
                <span>00/00</span>
              </div>
              <img
                src={process.env.PUBLIC_URL + "/icons/calendar-big-icon.svg"}
                alt=""
              />
              <div className="dal">
                <strong>AL</strong>
                <span>00/00</span>
              </div>
            </div>
            <div className="h-box">
              <strong>Livello 1</strong>
              <div className="dal">
                <strong>DAL</strong>
                <span>00/00</span>
              </div>
              <img
                src={process.env.PUBLIC_URL + "/icons/calendar-big-icon.svg"}
                alt=""
              />
              <div className="dal">
                <strong>AL</strong>
                <span>00/00</span>
              </div>
            </div>
            <div className="h-box">
              <strong>Livello 1</strong>
              <div className="dal">
                <strong>DAL</strong>
                <span>00/00</span>
              </div>
              <img
                src={process.env.PUBLIC_URL + "/icons/calendar-big-icon.svg"}
                alt=""
              />
              <div className="dal">
                <strong>AL</strong>
                <span>00/00</span>
              </div>
            </div>
          </div>
        </div>
      </How>

      <Badges>
        <div>
          <h4>Badge</h4>
          <div className="badge-box">
            <img
              src={process.env.PUBLIC_URL + "/icons/award-gray-icon.svg"}
              alt=""
            />
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt. Lorem ipsum dolor sit amet,
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt.
            </p>
          </div>
        </div>

        <div>
          <h4>Missioni</h4>
          <div className="badge-box">
            <img
              src={process.env.PUBLIC_URL + "/icons/star-gray-icon.svg"}
              alt=""
            />
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt. Lorem ipsum dolor sit amet,
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt.
            </p>
          </div>
        </div>

        <div>
          <h4>Coppe</h4>
          <div className="badge-box">
            <img
              src={process.env.PUBLIC_URL + "/icons/trophy-gray-icon.svg"}
              alt=""
            />
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt. Lorem ipsum dolor sit amet,
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt.
            </p>
          </div>
        </div>
      </Badges>

      <Points>
        <h3>Punti e premi</h3>

        <div className="boxs">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/punti-premi.png"}
              alt=""
            />
            <div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor <strong>invidunt</strong> ut labore et
                dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                accusam et justo <strong>duo dolores et ea rebum.</strong>
                <br />
                <br /> Stet clita kasd gubergren, no sea{" "}
                <strong>takimata</strong> sanctus est Lorem{" "}
                <strong>ipsum dolor</strong> sit amet. Lorem ipsum dolor sit
                amet, consetetur sadipscing elitr. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr.
              </p>

              <Link to="/catalogo">
                <Button bg="#1BB410">Catalogo</Button>
              </Link>
            </div>
          </div>
          <div>
            <div className="table-content">
              <table>
                <thead>
                  <tr>
                    <td>AZIONE</td>
                    <td>DETTAGLIO</td>
                    <td>PUNTI</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                  <tr>
                    <td>Nome azione premiante</td>
                    <td>Dettaglio lorem ipsum</td>
                    <td>0.000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Points>
    </>
  );
};

const Points = styled.div`
  background-color: #fff;
  position: relative;
  padding: 70px 12px;

  ${respondTo.md`
    top: -90px;
    margin-bottom: -100px;
  `}

  h3 {
    color: #1d241e;
    font-size: 44px;
    text-align: center;
    margin-bottom: 20px;
  }

  .boxs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      ${respondTo.md`
        flex-wrap: nowrap;
        width: calc(50% - 30px);
      `}

      img {
        width: 200px;
        height: auto;
        margin-right: 24px;
        margin-bottom: 24px;

        ${respondTo.md`
          margin-bottom: 0;
        `}
      }

      p {
        font-size: 16px;
        margin-bottom: 24px;
      }

      .table-content {
        width: 100%;
        background-color: #ebebeb;
        border-radius: 24px;
        padding: 16px;
        margin-top: 30px;

        ${respondTo.md`
          margin-top: 0;
        `}

        table {
          width: 100%;
          color: #94a2ac;

          thead {
            td {
              font-size: 10px;
              font-weight: 600;
              border-bottom: 1px solid #94a2ac;
            }
          }

          tbody {
            td {
              font-size: 18px;
            }
          }

          td {
            padding: 5px 0;
          }
        }
      }
    }
  }
`;

const Badges = styled.div`
  background-color: #ebebeb;
  position: relative;
  border-radius: 24px;
  z-index: 11;
  padding: 50px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${respondTo.md`
    top: -70px;
  `}

  & > div {
    width: 100%;
    text-align: center;

    ${respondTo.md`
      width: calc(33.333% - 12px);
    `}

    h4 {
      font-size: 44px;
      color: #1d241e;
      margin-bottom: 12px;
    }

    .badge-box {
      background-color: #fff;
      border-radius: 24px;
      padding: 30px 16px;
      text-align: center;

      p {
        font-size: 14px;
        color: #1d241e;
        margin: 20px 0;
      }
    }
  }
`;

const How = styled.div`
  background-color: #fff;
  padding: 60px 12px;
  position: relative;
  z-index: 9;

  ${respondTo.md`
    top: -50px;
  `}

  h3 {
    font-size: 44px;
    color: #1d241e;
  }

  .h-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .h-col {
    width: 100%;

    ${respondTo.md`
      width: calc(50% - 20px);
    `}

    p {
      font-size: 16px;
      margin-top: 20px;
      color: #1d241e;
      padding-right: 90px;
    }

    a {
      display: inline-block;
      margin-top: 20px;
    }

    &.h-boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .h-box {
        width: 100%;
        border-radius: 24px;
        background-color: #ebebeb;
        text-align: center;
        padding-top: 25px;
        padding-bottom: 10px;
        margin-top: 24px;

        ${respondTo.md`
          margin-top: 0;
          width: 200px;
        `}

        &.active {
          background-color: #1bb410;

          .dal {
            color: #00852a;
          }
        }

        & > strong {
          font-size: 12px;
          text-transform: uppercase;
          color: #1d241e;
        }

        .dal {
          color: #94a2ac;
          margin: 15px 0;

          strong {
            display: block;
            font-size: 12px;
          }

          span {
            font-size: 24px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;

export default HowToWork;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { openOrderModal } from "../../store/actions/products";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { removeFromWishlist, addToCart } from "../../store/actions/wishlist";
import { Button } from "../../components/Ui";

const WishlistWrapper = styled.div`
  .slide {
    position: relative;
    padding: 130px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 530px;
        height: 100%;
        margin-top: 0;
        padding: 0 16px;
      `}

      h3 {
        font-size: 44px;
        color: #1d241e;
        margin-bottom: 20px;
      }

      p {
        color: #1d241e;
      }
    }
  }

  .wish-header {
    display: flex;
    justify-content: space-between;

    span {
      color: #1bb410;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .wish-products {
    margin-top: 50px;

    .wish-product {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      border-radius: 15px;
      padding: 15px;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
      `}

      &:nth-child(even) {
        background-color: #ecf0fa;
      }

      &:nth-child(1) {
        .prod-data:before,
        .prod-actions:before,
        .prod-points:before,
        .prod-percent:before {
          position: absolute;
          top: -15px;
          color: #1bb410;
          font-weight: 300;
          font-size: 18px;
          display: none;

          ${respondTo.md`
            display: block;
          `}
        }

        .prod-data:before {
          content: "Premio";
        }
        .prod-percent:before {
          content: "Raggiunto";
          margin-left: -35px;
        }
        .prod-points:before {
          content: "Punti";
        }
        .prod-actions:before {
          content: "Azioni";
          margin-left: 45px;
        }
      }

      .prod-info {
        display: flex;
        align-items: center;
        max-width: 570px;

        .prod-image {
          display: flex;
          flex: 0 0 120px;
          height: 120px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 5px 15px #00000029;
          overflow: hidden;
          align-items: center;

          ${respondTo.md`
            flex: 0 0 160px;
            height: 160px;
          `}

          img {
            width: 100%;
            height: auto;
          }
        }

        .prod-data {
          margin-left: 25px;

          ${respondTo.md`
            margin-left: 40px;
          `}

          span {
            color: #1bb410;
          }

          a {
            display: block;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: 700;
            color: #3a3c42;
          }
        }
      }

      .prod-text {
        border: 1px solid #1bb410;
        border-radius: 5px;
        color: #1bb410;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 7px 2px 7px;
      }

      .prod-actions {
        display: flex;
        text-align: center;

        .prod-act {
          margin: 0 10px;
          cursor: pointer;

          .wish {
            background-color: #adb1bc;
          }

          .cart {
            background-color: #1bb410;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0px 5px 15px #00000026;
            margin-bottom: 5px;
          }

          .gray {
            color: #adb1bc;
          }

          span {
            color: #1bb410;
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;
            display: block;
          }
        }
      }
    }
  }

  .empty-wishlist {
    color: #1bb410;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 40px;

    span {
      display: block;
      margin-top: 20px;
    }
  }
`;

const Wishlist = (props) => {
  const dispatch = useDispatch();

  const wishlist = useSelector((state) => state.user.user.wishlist);
  const userInfo = useSelector((state) => state.user.user.userinfo);

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  const moveToCartHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
    dispatch(addToCart(productId));
  };

  return (
    <WishlistWrapper>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/catalog-bg.png"} alt="" />

        <div className="content">
          <div>
            <h3>Wishlist</h3>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
              nonumy eirmod tempor <strong>invidunt</strong>. Pisum dolor sit
              amet, <strong>consetetur sadipscing elitr</strong>, sed diam n
              diam nonumy eirmo umquam sed adiscipit nonumy eirmod tempor
              invidunt <strong>adiscipit nonumy</strong> eirmod.
            </p>
          </div>
        </div>
      </div>

      <div className="wish-products">
        {wishlist && wishlist.length > 0 ? (
          wishlist.map((prod) => (
            <div className="wish-product" key={prod.id}>
              <div className="prod-info">
                <Link to={`/prodotto/${prod.id}`} className="prod-image">
                  <img src={prod.image} alt="" />
                </Link>

                <div className="prod-data">
                  <span>{prod.brand}</span>
                  <Link to={`/prodotto/${prod.id}`}>{prod.title}</Link>
                  <p>{prod.short_description}</p>
                </div>
              </div>
              <div className="prod-text prod-percent">
                {userInfo && (userInfo.score / prod.points) * 100 > 100
                  ? 100
                  : Math.floor((userInfo.score / prod.points) * 100)}
                %
              </div>
              <div className="prod-text prod-points">{prod.points} Pt</div>
              <div className="prod-actions">
                <div
                  className="prod-act"
                  //onClick={() => openOrderModalHandler(prod)}
                  onClick={() => moveToCartHandler(prod.id)}
                >
                  <div className="cart">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/addToCartWhite.svg"}
                      alt=""
                    />
                  </div>
                  <span>
                    Sposta nel
                    <br />
                    carrello
                  </span>
                </div>
                <div
                  className="prod-act"
                  onClick={() => removeFromWishlistHandler(prod.id)}
                >
                  <div className="wish">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/icons/favorite-white-icon-full.svg"
                      }
                      alt=""
                    />
                  </div>
                  <span className="gray">
                    Rimuovi dalla
                    <br />
                    wishlist
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-wishlist">
            <span>
              La tua wishlist è vuota, aggiungi qualcosa dal catalogo!
            </span>
            <Link to="catalogo">
              <Button
                upper
                gradient={{ left: "#1bb410", right: "#1bb410" }}
                hoverGradient={{ left: "#1bb410", right: "#1bb410" }}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                }}
              >
                Vai al catalogo
                <ChevronRight size={30} />
              </Button>
            </Link>
          </div>
        )}
      </div>
    </WishlistWrapper>
  );
};

export default Wishlist;

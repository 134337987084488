import React from "react";
import PropTypes from "prop-types";
import { Section, Wrapper, Button } from "../../../Ui";
import { Link, withRouter } from "react-router-dom";

import { OauthsHandler } from "../../../Oauths";
import { useTranslation } from "react-i18next";

const MechanicGoToGame = (props) => {
  const [t] = useTranslation();

  const {
    location: { pathname },
    g: {
      id,
      i18l: { interaction },
    },
    authObj,
    authObj: { isLogged },
    multipromo,
  } = props;

  let interactionObj = {};
  let label = null;
  if (interaction) {
    interactionObj = JSON.parse(interaction);
  }

  if (interactionObj.label) {
    label = interactionObj.label;
  } else {
    label = t("games.gotogame");
  }

  return (
    <Section>
      <Wrapper>
        {isLogged ? (
          <Link
            to={{
              pathname: multipromo
                ? `${pathname}/game/${id}`
                : `promo/game/${id}`,
              state: { cid: "boxgotogame" },
            }}
          >
            <Button type="" fullwidth upper>
              {label}
            </Button>
          </Link>
        ) : (
          <OauthsHandler authObj={authObj} label={label} isfullwidth={true} />
        )}
      </Wrapper>
    </Section>
  );
};

MechanicGoToGame.propTypes = {
  id: PropTypes.number,
  pathname: PropTypes.string,
};

export default withRouter(MechanicGoToGame);

import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getSlug } from "../../utils/utils";
import { withRouter, Link } from "react-router-dom";
import * as actionCreators from "../../store/actions";
import { respondTo } from "../../theme/mixin";
import { Viewer } from "../../components/Ui";

const WinMoreWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .asterisk {
    font-size: 10px;
    color: #adb1bc;
    margin-top: 20px;

    a {
      color: #eb5a3f;
      text-decoration: underline;
    }
  }
`;

const WinMore = (props) => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    isLogged,
    logo,
    promoinfo,
    viewer,
    getGames,
    games,
    oauths,
    ownLogin,
    oauthInfo,
    location: { pathname },
  } = props;

  let slug = "get";

  if (multipromo) {
    slug = getSlug();
  }

  let idFirstGame = null;
  let idSecondGame = null;

  if (games.length > 0 && games.length === 2) {
    idFirstGame = games[0].id;
    idSecondGame = games[1].id;
  }

  useEffect(() => {
    getGames(slug, isLogged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <WinMoreWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/vinci-di-piu-slide.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <h3>Vinci di più</h3>
            <p>
              Sei il <strong>+ fortunato?</strong> Mettiti alla prova! In questa
              sezione potrai partecipare ai concorsi che ti proporremo per
              provare a vincere premi esclusivi.
              <br />
              <br />
              <strong>MYLINKPLUS</strong> ricompensa anche la tua{" "}
              <strong>fedeltà</strong>: da + tempo sei nostro cliente e +
              possibilità di gioco hai a disposizione.
            </p>
          </div>
        </div>
      </div>

      <Viewer
        scream={promoinfo.i18l ? promoinfo.i18l.title : ""}
        description={promoinfo.i18l ? promoinfo.i18l.description : ""}
        imgdesktop={viewer.img_desktop}
        imgmobile={viewer.img_mobile}
        logopromo={viewer.logo}
        islogged={isLogged}
        logo={logo}
        url={viewer.i18l ? viewer.i18l.url : null}
        cta={viewer.i18l ? viewer.i18l.calltoaction : null}
        ctatype={viewer.calltoaction_type}
        iscta={viewer.calltoaction}
        dark_text={viewer.dark_text}
        oauths={oauths}
        ownlogin={ownLogin}
        oauthinfo={oauthInfo}
        idFirstGame={idFirstGame}
        idSecondGame={idSecondGame}
        pathname={pathname}
        multipromo={multipromo}
      />

      <div className="asterisk">
        *Concorso valido dal 06/07/21 al 30/05/22 con estrazione entro il
        16/06/22. Montepremi complessivo € 57.723,00 iva inclusa. Per maggiori
        informazioni, periodi di partecipazione, premi e dettagli, consulta il{" "}
        <Link
          to={{
            pathname:
              process.env.PUBLIC_URL +
              "/pdf/Regolamento OP_MyLinkPlus_2021_2022.pdf",
          }}
          target="_blank"
        >
          regolamento
        </Link>
        .
      </div>
    </WinMoreWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user.userinfo,
    partners: state.partner.partnerList,
    wishlist: state.user.user.wishlist,
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
    products: state.products.allProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WinMore)
);

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import { Button } from "../Ui";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const LogosWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 11;
  padding: 130px 15px 0 15px;

  ${respondTo.md`
    padding: 0;
  `}

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-list > div {
    margin-left: 0;
  }
`;

const Logo = styled.div`
  img {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;

  ${respondTo.md`
    top: 0;
    position: absolute;
    width: 100%;
    max-width: 650px;
    padding-bottom: 30px;
    height: 100%;
    margin-top: 0;
  `}

  div {
    padding: 0;
    padding-bottom: 30px;

    ${respondTo.md`
      padding: 0 15px;
    `}

    h3 {
      font-size: 32px;
      line-height: 1.2;
      color: #1d241e;

      ${respondTo.md`
        font-size: 64px;
      `}
    }

    p {
      font-size: 16px;
      color: #1d241e;
      margin-bottom: 25px;
      display: block;
    }
  }
`;

const HomeSlider = () => {
  const options = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <LogosWrapper>
      <Slider {...options}>
        <Logo>
          <img src={process.env.PUBLIC_URL + "/images/login-bg.png"} alt="" />
        </Logo>
      </Slider>
      <Content>
        <div>
          <h3>Lorem ipsum dolor sit amet! Sed Adiscipt elit.</h3>
          <p style={{ marginBottom: 15, marginTop: 20 }}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor <strong>invidunt</strong> ut labore et dolore
            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
            justo <strong>duo dolores et ea rebum.</strong>
            <br />
            <br />
            Stet clita kasd gubergren, no sea <strong>takimata</strong> sanctus
            est Lorem <strong>ipsum dolor</strong> sit amet. Lorem ipsum dolor
            sit amet, consetetur sadipscing elitr.
          </p>
          <Link to="/regole-del-gioco">
            <Button bg="#fff" color="#1D241E">
              Scopri di più
            </Button>
          </Link>
        </div>
      </Content>
    </LogosWrapper>
  );
};

export default HomeSlider;

import styled from "styled-components";

const Select = styled.select`
  outline: none;
  width: 100%;
  height: 45px;
  border-radius: 15px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Mont", sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(${process.env.PUBLIC_URL + "/icons/chevron-down.svg"}),
    ${(props) => props.theme.input.bg};
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  border: 1px solid
    ${(props) =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : (props) => props.theme.input.bg};

  color: #adb1bc;
`;

export default Select;

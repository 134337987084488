import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
} from "../../store/actions/wishlist";
import parse from "html-react-parser";

const BoxWrapper = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  width: 185px;

  &:hover .box-image:before {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s;
  }

  &:hover .box-info .box-name {
    color: ${(props) => props.theme.primary};
  }

  .box-image {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 185px;
    height: 200px;
    padding: 15px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 10px;

    & > img {
      width: 100%;
      height: auto;
    }

    .add-to-wishlist {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      transition: all 0.2s;

      &:hover {
        background-color: ${(props) => props.theme.primary};
        transition: all 0.2s;
      }
    }
  }

  .box-info {
    width: 100%;

    span {
      color: #94a2ac;
      font-size: 12px;
    }

    p {
      color: #ff7db9;
    }

    strong {
      display: block;
    }

    .box-name {
      font-size: 12px;
      text-transform: uppercase;
      color: #1d241e;
    }

    .box-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .box-points {
        color: ${(props) => props.theme.primary};
      }

      .add-to-cart {
        width: 65px;
        height: 32px;
        background-color: #fff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

const ProductBox = ({ product }) => {
  const dispatch = useDispatch();

  const wishlist = useSelector((state) => state.user.user.wishlist);

  const [inWishlist, setInWishlist] = useState(false);

  const addToWishlistHandler = (productId) => {
    dispatch(addToWishlist(productId));
  };

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find((el) => el.id === product.id);

      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  return (
    <>
      <BoxWrapper>
        {product && (
          <>
            <div className="box-image">
              <div
                className="add-to-wishlist"
                onClick={() =>
                  inWishlist
                    ? removeFromWishlistHandler(product.id)
                    : addToWishlistHandler(product.id)
                }
              >
                {inWishlist ? (
                  <img
                    src={process.env.PUBLIC_URL + "/icons/heart-full-icon.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/icons/heart-icon.svg"}
                    alt=""
                  />
                )}
              </div>
              <Link to={`/prodotto/${product.id}`}>
                <img src={product.image} alt="" />
              </Link>
            </div>

            <div className="box-info">
              <Link to={`/prodotto/${product.id}`}>
                <span>{product.brand}</span>
                <strong className="box-name">
                  {parse(product.short_description || "")}
                </strong>
              </Link>
              <div className="box-footer">
                <strong className="box-points">{product.points} PT</strong>
                <div
                  className="add-to-cart"
                  onClick={() => dispatch(addToCart(product.id))}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/icons/add-cart-icon.svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </BoxWrapper>
    </>
  );
};

export default ProductBox;

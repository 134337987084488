import React from "react";
import PropTypes from "prop-types";
import {
  CardHandler,
  UploadReceiptHandler,
  SurveyQuizHandler,
  BackHandler,
} from "../../Handlers";

const MechanicGame = (props) => {
  const {
    g: { interaction },
  } = props;

  let mechanicgame = null;

  switch (interaction.type) {
    case "survey-quiz":
      mechanicgame = <SurveyQuizHandler {...props} />;
      break;
    case "card":
      mechanicgame = <CardHandler {...props} />;
      break;
    case "prize-bag":
      mechanicgame = <BackHandler {...props} />;
      break;
    case "upload-receipt":
      mechanicgame = <UploadReceiptHandler {...props} />;
      break;
    default:
      mechanicgame = <CardHandler {...props} />;
  }

  return <React.Fragment>{mechanicgame}</React.Fragment>;
};

MechanicGame.propTypes = {
  interaction: PropTypes.object,
};

export default MechanicGame;

import React, { useState } from "react";
import styled from "styled-components";
import InputField from "./InputField/InputField";
import Select from "./Select/Select";
import Checkbox from "./Checkbox/Checkbox";
import Label from "./Label/Label";
import Datepicker from "./Datepicker";
import File from "./File";
import Radio from "./Radio";
import Textarea from "./Textarea";
import Range from "./Range";

const InputContainer = styled.div`
  margin-bottom: 18px;
  display: ${(props) => (props.type === "checkbox" ? "flex" : "block")};
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const ErrorContainer = styled.p`
  margin-top: 5px;
  text-align: right;
  color: red;
  font-size: 12px;
`;

const TextP = styled.p`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  margin-top: 24px;
`;

const Input = (props) => {
  let label = null;
  let inputElement = null;
  let title = null;

  const [startDate, setStartDate] = useState(null);

  if (props.label !== "") {
    if (props.elementConfig.type === "checkbox") {
      label = null;
    } else {
      label = (
        <Label
          htmlFor={props.elementConfig.name}
          inModal={props.inModal}
          type={props.elementConfig.type}
        >
          {props.label}
        </Label>
      );
    }
  }

  if (props.title) {
    title = <TextP {...props}>{props.title}</TextP>;
  }

  switch (props.elementType) {
    case "input":
      let value = props.value;
      if (props.isZipcode) {
        value = props.zipcode;
      }

      inputElement = (
        <InputField
          {...props.elementConfig}
          onChange={props.changed}
          {...props}
          value={value}
        />
      );
      break;
    case "select":
      let options = null;

      if (props.isDistrict) {
        options = (
          <>
            {props.elementConfig.options.map((opt) => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.districts.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isCity) {
        options = (
          <>
            {props.elementConfig.options.map((opt) => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.cities.map((opt) => (
              <option value={opt.name} key={opt.name}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isCategories) {
        options = (
          <>
            {props.elementConfig.options.map((opt) => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            <option value={0} key="empty">
              TUTTE
            </option>
            {props.categories.map((opt) => (
              <option value={opt.id} key={opt.id}>
                {opt.value}
              </option>
            ))}
          </>
        );
      } else {
        options = props.elementConfig.options.map((opt) => (
          <option value={opt.value} key={opt.value} disabled={opt.disabled}>
            {opt.displayValue}
          </option>
        ));
      }
      inputElement = (
        <Select
          onChange={props.changed}
          name={props.elementConfig.name}
          disabled={props.elementConfig.disabled}
          {...props}
        >
          {options}
        </Select>
      );
      break;
    case "datepicker":
      inputElement = (
        <Datepicker
          lang={props.lang}
          {...props.elementConfig}
          selected={props.startdate}
          onChange={props.changedDatePicker}
          {...props}
        />
      );
      break;
    case "inputcheckbox":
      inputElement = (
        <Checkbox
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case "inputradio":
      inputElement = (
        <Radio
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          {...props}
        />
      );
      break;
    case "inputfile":
      inputElement = (
        <File
          {...props.elementConfig}
          handleFile={props.fileUploadHandler}
          imagePreviewUrl={props.imagePreviewUrl}
          deleteFileHandler={props.deleteFileHandler}
          value={props.value}
          {...props}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <Textarea
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case "inputrange":
      inputElement = <Range {...props} />;
      break;
    default:
      inputElement = <input />;
  }

  return (
    <React.Fragment>
      {title}
      <InputContainer type={props.elementConfig.type}>
        {label ? label : null}
        {inputElement}
        <ErrorContainer>
          {(!props.isvalid &&
            !props.istouched &&
            props.firstSubmit &&
            props.showError) ||
          (!props.isvalid && props.showError)
            ? props.error
            : ""}
        </ErrorContainer>
      </InputContainer>
    </React.Fragment>
  );
};

export default Input;

import React, { Suspense, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { SideBar, Modal, Loader, Button } from "../../components/Ui";
import SessionExpired from "../../components/SessionExpired";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import OrderSteps from "./OrderSteps";
import {
  closeOrderModal,
  clearOrder,
  closeWishlistModalAccess,
} from "../../store/actions/products";
import { clearFirstAccessError } from "../../store/actions/auth";

const MainContent = styled.div`
  position: relative;
  flex: 1 0 auto;
`;

const BaseLayout = (props) => {
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    isGame,
    ShoPrizeInHome,
    newOrder,
    firstAccess,
    orderModal,
    wishlistModalAccess,
  } = props;

  const dispatch = useDispatch();

  const closeOrderModalHandler = () => {
    dispatch(closeOrderModal());
  };

  const errorFirstAccess = useSelector((state) => state.auth.errorFirstAccess);

  const [gameDate, setGameDate] = useState();
  const [accessErrorModal, setAccessErrorModal] = useState(false);

  useEffect(() => {
    if (errorFirstAccess) {
      setAccessErrorModal(errorFirstAccess);
    }
  }, [errorFirstAccess]);

  const gameObj = useSelector((store) => store.promo.game[0]);
  useEffect(() => {
    if (gameObj && gameObj.interaction) {
      setGameDate(JSON.parse(gameObj.interaction.data));
    }
  }, [gameObj]);

  useEffect(() => {
    if (firstAccess !== undefined && !firstAccess) {
      props.history.push("/first-access");
    }
  }, [firstAccess, props.history]);

  let lazy = null;

  const Content = (
    <>
      <Header
        isLogged={isLogged}
        logo={logo}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
        logout={logout}
        bootApp={bootApp}
        langs={langs}
        oauths={oauths}
        ownLogin={ownLogin}
        multipromo={multipromo}
        oauthInfo={oauthInfo}
        userInfo={userInfo}
        oauth_profile_url={oauth_profile_url}
      />

      <MainContent isGame={isGame}>
        <div className="container">
          <SideBar
            isLogged={isLogged}
            show={showSidebar}
            logout={logout}
            toggleSidebar={toggleSidebar}
            oauths={oauths}
            ownLogin={ownLogin}
            oauthInfo={oauthInfo}
            bootApp={bootApp}
            langs={langs}
            oauth_profile_url={oauth_profile_url}
          />
          <main>
            <C isLogged={isLogged} />
          </main>
        </div>
      </MainContent>

      <Footer footercontent={footercontent} />
      {/* <Modal
        show={orderModal.isOpen}
        close={() => {
          if (newOrder.completed) {
            dispatch(clearOrder());
          }
          closeOrderModalHandler();
        }}
      >
        {isLogged ? (
          <>
            {newOrder.completed ? (
              <>
                Il tuo premio è stato richiesto correttamente, riceverai
                un'email con il riepilogo dell'ordine.
              </>
            ) : (
              <>
                {orderModal.product && (
                  <>
                    {userInfo.score >= orderModal.product.points ? (
                      <OrderSteps
                        product={orderModal.product}
                        newOrder={newOrder}
                      />
                    ) : (
                      "Non hai abbastanza punti per questo premio."
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            Devi eseguire l'accesso prima di poter ritirare un premio.
            <Link
              to={{
                pathname: "https://mylinkempage.linkem.com/User/Login",
              }}
              target="_blank"
            >
              <Button
                upper
                gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
                style={{ marginTop: 20 }}
              >
                Accedi
                <ChevronRight size={30} />
              </Button>
            </Link>
          </>
        )}
      </Modal> */}

      <Modal
        show={wishlistModalAccess}
        close={() => dispatch(closeWishlistModalAccess())}
      >
        Devi eseguire l'accesso prima di inserire prodotti in wishlist.
        <Link
          to={{
            pathname: "https://mylinkempage.linkem.com/User/Login",
          }}
          target="_blank"
        >
          <Button
            upper
            gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
            style={{ marginTop: 20 }}
          >
            Accedi
            <ChevronRight size={30} />
          </Button>
        </Link>
      </Modal>

      <Modal
        show={accessErrorModal}
        close={() => {
          setAccessErrorModal(false);
          dispatch(clearFirstAccessError());
        }}
      >
        {accessErrorModal}
      </Modal>
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: "base",
};

const mapStateToProps = (state) => {
  return {
    newOrder: state.product.newOrder,
    orderModal: state.product.orderModal,
    wishlistModalAccess: state.products.wishlistModalAccess,
    firstAccess: state.user.user.first_access_done,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);

import React from "react";
import InputRange from "react-input-range";
import styled from "styled-components";
import "react-input-range/lib/css/index.css";

const CustomRange = styled.div`
  padding-bottom: 60px;
  padding: 20px 10px 55px 10px;
  border-bottom: 1px solid #950b4b;
  border-top: 1px solid #950b4b;
  margin-top: 30px;

  & > span {
    color: #ff7db9;
    font-size: 16px;
    font-weight: 300;
    margin-left: -10px;
    margin-bottom: 10px;
  }

  .input-range__track--active,
  .input-range__slider {
    background-color: #ff7db9;
  }

  .input-range__slider {
    border: 1px solid #ff7db9;
  }

  .input-range__track--background {
    background-color: #950b4b;
  }

  .input-range__label--max,
  .input-range__label--min {
    display: none;
  }

  .input-range__label--value .input-range__label-container {
    background-color: #fff;
    top: 47px;
    padding: 5px;
    border-radius: 30px;
    color: #e61274;
    font-weight: 700;
  }
`;

const Range = (props) => {
  return (
    <CustomRange>
      <span>Seleziona una fascia di punti</span>
      <InputRange
        value={props.value}
        minValue={0}
        maxValue={props.maxValue}
        onChange={(newValue) =>
          props.changed({ target: { name: props.name, value: newValue } })
        }
      />
    </CustomRange>
  );
};

export default Range;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { respondTo } from "../../theme/mixin";

const SurveysListWrapper = styled.div`
  .surveys-list {
    background-color: #00abe5;
    box-shadow: 0px 5px 15px #00000026;
    border-radius: 15px;
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 50px;

    & > strong {
      color: #fff;
      font-size: 21px;
    }

    .surveys-list-wrapper {
      display: flex;
      margin: 20px -20px;

      .survey-box {
        padding: 0 20px;
        opacity: 0.5;

        &.active,
        &:hover {
          opacity: 1;
        }

        .survey-title {
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0px 3px 15px #00000026;
          padding: 8px 10px 5px 10px;

          strong {
            text-transform: uppercase;
            color: #00abe5;
          }
        }
      }
    }
  }
`;

const SurveysList = (props) => {
  const { id } = useParams();
  const games = useSelector((state) => state.game.games);

  return (
    <SurveysListWrapper>
      <div className="surveys-list">
        <strong>I tuoi sondaggi</strong>
        <div className="surveys-list-wrapper">
          {games &&
            games.map((game) => (
              <div
                key={game.id}
                className={`survey-box ${Number(id) === game.id && "active"}`}
              >
                {game.image && <img src="" alt="" />}
                <Link
                  to={`/sondaggi/promo-survey/game/${game.id}`}
                  className="survey-title"
                >
                  <strong>{game.i18l.info.title}</strong>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </SurveysListWrapper>
  );
};

export default SurveysList;

import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getElement } from "../../../../../utils/utils";
import { respondTo } from "../../../../../theme/mixin";
import { Modal, Loader } from "../../../../../components/Ui";

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .image-overlay {
    width: 300px;

    ${respondTo.md`
      width: 370px;
    `}

    img {
      width: 100%;
      height: auto;
    }
  }

  .loader-wrapper {
    position: absolute;
    left: 30px;
    bottom: -10px;
  }

  .tap-image {
    position: absolute;
    bottom: -25px;
    margin-left: 50px;
    cursor: pointer;
  }
`;

const CardElement = (props) => {
  const { clicked } = props;

  const [notMondayOpen, setNotMondayOpen] = useState(false);

  const loaderIw = useSelector((state) => state.game.loadingIw);

  const date = new Date();
  const currentDay = date.getDay();
  return (
    <>
      <CardWrapper>
        <div className="image-overlay">
          <img
            src={process.env.PUBLIC_URL + "/icons/bike-play-icon.svg"}
            alt=""
          />
        </div>

        {loaderIw ? (
          <div className="loader-wrapper">
            <Loader show={loaderIw} color="#6CB967" />
          </div>
        ) : (
          <div
            className="tap-image"
            onClick={
              props.remaining_attempts <= 0 || currentDay !== 1
                ? () => setNotMondayOpen(true)
                : clicked
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/icons/tap-play-icon.svg"}
              alt=""
            />
          </div>
        )}
      </CardWrapper>

      <Modal show={notMondayOpen} close={() => setNotMondayOpen(false)}>
        {props.remaining_attempts <= 0 ? (
          <p>Attenzione, non puoi giocare perchè hai terminato i tentativi</p>
        ) : (
          <p>Attenzione, è possibile giocare soltanto il lunedì</p>
        )}
      </Modal>
    </>
  );
};

export default CardElement;

import React, { useState } from "react";
import { Input, Button, CustomContainer } from "../../components/Ui";
import { contactForm } from "../../formsConfig/formsConfig";
import styled from "styled-components";
import useForm from "../../hooks/useForm";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import { Modal } from "../../components/Ui";

const ContactWrapper = styled.div`
  .slide {
    position: relative;
    padding: 130px 15px 0 15px;
    z-index: 12;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;
      padding: 0 16px 40px 16px;

      ${respondTo.md`
        padding: 0 16px;
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 535px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 44px;
        color: #1d241e;
        margin-bottom: 20px;
      }

      p {
        color: #1d241e;
      }
    }
  }

  .contact-form-wrapper {
    width: 100%;
    padding: 40px 15px;
    background-color: #fff;
    position: relative;
    z-index: 9;

    ${respondTo.md`
      top: -50px;
      margin-bottom: -60px;
      padding: 60px 15px;
    `}

    h3 {
      font-size: 44px;
      color: #1d241e;
      text-align: center;
      margin: 10px 0;
    }

    .contactContent {
      max-width: 650px;
      margin: 0 auto;
    }

    .contactContent-footer {
      display: flex;
      justify-content: center;
    }
  }
`;

const Contact = () => {
  const [modal, setModal] = useState(false);

  const submitHandler = () => {
    customaxios
      .post(
        "/app/contactus",
        { topic: formData.topic.value, message: formData.message.value },
        {
          headers: {
            "X-Custom-Header": { alias: "insiemepervincere.com", lang: "it" },
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setModal(true);
          resetFormHandler();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    resetFormHandler,
  } = useForm(submitHandler, contactForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        resetFormHandler={resetFormHandler}
      />
    );
  });

  return (
    <>
      <ContactWrapper>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/contatti-bg.png"}
            alt=""
          />

          <div className="content">
            <div>
              <h3>Contattaci</h3>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                nonumy eirmod tempor <strong>invidunt</strong>. Pisum dolor sit
                amet, <strong>consetetur sadipscing elitr</strong>, sed diam n
                diam nonumy eirmo umquam sed adiscipit nonumy eirmod tempor
                invidunt <strong>adiscipit nonumy</strong> eirmod.
              </p>
            </div>
          </div>
        </div>

        <div className="contact-form-wrapper">
          <h3>Scrivici</h3>

          <div className="contactContent">
            <form onSubmit={formSubmitHandler}>
              {inputs}
              <div className="contactContent-footer">
                <Button upper color="#fff">
                  Invia
                </Button>
              </div>
            </form>
          </div>
        </div>
      </ContactWrapper>
      <Modal show={modal} close={() => setModal(false)}>
        <p>Grazie, il tuo messaggio è stato inviato con successo.</p>
      </Modal>
    </>
  );
};

export default Contact;

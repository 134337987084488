import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SurveyQuiz } from "../../Interactions";
import { Loader } from "../../../Ui";
import { getSlug } from "../../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import { respondTo } from "../../../../theme/mixin";
import SurveysList from "../../../../components/SurveysList/SurveysList";
import { getGames } from "../../../../store/actions";

const SurveyQuizWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .survey-content {
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}
  }
`;

const SurveyQuizHandler = (props) => {
  const dispatch = useDispatch();

  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
    action: { loading },
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const games = useSelector((state) => state.game.games);
  const isLogged = useSelector((state) => state.auth.token);

  const slug = getSlug();

  useEffect(() => {
    if (slug && isLogged && games.length === 0) {
      dispatch(getGames(slug, isLogged));
    }
  }, [dispatch, isLogged, slug, games]);

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  content = (
    <SurveyQuizWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/sondaggi-background.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <h3>Conosciamoci di più</h3>
            <p>
              In questa sezione trovi l’elenco dei{" "}
              <strong>sondaggi da sbloccare</strong>: rispondi alle domande e
              accumula punti da spendere nel nostro catalogo. Puoi iniziare a
              rispondere a un sondaggio e decidere se finirlo subito oppure
              completarlo più tardi.
              <br />
              <br />
              Nel box in basso puoi controllare i{" "}
              <strong>sondaggi completati</strong>, quelli iniziati e avere
              un’anteprima di quelli in arrivo.
              <br />
              <br />
              Rispondi subito alle domande e{" "}
              <strong>inizia ad accumulare punti!</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="survey-content">
        <SurveysList />

        {loading ? (
          <Loader show={loading} full />
        ) : showResult || played !== null ? (
          <ResultGame {...props} />
        ) : !showInteraction && steps.preinteraction.data === null ? (
          <PreInteraction
            preinteraction={preinteraction}
            onShowInteractionHandler={onShowInteractionHandler}
            {...props}
          />
        ) : (
          <SurveyQuiz
            typeInteraction="interaction"
            onShowResult={onShowResult}
            {...props}
          />
        )}
      </div>
    </SurveyQuizWrapper>
  );

  return content;
};

SurveyQuizHandler.propTypes = {
  action: PropTypes.object,
};

export default SurveyQuizHandler;

import React from "react";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";
import { PlayCircle, ChevronLeft } from "@styled-icons/boxicons-regular";
import Checkbox from "../../components/Ui/Input/Checkbox/Checkbox";
import { Link } from "react-router-dom";

const Course = (props) => {
  return (
    <>
      <CourseOverview>
        <div className="video">
          <img src={process.env.PUBLIC_URL + "/images/elearning.jpg"} alt="" />
          <PlayCircle size={90} />
        </div>
        <div className="info">
          <span>00/10/2022</span>
          <h5>Lorem ipsum dolor sit amet</h5>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor <strong>invidunt</strong> ut labore et dolore
            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
            justo <strong>duo dolores et ea rebum.</strong>
            <br />
            <br /> Stet clita kasd gubergren, no sea takimata sanctus est Lorem
            ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr.
          </p>
          <Link to="/e-learning">
            <ChevronLeft size={30} /> Indietro
          </Link>
        </div>
      </CourseOverview>

      <YourOpinion>
        <h3>Rispondi al questionario</h3>

        <div className="questionnaire-wrap">
          <div className="questions-box">
            <strong>Domande</strong>
            <ul>
              <li className="active">
                Domanda 1 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 2 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 3 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 4 <CheckCircle size={24} />
              </li>
            </ul>
            <div className="points-box">
              <span>Puoi ottenere</span>
              <div>
                <img src={process.env.PUBLIC_URL + "/icons/gift.svg"} alt="" />
                <strong>000 PT</strong>
              </div>
            </div>
          </div>
          <div className="answer-box">
            <strong>Seleziona una risposta</strong>
            <p>
              Testo della domanda lorem ipsum dolor sit amet sed adiscipit elit
              numquam?
            </p>
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />

            <Button bg="#EBEBEB" color="#1D241E">
              Rispondi
            </Button>
          </div>
        </div>
      </YourOpinion>
    </>
  );
};

const CourseOverview = styled.div`
  margin-top 130px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .video {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 505px;
    border-radius: 24px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
    
    svg {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ${respondTo.md`
      width: calc(66.666% - 12px);
    `}
  }

  .info {
    width: 100%;
    background-color: #00852A;
    border-radius: 24px;
    padding: 16px;

    span {
      color: #1BB410;
      font-size: 24px;
    }

    h5 {
      color: #fff;
      font-size: 44px;
      font-weight: 300;
      line-height: 1.2;
      margin-top: 25px;
      margin-bottom: 15px;
    }

    a {
      display: inline-block;
      margin-top: 70px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: #1D241E;
      font-size: 16px;
    }

    ${respondTo.md`
      width: calc(33.333% - 12px);
    `}
  }
`;

const YourOpinion = styled.div`
  background-color: #ebebeb;
  border-radius: 24px;
  position: relative;
  padding: 50px 0;

  h3 {
    color: #1d241e;
    font-size: 44px;
    margin-bottom: 10px;
  }

  .questionnaire-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .questions-box {
      width: 100%;
      background-color: #00852a;
      border-radius: 24px;
      padding: 16px;
      margin-bottom: 16px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(40% - 12px);
      `}

      strong {
        font-size: 12px;
        color: #1bb410;
        text-transform: uppercase;
      }

      ul {
        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 24px;
          height: 64px;
          background-color: #ebebeb;
          color: #94a2ac;
          font-size: 18px;
          padding: 0 12px;
          margin-top: 12px;
          font-weight: 600;

          &.active {
            color: #fff;
            background-color: #1bb410;
          }
        }
      }

      .points-box {
        margin-top: 80px;
        background-color: #1bb410;
        width: 105px;
        border-radius: 16px;
        padding: 5px 12px;

        span {
          font-size: 12px;
          color: #00852a;
        }

        div {
          display: flex;
          align-items: center;

          strong {
            display: inline-block;
            margin-left: 5px;
            color: #fff;
          }
        }
      }
    }

    .answer-box {
      background-color: #fff;
      border-radius: 24px;
      padding: 16px;
      width: 100%;

      ${respondTo.md`
        width: calc(60% - 12px);
      `}

      strong {
        text-transform: uppercase;
        font-size: 12px;
        color: #94a2ac;
      }

      p {
        font-size: 32px;
        color: #1bb410;
        font-weight: 300;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }

      & > div {
        margin-bottom: 24px;
      }

      button {
        margin-top: 55px;
        margin-left: auto;
      }
    }
  }
`;

export default Course;

import React, { useState, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Input, Button, Loader, Modal } from "../../Ui";
import Fade from "../../Fade";
import styled from "styled-components";
import { respondTo } from "../../../theme/mixin";
import { useTranslation } from "react-i18next";
import customaxios from "../../../config/axios-refresh-token";
import { getSlug } from "../../../utils/utils";
import ReactGA from "react-ga";
import parse from "html-react-parser";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { getUserInfo } from "../../../store/actions/index";

const FormContainer = styled.div`
  padding: 20px;

  ${respondTo.sm`
		padding: 0;
		margin: 0 -15px;
		margin: 50px 0;
	`}
`;

const FormList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .left {
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    background-color: #6cb967;
    box-shadow: 0px 5px 15px #00000026;

    ${respondTo.md`
      width: calc(33.333% - 15px);
    `}

    h3 {
      color: #fff;
    }
  }

  .right {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 5px 15px #00000026;
    margin-top: 20px;

    ${respondTo.md`
      width: calc(66.666% - 15px);
      margin-top: 0;
    `}

    h3 {
      color: #6cb967;
    }

    .untouchable {
      padding: 15px;
      margin: 0 -15px;
      background-color: #e4e7f1;

      h4 {
        font-size: 18px;
        color: #185e14;
        font-weight: 300;
      }
    }

    .options-fields {
      position: relative;
      margin-top: 30px;
      margin-bottom: 20px;
      padding: 0 20px;

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 100%;
        background-color: #6cb967;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .others-fields {
      .fields-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > div {
          width: 100%;

          ${respondTo.md`
            width: calc(50% - 10px);
          `}
        }
      }

      button {
        margin-left: auto;
      }
    }
  }

  /* ${respondTo.sm`
    display: flex;
    flex-wrap: wrap;
		align-items: flex-start;
		border-radius: 0;
    background-color: transparent;
    padding: 0 50px;
  `}

  ${respondTo.md`
    padding: 0;
    flex-wrap: nowrap;
	`} */

  /* & li {
    padding: 20px;
    border-bottom: 1px solid #c5cbd1;

    ${respondTo.sm`
			width: 100%;
			margin: 0 15px;
			overflow: hidden;
			background-color: #fff;
			border-radius: 20px;
			border: none;
      box-shadow: 0 3px 6px #63666919;
      margin-bottom: 30px;
    `}

    ${respondTo.md`
			width: calc(33.333% - 15px);
		`}

    &:first-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .staticInputs {
      padding: 20px 20px 2px 20px;
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      background-color: #f0f0f0;

      .staticInputHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        span {
          text-transform: uppercase;
          font-size: 12px;

          &:first-child {
            color: ${(props) => props.theme.primary};
          }
        }
      }

      input {
        background-color: #e2e2e2;
      }
    }

    & input[type="number"] {
      background-color: ${(props) => props.theme.input.bg};
    }
  }

  & li:first-child > div {
    margin-bottom: 0;
  }

  & li:last-child {
    border-bottom: none;
  } */
`;

const TextP = styled.h3`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Response = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;

  h3 {
    font-size: 34px;
    color: ${(props) => props.theme.primary};
  }

  p {
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 18px;
  }

  button {
    margin: 0 auto;
  }
`;

const Title = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const Content = styled.p`
  font-size: 14px;
`;

const Extra = styled.p`
  font-size: 20px;
  margin-bottom: 15px;
`;

const Form = (props) => {
  const {
    form: {
      data,
      type,
      i18l: { title, content, extra },
    },
    lang,
    id,
    playslog,
    formResponse,
    played,
    has_pending_play,
  } = props;

  let showResponse = false;
  if (formResponse !== null && played) {
    showResponse = true;
  }

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(showResponse);

  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState("");

  const [blockFields, setBlockFields] = useState(true);
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);

  const [t] = useTranslation();
  const dataForm = JSON.parse(data);

  let slug = getSlug();

  const submitHandler = (e) => {
    const objData = {};
    for (const property in formData) {
      objData[property] = formData[property].value;
      if (formData[property].valuetosend) {
        objData[property] = formData[property].valuetosend;
      } else {
        objData[property] = formData[property].value;
      }
    }

    const data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === "file") {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === "fileextra") {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append("data", JSON.stringify(objData));
    data.append("section", "form");
    if (type === "post") {
      data.append("playslog", playslog);
    }
    setLoading(true);
    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data.data.status) {
            setShowFormResponse(true);
            ReactGA.event({
              category: "Convalida",
              action: "Convalida",
            });
            dispatch(getUserInfo());
          } else {
            setShowModal(true);
            setInfo(response.data.data.info);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowModal(true);
        setInfo(
          "errore interno: segnala questo errore al servizio clienti" + error
        );
      });
  };

  const closeModal = () => {
    setInfo("");
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === "datepicker") {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const onSetImmagine = (value) => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const onSetSelectedFiles = (file) => {
    setSelectedFiles((prevState) => {
      return [...prevState, file];
    });
  };

  useEffect(() => {
    if (immagine) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
    }
  }, [immagine]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
    onSetFormValid,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        inModal={true}
        radioInputs={inp.inputs}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        onSetImmagine={onSetImmagine}
        onSetFormValid={onSetFormValid}
        onSetSelectedFiles={onSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        resetImage={resetImage}
        {...inp}
      />
    );
  });

  const user = useSelector((state) => state.user.user.userinfo);
  const convalidaArea = useSelector(
    (state) => state.cms.convalida[0].contents[0].i18l
  );

  const staticData = [
    {
      label: "",
      value: user.firstname || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstname",
        id: "firstname",
        placeholder: "Nome",
        disabled: true,
      },
    },
    {
      label: "",
      value: user.lastname || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastname",
        id: "lastname",
        placeholder: "Cognome",
        disabled: true,
      },
    },
    {
      label: "",
      value: user.email || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "email",
        id: "email",
        placeholder: "Email",
        disabled: true,
      },
    },
  ];

  return (
    <div className="container">
      <FormContainer>
        {!showFormResponse && has_pending_play ? (
          <Fade show={true}>
            <form onSubmit={formSubmitHandler}>
              <FormList>
                <div className="left">
                  <TextP>Documento d'identità</TextP>
                  {inputs.slice(0, 6)}
                </div>
                <div className="right">
                  <TextP>Dati personali e convalida o rifiuto premio</TextP>
                  <div className="untouchable">
                    <h4>Campi non modificabili</h4>
                    {staticData.map((inp, indx) => (
                      <Input
                        error={inp.errorMessage}
                        key={indx}
                        value={inp.value}
                        elementType={inp.elementType}
                        elementConfig={inp.elementConfig}
                        changed={inputChangedHandler}
                        label={inp.label[lang]}
                        isvalid={inp.valid}
                        istouched={inp.validation.touched}
                        firstSubmit={firstSubmit}
                        startdate={startDate}
                        changedDatePicker={datePickerHandler}
                        inModal={true}
                        radioInputs={inp.inputs}
                      />
                    ))}
                  </div>
                  <div className="options-fields">{inputs[6]}</div>

                  <div className="others-fields">
                    {inputs[6].props.value === "1" ? (
                      <>
                        <TextP>Indirizzo di spedizione</TextP>
                        <div className="fields-list">{inputs.slice(7)}</div>
                      </>
                    ) : null}
                    <Button
                      upper
                      type="submit"
                      disabled={loading}
                      gradient={{ left: "#6CB967", right: "#96F290" }}
                      hoverGradient={{ left: "#96F290", right: "#96F290" }}
                    >
                      {loading ? <Loader inBtn show={true} /> : t("auth.send")}
                      <ChevronRight size={30} />
                    </Button>
                  </div>
                </div>
              </FormList>
            </form>
          </Fade>
        ) : (
          <Response>
            <h3>{convalidaArea.title}</h3>
            {parse(convalidaArea.content)}
            <Link to="/">
              <Button
                upper
                gradient={{ left: "#6CB967", right: "#96F290" }}
                hoverGradient={{ left: "#96F290", right: "#96F290" }}
              >
                Torna alla home
              </Button>
            </Link>
          </Response>
        )}
        {showModal ? (
          <Modal show={showModal} close={closeModal}>
            <p>{info && info}</p>
          </Modal>
        ) : null}
      </FormContainer>
    </div>
  );
};

export default Form;

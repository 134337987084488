import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../components/Ui";
import Parser from "html-react-parser";
import { useParams, useHistory, Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import ModalMessage from "../../components/ModalMessage";
import ErrorInModal from "../../components/ErrorInModal";
import { Modal } from "../../components/Ui";
import {
  ChevronLeft,
  ChevronRight,
  Copy,
} from "@styled-icons/boxicons-regular";
import moment from "moment";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";

const SPContainer = styled.div`
  margin-top: 120px;

  .sp-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .sp-image {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      padding: 15px;

      ${respondTo.md`
        width: calc(40% - 15px);
      `}

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        object-fit: cover;
      }

      .sp-brand {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 5px 15px #00000029;

        img {
          width: 100%;
        }
      }
    }

    .sp-info {
      width: 100%;
      margin-top: 30px;

      ${respondTo.md`
        width: calc(60% - 15px);
        margin-top: 0;
      `}

      .sp-header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        img {
          margin-right: 10px;
        }

        span {
          color: #ffdf8d;
          font-weight: 300;
        }

        strong {
          color: #fdca46;
        }
      }

      .sp-body {
        .sp-brand-name {
          color: #ffdf8d;
          font-size: 18px;
        }

        .sp-brand-subtitle {
          display: block;
          font-size: 28px;
          color: #fdca46;
          margin-bottom: 10px;
        }
      }

      .sp-footer {
        display: flex;
        margin-top: 50px;
      }
    }
  }

  .sp-coupon-success {
    width: 100%;
    background-color: #fdca46;
    border-radius: 15px;
    padding: 15px;
    margin-top: 50px;
    box-shadow: 0px 5px 15px #00000026;

    .sp-coupon-header {
      display: flex;
      justify-content: space-between;

      h3 {
        color: #fff;
        font-size: 21px;
        font-weight: 700;
      }

      .sp-coupon-brand {
        width: 70px;
        height: 70px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 5px 15px #00000029;
        overflow: hidden;
      }
    }

    .coupon-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .coupon-success-left {
        width: 100%;

        ${respondTo.md`
          width: calc(45% - 20px);
        `}

        h4 {
          color: #fff;
          font-size: 28px;
          margin-bottom: 10px;
        }
      }

      .coupon-success-right {
        width: 100%;
        text-align: center;
        margin-top: 30px;

        ${respondTo.md`
          margin-top: 0;
          width: calc(55% - 20px);
        `}

        h5 {
          color: #fff;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .coupon-box {
          background-color: #ffeec1;
          padding: 20px;
          border-radius: 15px;

          span {
            font-weight: 300;
            font-size: 28px;
            color: #fdca46;
          }

          .coupon-expiration {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cb9200;
            text-transform: uppercase;
            font-size: 12px;

            img {
              margin-right: 5px;
            }
          }
        }

        .coupon-footer {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          ${respondTo.md`
            flex-wrap: nowrap;
            justify-content: space-between;
          `}

          strong {
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            margin-bottom: 15px;
            font-size: 16px;

            ${respondTo.md`
              margin-bottom: 0;
            `}

            &:hover {
              color: #ffdf8d;
            }
          }

          button {
            background-color: #fff;
            color: #fdca46;

            &:hover {
              background-color: #ffdf8d;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .coupon-terms {
    width: 100%;
    position: relative;
    top: -10px;
    background-color: #fff;
    box-shadow: 0px 5px 15px #00000026;
    padding: 30px 15px 15px 15px;
    border-radius: 15px;
    z-index: -1;

    strong {
      color: #fdca46;
      font-size: 21px;
      display: block;
      margin-bottom: 10px;
    }
  }
`;

const SinglePartner = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [partner, setPartner] = useState();
  const [modalError, setModalError] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(null);

  const partners = useSelector((state) => state.partner.partnerList);
  const vouchers = useSelector((state) => state.user.user.vouchers);
  const isLogged = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (id && partners) {
      const newPartner = partners.find((partner) => partner.id === Number(id));

      setPartner(newPartner);
    }
  }, [partners, id]);

  useEffect(() => {
    if (partner && vouchers) {
      const existCoupon = vouchers.find(
        (voucher) => voucher.partner_id === partner.id
      );

      if (existCoupon) {
        setHasCoupon(existCoupon);
      }
    }
  }, [partner, vouchers]);

  const requestCouponHandler = () => {
    let endpoint = `partners/${partner.id}/request/`;
    customaxios
      .post(endpoint)
      .then((result) => {
        dispatch(actionCreators.getUserInfo());
      })
      .catch((error) => {
        setModalError(error.response.data.error);
      });
  };

  const closeModalError = () => {
    setModalError(null);
  };

  return (
    <SPContainer>
      {partner && (
        <div className="sp-wrapper">
          <div className="sp-image">
            <img src={partner.main_image} alt="" />
            <div className="sp-brand">
              <img src={partner.logo} alt="" />
            </div>
          </div>

          <div className="sp-info">
            <ContentBorderBox position="right" color="#FDCA46">
              <div className="sp-header">
                <img
                  src={process.env.PUBLIC_URL + "/icons/discount-icon.svg"}
                  alt=""
                />
                <div>
                  <span>Sconto</span>
                  <br />
                  <strong>{partner.under_value}</strong>
                </div>
              </div>

              <div className="sp-body">
                <span className="sp-brand-name">{partner.title}</span>
                <span className="sp-brand-subtitle">{partner.subtitle}</span>

                {Parser(partner.description)}
              </div>

              <div className="sp-footer">
                <Button
                  onClick={() => history.goBack()}
                  upper
                  gradient={{ left: "#E4E7F1", right: "#ADB1BC" }}
                  hoverGradient={{ left: "#FF7DB9", right: "#E61274" }}
                >
                  <ChevronLeft size={30} />
                  Indietro
                </Button>

                {isLogged && (
                  <>
                    {!hasCoupon && (
                      <Button
                        gradient={{ left: "#FDCA46", right: "#FFEEC1" }}
                        hoverGradient={{ left: "#FFDF8D", right: "#FFDF8D" }}
                        upper
                        style={{ marginLeft: 15 }}
                        onClick={() => requestCouponHandler()}
                      >
                        Richiedi
                        <img
                          style={{ marginLeft: 5 }}
                          src={
                            process.env.PUBLIC_URL +
                            "/icons/request-coupon-icon.svg"
                          }
                          alt=""
                        />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </ContentBorderBox>
          </div>

          {hasCoupon && (
            <>
              <div className="sp-coupon-success">
                <div className="sp-coupon-header">
                  <h3>Ecco il tuo coupon</h3>
                  <div className="sp-coupon-brand">
                    <img src={partner.logo} alt="" />
                  </div>
                </div>

                <div className="coupon-row">
                  <div className="coupon-success-left">
                    <h4>Grazie per la tua richiesta!</h4>

                    <p>
                      Ti abbiamo inviato{" "}
                      <strong>un'email con tutte le istruzioni </strong> per
                      utilizzare il tuo coupon.
                      <br />
                      <br />
                      In alternativa, puoi copiare direttamente{" "}
                      <strong>il tuo codice coupon</strong> e utilizzarlo subito
                      <strong> sul sito del partner che hai scelto.</strong>
                    </p>
                  </div>

                  <div className="coupon-success-right">
                    <h5>Il codice coupon</h5>

                    <div className="coupon-box">
                      <span>{hasCoupon.code}</span>
                      <div className="coupon-expiration">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/icons/calendar-icon.svg"
                          }
                          alt=""
                        />
                        <strong>
                          Scade:{" "}
                          {moment(partner.voucher_expires_at).format(
                            "DD/MM/YYYY"
                          )}
                        </strong>
                      </div>
                    </div>

                    <div className="coupon-footer">
                      <strong
                        onClick={() => {
                          navigator.clipboard.writeText(hasCoupon.code);
                        }}
                      >
                        Copia il codice <Copy size={32} />
                      </strong>
                      <Link
                        to={{
                          pathname: partner.url,
                        }}
                        target="_blank"
                      >
                        <Button upper>
                          Vai al sito partner <ChevronRight size={30} />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupon-terms">
                <strong>Termini e condizioni</strong>
                {Parser(partner.howto)}
              </div>
            </>
          )}
        </div>
      )}

      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </SPContainer>
  );
};

export default SinglePartner;

import React, { useState, useEffect } from "react";

import {
  validateEmail,
  validatePassword,
  customRegexValidation,
  getDistricts,
  getCities,
} from "../utils/utils";

const useForm = (
  callback,
  formState,
  datepickername,
  resetForm = false,
  isConvalida = false
) => {
  const [formData, setFormData] = useState(formState);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [control, setControl] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [passwordValue, setPasswordValue] = useState("");
  const [startDate, setStartDate] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcode, setZipcode] = useState("");

  const checkValidity = (value, rules, target) => {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
      if (target.type === "checkbox") {
        if (target.checked == true) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      if (target.type === "radio") {
        isValid = true;
      }
      if (target.type === "file") {
        if (target.value) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    if (rules.isEmail) {
      isValid = validateEmail(value) && isValid;
    }
    if (rules.isPassword) {
      isValid = validatePassword(value) && isValid;
    }
    if (rules.isConfirmPassword) {
      isValid = value === passwordValue && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isCustomCode) {
      isValid = customRegexValidation(rules.regex, value) && isValid;
    }
    return isValid;
  };

  const inputChangedHandler = (event) => {
    const updateFormData = {
      ...formData,
    };

    if (event.target.name === "district") {
      setCities([]);
      getCities(event.target.value).then((data) => {
        setCities(data);
      });
    }

    if (event.target.name === "city") {
      let zipcode = cities.filter((city) => city.name === event.target.value)[0]
        .zipcode;
      setZipcode(zipcode);

      updateFormData["zipcode"].valid = true;
      updateFormData["zipcode"].value = cities.filter(
        (city) => city.name === event.target.value
      )[0].zipcode;
    }

    if (event.target.name === "zipcode") {
      setZipcode(event.target.value);
    }

    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? "1"
          : "0"
        : event.target.value;
    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }

    if (updateFormDataElement.validation.toMatch) {
      setPasswordValue(updateFormDataElement.value);
    }
    setControl(true);

    if (
      updateFormData.prize &&
      updateFormData.prize.value === "2" &&
      updateFormData.document_type.valid &&
      updateFormData.document_number.valid &&
      updateFormData.document_expiration.valid &&
      updateFormData.document_source.valid &&
      updateFormData.file.valid &&
      updateFormData.fileextra.valid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(formIsValid);
    }

    setFormData(updateFormData);
  };

  const resetFormHandler = () => {
    const updateFormData = {
      ...formData,
    };

    for (let i in updateFormData) {
      if (updateFormData[i].elementType === "inputfile") {
        if (updateFormData[i].valid === true) {
          updateFormData[i].valid = true;
        }
      } else {
        if (updateFormData[i].required === true) {
          updateFormData[i].valid = false;
        }
        if (updateFormData[i].elementType === "inputrange") {
          updateFormData[i].value = { min: 0, max: updateFormData[i].maxValue };
        } else {
          updateFormData[i].value = "";
        }
      }
    }
    setFormData(updateFormData);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setFirstSubmit(true);
    if (control) {
      setShowError(true);
    }
    let formDataObj = {};

    for (let formEl in formData) {
      if (formData[formEl].valuetosend) {
        formDataObj[formEl] = formData[formEl].valuetosend;
      } else {
        formDataObj[formEl] = formData[formEl].value;
      }
    }

    let canSubmit = true;

    if (isConvalida) {
      if (formData.prize && formData.prize.value !== "2") {
        for (let i in formData) {
          if (formData[i].validation.required && !formData[i].valid) {
            canSubmit = false;
            break;
          }
        }
      }
    } else {
      for (let i in formData) {
        if (formData[i].validation.required && !formData[i].valid) {
          canSubmit = false;
          break;
        }
      }
    }

    if (canSubmit && formIsValid) {
      callback(formDataObj);
    }
  };

  const datePickerHandler = (date) => {
    setStartDate(date);
  };

  const fileUploadHandler = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (file !== undefined) {
      setSelectedFile(file);

      const updateFormData = {
        ...formData,
      };
      const updateFormDataElement = {
        ...updateFormData[event.target.name],
      };

      updateFormDataElement.valid = checkValidity(
        updateFormDataElement.value,
        updateFormData[event.target.name].validation,
        event.target
      );

      updateFormDataElement.touched = true;
      updateFormData[event.target.name] = updateFormDataElement;

      let formIsValid = true;
      for (let key in updateFormData) {
        formIsValid = updateFormData[key].valid && formIsValid;
      }

      setControl(true);
      if (
        updateFormData.prize &&
        updateFormData.prize.value === "2" &&
        updateFormData.document_type.valid &&
        updateFormData.document_number.valid &&
        updateFormData.document_expiration.valid &&
        updateFormData.document_source.valid &&
        updateFormData.file.valid &&
        updateFormData.fileextra.valid
      ) {
        setFormIsValid(true);
      } else {
        setFormIsValid(formIsValid);
      }
      setFormData(updateFormData);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl(null);
    }
  };

  const deleteFileHandler = () => {
    if (resetForm) {
      resetFormHandler();
      setFormIsValid(false);
    }
    setImgPreviewUrl(null);
  };

  useEffect(() => {
    getDistricts().then((data) => {
      setDistricts(data);
    });
  }, []);

  useEffect(() => {
    if (cities.length === 0) {
      setZipcode("");
    }
  }, [cities]);

  useEffect(() => {
    if (startDate !== null) {
      for (var i = 0; i < datepickername.length; i++) {
        const updateFormData = {
          ...formData,
        };
        const updateFormDataElement = {
          ...updateFormData[`${datepickername[i]}`],
        };

        if (startDate !== null) {
          updateFormDataElement.value = startDate;
          let newDate = new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000
          ).toISOString();
          updateFormDataElement.valuetosend = newDate;
        }

        updateFormDataElement.valid = true;
        updateFormDataElement.touched = true;
        updateFormData[`${datepickername[i]}`] = updateFormDataElement;

        let formIsValid = true;
        for (let key in updateFormData) {
          formIsValid = updateFormData[key].valid && formIsValid;
        }

        setControl(true);
        setFormIsValid(formIsValid);
        setFormData(updateFormData);
      }
    }
  }, [startDate]);

  const onSetFormValid = (event) => {
    const updateFormData = {
      ...formData,
    };
    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid;
    }

    setControl(true);
    if (
      updateFormData.prize &&
      updateFormData.prize.value === "2" &&
      updateFormData.document_type.valid &&
      updateFormData.document_number.valid &&
      updateFormData.document_expiration.valid &&
      updateFormData.document_source.valid &&
      updateFormData.file.valid &&
      updateFormData.fileextra.valid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(formIsValid);
    }
    setFormData(updateFormData);
  };

  return {
    inputChangedHandler,
    formSubmitHandler,
    formData,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    formIsValid,
    resetFormHandler,
    districts,
    cities,
    zipcode,
    onSetFormValid,
  };
};

export default useForm;

import React from "react";
import styled from "styled-components";

const TextareaField = styled.textarea`
  outline: none;
  width: 100%;
  background-color: ${(props) => props.theme.input.bg};
  border: 1px solid
    ${(props) =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};
  border-radius: 15px;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 20px;
  height: 180px;
`;

const Textarea = (props) => {
  return <TextareaField {...props} />;
};

export default Textarea;

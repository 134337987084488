import React from "react";
import { useTranslation } from "react-i18next";

import SigninForm from "../../components/SigninForm";
import AuthTemplate from "../../templates/AuthTemplate";

const Login = (props) => {
  const { logo, logoinverted } = props;

  const { from } = props.location.state || { from: "/" };
  const [t] = useTranslation();

  return (
    <AuthTemplate
      logo={logo}
      logoinverted={logoinverted}
      titlepage={t("auth.signinlabel")}
    >
      <SigninForm whereToGo={from} />
    </AuthTemplate>
  );
};

export default Login;

import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`;

export default Wrapper;

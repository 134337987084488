import React from "react";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";
import Checkbox from "../../components/Ui/Input/Checkbox/Checkbox";

const ProfileContainer = styled.div`
  .slide {
    z-index: 11;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 25px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 15px;
      `}

      .content-info {
        max-width: 540px;
        color: #1d241e;

        h3 {
          font-size: 44px;
          margin-bottom: 15px;
        }
      }

      .personal-awards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        .award-box {
          width: 200px;
          margin-top: 30px;
          margin-right: 30px;

          strong {
            font-size: 12px;
            color: #1d241e;
            text-transform: uppercase;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-size: 64px;
              color: #00852a;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;

const Questions = (props) => {
  return (
    <>
      <ProfileContainer>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/questions-bg.png"}
            alt=""
          />

          <div className="content">
            <div>
              <div className="content-info">
                <h3>Dicci la tua</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                  nonumy eirmod tempor <strong>invidunt</strong>. Pisum dolor
                  sit amet, <strong>consetetur sadipscing elitr</strong>, sed
                  diam n diam nonumy eirmo umquam sed adiscipit nonumy eirmod
                  tempor invidunt <strong>adiscipit nonumy</strong> eirmod.
                </p>
              </div>
              <div className="personal-awards">
                <div className="award-box">
                  <strong>Piacere di conoscerti</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/met-icon.svg"}
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
                <div className="award-box">
                  <strong>La tua opinione conta</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/chats-icon.svg"}
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
                <div className="award-box">
                  <strong>Punti classifica ottenuti</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/star-big-icon.svg"}
                      alt=""
                    />
                    <span>00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileContainer>

      <NiceToMetYou>
        <h3>
          Piacere di conoscerti <span>Novità</span>
        </h3>
        <div className="questions-box">
          <div className="q-left-side">
            <div className="q-image">
              <img src={process.env.PUBLIC_URL + "/images/man.jpg"} alt="" />

              <div className="points-box">
                <span>Puoi ottenere</span>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift.svg"}
                    alt=""
                  />
                  <strong>000 PT</strong>
                </div>
              </div>
            </div>
            <div className="q-info">
              <strong>LA NOSTRA DOMANDA</strong>
              <p>
                Testo della domanda lorem ipsum dolor sit amet sed adiscipit
                elit numquam?
              </p>
            </div>
          </div>
          <div className="q-right-side">
            <strong>SELEZIONA UNA RISPOSTA</strong>

            <div className="q-answers">
              <Checkbox label="Risposta lorem ipsum" bg="#fff" />
              <Checkbox label="Risposta lorem ipsum" bg="#fff" />
              <Checkbox label="Risposta lorem ipsum" bg="#fff" />
            </div>

            <Button bg="#fff" color="#1D241E">
              Rispondi
            </Button>
          </div>
        </div>
      </NiceToMetYou>

      <YourOpinion>
        <h3>La tua opinione conta</h3>

        <div className="questionnaire-wrap">
          <div className="questions-box">
            <strong>Titolo questionario</strong>
            <ul>
              <li className="active">
                Domanda 1 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 2 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 3 <CheckCircle size={24} />
              </li>
              <li>
                Domanda 4 <CheckCircle size={24} />
              </li>
            </ul>
            <div className="points-box">
              <span>Puoi ottenere</span>
              <div>
                <img src={process.env.PUBLIC_URL + "/icons/gift.svg"} alt="" />
                <strong>000 PT</strong>
              </div>
            </div>
          </div>
          <div className="answer-box">
            <strong>Seleziona una risposta</strong>
            <p>
              Testo della domanda lorem ipsum dolor sit amet sed adiscipit elit
              numquam?
            </p>
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />
            <Checkbox label="Risposta lorem ipsum" bg="#EBEBEB" />

            <Button bg="#EBEBEB" color="#1D241E">
              Rispondi
            </Button>
          </div>
        </div>
      </YourOpinion>
    </>
  );
};

const YourOpinion = styled.div`
  background-color: #ebebeb;
  border-radius: 24px;
  position: relative;
  padding: 50px 12px;

  ${respondTo.md`
    top: -50px;
  `}

  h3 {
    color: #1d241e;
    font-size: 44px;
  }

  .questionnaire-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .questions-box {
      width: 100%;
      background-color: #fff;
      border-radius: 24px;
      padding: 16px;
      margin-bottom: 16px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(40% - 12px);
      `}

      strong {
        font-size: 12px;
        color: #94a2ac;
        text-transform: uppercase;
      }

      ul {
        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 24px;
          height: 64px;
          background-color: #ebebeb;
          color: #94a2ac;
          font-size: 18px;
          padding: 0 12px;
          margin-top: 12px;
          font-weight: 600;

          &.active {
            color: #fff;
            background-color: #1bb410;
          }
        }
      }

      .points-box {
        margin-top: 80px;
        background-color: #1bb410;
        width: 105px;
        border-radius: 16px;
        padding: 5px 12px;

        span {
          font-size: 12px;
          color: #00852a;
        }

        div {
          display: flex;
          align-items: center;

          strong {
            display: inline-block;
            margin-left: 5px;
            color: #fff;
          }
        }
      }
    }

    .answer-box {
      background-color: #fff;
      border-radius: 24px;
      padding: 16px;
      width: 100%;

      ${respondTo.md`
        width: calc(60% - 12px);
      `}

      strong {
        text-transform: uppercase;
        font-size: 12px;
        color: #94a2ac;
      }

      p {
        font-size: 32px;
        color: #1bb410;
        font-weight: 300;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }

      & > div {
        margin-bottom: 24px;
      }

      button {
        margin-top: 55px;
        margin-left: auto;
      }
    }
  }
`;

const NiceToMetYou = styled.div`
  background-color: #fff;
  position: relative;
  padding: 50px 12px;

  ${respondTo.md`
    top: -30px;
  `}

  h3 {
    font-size: 44px;
    color: #1d241e;

    span {
      font-size: 12px;
      font-weight: 700;
      color: #00852a;
      text-transform: uppercase;
      position: relative;
      top: -7px;

      &:before {
        content: "";
        display: inline-block;
        background-color: #1bb410;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 5px;
        position: relative;
        top: 3px;
      }
    }
  }

  .questions-box {
    margin-top: 10px;
    background-color: #ebebeb;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .q-left-side {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
        width: calc(50% - 15px);
      `}

      .q-image {
        position: relative;
        overflow: hidden;
        flex: 0 0 183px;
        border-radius: 16px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .points-box {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background-color: #1bb410;
          width: 105px;
          margin-top: 25px;
          border-radius: 16px;
          padding: 5px 12px;

          span {
            font-size: 12px;
            color: #00852a;
          }

          div {
            display: flex;
            align-items: center;

            strong {
              display: inline-block;
              margin-left: 5px;
              color: #fff;
            }
          }
        }
      }

      .q-info {
        padding-left: 0;

        ${respondTo.md`
          padding-left: 24px;
        `}

        strong {
          font-size: 12px;
          color: #94a2ac;
        }

        p {
          margin-top: 8px;
          color: #1bb410;
          font-size: 32px;
          font-weight: 300;
        }
      }
    }

    .q-right-side {
      padding-left: 24px;
      width: 100%;
      border-left: 1px solid #fff;
      margin-top: 16px;

      ${respondTo.md`
        margin-top: 0;
        width: calc(50% - 15px);
      `}

      strong {
        font-size: 12px;
        color: #94a2ac;
      }

      .q-answers {
        margin-top: 8px;

        & > div {
          margin-bottom: 24px;
        }
      }

      button {
        margin-left: auto;
      }
    }
  }
`;

export default Questions;

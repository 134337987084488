import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { respondTo } from "../../theme/mixin";

const LogoutContainer = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.text.primary};

  ${respondTo.sm`
  font-size: 18px;`}
`;

const Icon = styled.svg`
  g g {
    fill: ${(props) => props.theme.primary};
  }
`;

const Logout = (props) => {
  const { clicked, label } = props;

  return (
    <LogoutContainer onClick={clicked} {...props}>
      {label}
      {isMobile && (
        <Icon width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-3.000000, -4.000000)" fill-rule="nonzero">
              <circle
                opacity="0.4"
                transform="translate(18.161786, 19.160995) rotate(-13.063000) translate(-18.161786, -19.160995) "
                cx="18.1617856"
                cy="19.1609948"
                r="15.133"
              ></circle>
              <path d="M21.155,17.083 C22.866967,15.8882352 23.7034311,13.7890809 23.282332,11.7443389 C22.8612329,9.69959698 21.263403,8.10176709 19.2186611,7.68066799 C17.1739191,7.2595689 15.0747648,8.09603295 13.88,9.808 L21.155,17.083 Z"></path>
              <path d="M19.776,20.777 C19.5426667,20.761 19.3073333,20.753 19.07,20.753 L17.252,20.753 C13.5969876,20.7535705 10.2344314,22.751311 8.486,25.961 C10.4907297,28.8222913 13.6593604,30.6492281 17.1400316,30.9506534 C20.6207028,31.2520787 24.0562301,29.9970605 26.523,27.523 L19.776,20.777 Z"></path>
              <g transform="translate(7.807000, 8.808000)">
                <path d="M19.513,20.7070018 C19.1959553,20.7075559 18.8917961,20.5815728 18.668,20.357 L0.35,2.039 C-0.116404471,1.57259553 -0.116404471,0.816404471 0.35,0.35 C0.816404471,-0.116404471 1.57259553,-0.116404471 2.039,0.35 L20.357,18.668 C20.6980596,19.009861 20.7998476,19.5233932 20.6149723,19.9695005 C20.430097,20.4156079 19.994898,20.7066025 19.512,20.7070018 L19.513,20.7070018 Z"></path>
              </g>
            </g>
          </g>
        </Icon>
      )}
    </LogoutContainer>
  );
};

Logout.propTypes = {
  clicked: PropTypes.func.isRequired,
  children: PropTypes.any,
  label: PropTypes.string,
};

export default Logout;

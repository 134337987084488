import * as actionTypes from "./actionTypes";

import { getCmsFooter, getCmsHow, getCmsCoupon, getCmsConvalida } from "./cms";
import { checkAuth, checkAuthState } from "./auth";
import { appInit, oauthInfo } from "./app";
import { getAllProducts, getFiltersCategories } from "./products";

const bootAppStart = () => {
  return {
    type: actionTypes.BOOT_APP_START,
  };
};

const bootAppSuccess = () => {
  return {
    type: actionTypes.BOOT_APP_SUCCESS,
  };
};

const bootAppError = (error) => {
  return {
    type: actionTypes.BOOT_APP_ERROR,
    error,
  };
};

export const bootApp = () => {
  return (dispatch) => {
    dispatch(bootAppStart());
    Promise.all([
      dispatch(appInit()),
      dispatch(checkAuth()),
      dispatch(checkAuthState()),
      dispatch(oauthInfo()),
      //dispatch(getCmsFooter()),
      //dispatch(getCmsHow()),
      //dispatch(getCmsCoupon()),
      dispatch(getCmsConvalida()),
      dispatch(getAllProducts()),
      dispatch(getFiltersCategories()),
    ])
      .then(() => {
        dispatch(bootAppSuccess());
      })
      .catch((error) => {
        dispatch(bootAppError(error.response.status));
      });
  };
};

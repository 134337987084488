import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import moment from "moment";
import {
  Loader,
  CustomContainer,
  Button,
  Input,
  Modal,
} from "../../components/Ui";
import Avatar from "../../components/Avatar/Avatar";
import { useLastLocation } from "react-router-last-location";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import useForm from "../../hooks/useForm";
import {
  ChevronRight,
  UpArrowAlt,
  DownArrowAlt,
  ChevronDown,
} from "@styled-icons/boxicons-regular";
import { profileform } from "../../formsConfig/formsConfig";
import ModalMessage from "../../components/ModalMessage";
import EditProfile from "./EditProfile";
import { deleteUser } from "../../store/actions";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";

const ProfileContainer = styled.div`
  .slide {
    z-index: 11;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        margin-top: 0;
        padding: 15px;
      `}

      .content-avatar {
        width: 100%;
        display: flex;
        align-items: center;

        ${respondTo.md`
          margin-top: 70px;
        `}

        .profile-info {
          margin-left: 25px;

          strong {
            font-size: 12px;
            color: #1d241e;
          }

          h5 {
            font-size: 44px;
            color: #fff;
          }
        }
      }

      .personal-awards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        ${respondTo.md`
          width: 55%;
        `}

        .award-box {
          margin-top: 30px;

          ${respondTo.md`
            width: 280px;
            margin-right: 50px;
          `}

          strong {
            font-size: 12px;
            color: #1d241e;
            text-transform: uppercase;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-size: 64px;
              color: #00852a;
              line-height: 20px;
            }
          }
        }
      }

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .first-row-boxs {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .box-history-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .points-history-box {
      width: 100%;
      margin-bottom: 20px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(50% - 15px);
      `}

      .box-header {
        margin-bottom: 10px;
      }

      h6 {
        color: #f2a55b;
        font-size: 18px;
        margin-bottom: 10px;
      }

      ul {
        max-height: 450px;
        overflow-y: auto;

        li {
          display: flex;
          justify-content: space-between;
          padding-left: 10px;
          margin-top: 10px;

          &.positive {
            border-left: 5px solid #6cb967;

            svg,
            p {
              color: #6cb967;
            }
          }

          &.negative {
            border-left: 5px solid #eb5a3f;

            svg,
            p {
              color: #eb5a3f;
            }
          }

          .action-details {
            span {
              color: #adb1bc;
              font-size: 14px;
            }

            strong {
              display: block;
            }
          }
        }
      }
    }

    .orders-history-box {
      width: 100%;
      border-radius: 15px;
      background-color: #eb5a3f;
      padding: 15px;

      ${respondTo.md`
        width: calc(50% - 15px);
      `}

      h4 {
        color: #fff;
      }

      ul {
        max-height: 450px;
        overflow-y: auto;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #b73e27;

          .order-image {
            overflow: hidden;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            box-shadow: 0px 3px 10px #00000029;

            img {
              width: 100%;
            }
          }

          .order-info {
            margin-left: 10px;

            .order-points {
              color: #fff;
            }

            strong {
              display: block;
              color: #fdca46;
              margin: 5px 0;
            }

            span {
              color: #3a3c42;
            }

            .links {
              display: flex;
              margin-top: 3px;

              a {
                cursor: pointer;
                color: #fdca46;
                text-transform: uppercase;
                font-size: 12px;
                display: inline-block;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }

  .profile-boxs {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
      flex-wrap: nowrap;
    `}

    .shipment-box {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
      `}
    }

    & > div {
      width: 100%;

      ${respondTo.md`
        width: calc(33.333% - 15px);
      `}
    }

    .info-box,
    .shipment-box {
      background-color: #f2a55b;
      border-radius: 15px;
      padding: 15px;
    }

    .coupon-history-box {
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
      `}

      & > div {
        height: 100%;
      }
    }

    .box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      strong {
        margin-left: 10px;
        color: #fff;
        font-size: 21px;
      }
    }

    .box-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &.is-edit {
        .field-group,
        .field-group-half {
          margin-bottom: 0;
        }
      }

      .field-group-half {
        width: calc(50% - 10px);
        margin-bottom: 30px;
      }

      .field-group {
        width: 100%;
        margin-bottom: 30px;
      }

      span {
        display: block;
        color: #fdca46;
        font-weight: 700;
      }

      strong {
        font-size: 16px;
        color: #fff;
      }
    }

    .box-footer {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.double {
        & > button {
          margin-top: 10px;
        }

        p {
          color: #fff;
          margin-top: 15px;

          ${respondTo.md`
            margin-top: 0;
          `}
        }
      }

      span {
        text-align: center;
        color: #fff;
        width: 100%;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }
`;

const CouponList = styled.ul`
  max-height: 450px;
  overflow-y: auto;

  li {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .coupon-image {
    overflow: hidden;
    flex: 0 0 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 3px 10px #00000029;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .coupon-info {
    margin-left: 10px;

    span {
      color: #adb1bc !important;
      font-weight: 300 !important;
      font-size: 14px;
    }

    strong {
      color: #f2a55b !important;
      font-size: 14px;
    }
  }
`;

const UploadStep = styled.ul`
  list-style-type: none;
  background-color: #fff;
  border-radius: 20px;

  ${respondTo.sm`
    display: flex;
    align-items: flex-start;
		border-radius: 0;
		background-color: transparent;
	`}

  & li {
    padding: 20px;
    border-bottom: 1px solid #c5cbd1;

    ${respondTo.sm`
			width: 50%;
			margin: 0 15px;
			background-color: #fff;
			border-radius: 20px;
			border: none;
			box-shadow: 0 3px 6px #63666919;
    `}

    ${respondTo.md`
			width: calc(33.333% - 15px);
		`}

    & input[type="number"] {
      background-color: ${(props) => props.theme.input.bg};
    }

    label {
      align-items: flex-start;
      color: ${(props) => props.theme.secondary};
    }

    .buttons {
      display: flex;
    }
  }

  & li:last-child {
    border-bottom: none;
  }
`;

const Profile = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  let pathname = null;

  if (lastLocation) {
    pathname = lastLocation.pathname;
  }
  const {
    editUser,
    closeModalEdit,
    getUserIsLogged,
    userLogged,
    userInfo,
    updateModal,
    loading,
    logout,
    codes,
    token,
    toggleSidebar,
    deleteUser,
    deleteCompleted,
    userCouponList,
    partners,
    orders,
    actions,
  } = props;

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  useEffect(() => {
    getUserIsLogged();
    return () => {
      closeModalEdit();
    };
  }, []);

  useEffect(() => {
    setShowSidebar(true);
  }, [userLogged]);

  useEffect(() => {
    if (updateModal) {
      setIsEdit(false);
    }
  }, [updateModal]);

  useEffect(() => {
    if (deleteCompleted) {
      setDeleteAccountModal(false);
    }
  }, [deleteCompleted]);

  return (
    <ProfileContainer>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/profile-bg.png"} alt="" />

        <div className="content">
          <div>
            <div className="content-avatar">
              {userInfo && (
                <Avatar
                  width={200}
                  size={32}
                  firstname={userInfo.firstname[0]}
                />
              )}

              <div className="profile-info">
                <strong>MYPAGE</strong>
                <h5>{userInfo && userInfo.firstname}</h5>
              </div>
            </div>

            <div className="personal-awards">
              <div className="award-box">
                <strong>Classifica generale</strong>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/trophy-big-icon.svg"}
                    alt=""
                  />
                  <span>00˚</span>
                </div>
              </div>
              <div className="award-box">
                <strong>Riconoscimenti ottenuti</strong>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/award-big-icon.svg"}
                    alt=""
                  />
                  <span>5 di 10</span>
                </div>
              </div>
              <div className="award-box">
                <strong>Punti classifica</strong>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/star-big-icon.svg"}
                    alt=""
                  />
                  <span>0.000</span>
                </div>
              </div>
              <div className="award-box">
                <strong>Punti catalogo</strong>
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/icons/gift-big-icon.svg"}
                    alt=""
                  />
                  <span>0.000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CurrentMissions>
        <h3>Missioni in corso</h3>
        <div className="missions-boxs">
          <div className="mission-box">
            <div className="mission-box-left">
              <div className="progress-box">
                <div>
                  <span>Postepay</span>
                  <p>60%</p>
                </div>
                <div className="progress-icon">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/postepay-icon.svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mission-box-right">
              <strong>Estratto classifica</strong>
              <table>
                <thead>
                  <tr>
                    <td>POS</td>
                    <td>NICKNAME</td>
                    <td>PUNTI</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mission-box">
            <div className="mission-box-left">
              <div className="progress-box">
                <div>
                  <span>Servito</span>
                  <p>60%</p>
                </div>
                <div className="progress-icon">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/servito-icon.svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mission-box-right">
              <strong>Estratto classifica</strong>
              <table>
                <thead>
                  <tr>
                    <td>POS</td>
                    <td>NICKNAME</td>
                    <td>PUNTI</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mission-box">
            <div className="mission-box-left">
              <div className="progress-box">
                <div>
                  <span>Diesel +</span>
                  <p>60%</p>
                </div>
                <div className="progress-icon">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/diesel-icon.svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mission-box-right">
              <strong>Estratto classifica</strong>
              <table>
                <thead>
                  <tr>
                    <td>POS</td>
                    <td>NICKNAME</td>
                    <td>PUNTI</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>Nickname</td>
                    <td>0.0000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CurrentMissions>

      <AwardsList>
        <div className="al-header">
          <h3>I tuoi riconoscimenti</h3>
        </div>
        <div className="al-list">
          <div className="al-box" style={{ backgroundColor: "#1bb410" }}>
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/icons/circle-green.svg"}
                alt=""
              />
              <img
                className="ab-image"
                src={process.env.PUBLIC_URL + "/images/champion-diesel.svg"}
                alt=""
              />
            </div>
            <strong>Condividi</strong>
          </div>

          <div className="al-box" style={{ backgroundColor: "#94A2AC" }}>
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/icons/circle-gray.svg"}
                alt=""
              />
              <img
                className="ab-image"
                src={process.env.PUBLIC_URL + "/images/champion-diesel.svg"}
                alt=""
              />
            </div>
            <strong>Condividi</strong>
          </div>

          <div className="al-box" style={{ backgroundColor: "#94A2AC" }}>
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/icons/circle-gray.svg"}
                alt=""
              />
              <img
                className="ab-image"
                src={process.env.PUBLIC_URL + "/images/champion-hand.svg"}
                alt=""
              />
            </div>
            <strong>Condividi</strong>
          </div>

          <div className="al-box" style={{ backgroundColor: "#B19F00" }}>
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/icons/circle-yellow.svg"}
                alt=""
              />
              <img
                className="ab-image"
                src={process.env.PUBLIC_URL + "/images/champion-level.svg"}
                alt=""
              />
            </div>
            <strong>Condividi</strong>
          </div>

          <div className="al-box" style={{ backgroundColor: "#1bb410" }}>
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/icons/circle-green.svg"}
                alt=""
              />
              <img
                className="ab-image"
                src={process.env.PUBLIC_URL + "/images/champion-book.svg"}
                alt=""
              />
            </div>
            <strong>Condividi</strong>
          </div>
        </div>
      </AwardsList>

      <HistoryPrizes>
        <h3>Storico premi</h3>
        <div className="table-prize">
          <table>
            <thead>
              <tr>
                <td>PREMIO</td>
                <td></td>
                <td>PUNTI</td>
                <td>DATA ORDINE</td>
                <td>STATO</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="prize-image"></div>
                </td>
                <td>Nome premio</td>
                <td>0.000</td>
                <td>10/10/2021</td>
                <td>Stato</td>
              </tr>
              <tr>
                <td>
                  <div className="prize-image"></div>
                </td>
                <td>Nome premio</td>
                <td>0.000</td>
                <td>10/10/2021</td>
                <td>Stato</td>
              </tr>
              <tr>
                <td>
                  <div className="prize-image"></div>
                </td>
                <td>Nome premio</td>
                <td>0.000</td>
                <td>10/10/2021</td>
                <td>Stato</td>
              </tr>
              {orders &&
                orders.map(() => (
                  <tr>
                    <td></td>
                    <td>Nome premio</td>
                    <td>0.000</td>
                    <td>10/10/2021</td>
                    <td>Stato</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </HistoryPrizes>
    </ProfileContainer>
  );
};

const HistoryPrizes = styled.div`
  position: relative;
  top: -65px;
  background-color: #fff;
  padding: 50px 12px;
  z-index: 10;

  h3 {
    color: #1d241e;
    font-size: 44px;
  }

  .table-prize {
    margin-top: 16px;
    background-color: #ebebeb;
    border-radius: 24px;
    padding: 12px 16px;

    table {
      width: 100%;
      color: #94a2ac;

      thead {
        td {
          border-bottom: 1px solid #94a2ac;
          font-size: 10px;
          font-weight: 700;
        }
      }

      tbody {
        td {
          font-size: 18px;
          font-weight: 300;
          border-bottom: 1px solid #fff;
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      td {
        padding: 7px 0;
      }

      .prize-image {
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
`;

const AwardsList = styled.div`
  border-radius: 24px;
  background-color: #ebebeb;
  position: relative;
  top: -50px;
  z-index: 11;
  padding: 40px 12px;

  .al-header {
    text-align: center;

    h3 {
      font-size: 44px;
      color: #1d241e;
      margin-bottom: 10px;
    }
  }

  .al-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .al-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 16px 0;
      border-radius: 24px;
      width: 100%;
      margin: 10px 0;

      ${respondTo.md`
        margin: 0;
        width: calc(20% - 12px);
      `}

      .image {
        position: relative;
        margin: 0 auto;

        .ab-image {
          position: absolute;
          left: 35px;
          top: 35px;
        }
      }
    }

    strong {
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-size: 18px;
      display: block;
      color: #fff;
      font-weight: 600;
      margin-top: 16px;
    }
  }
`;

const CurrentMissions = styled.div`
  background-color: #fff;
  position: relative;
  padding: 50px 12px;
  z-index: 10;

  ${respondTo.md`
    top: -30px;
  `}

  h3 {
    font-size: 44px;
    color: #1d241e;
    margin-bottom: 15px;
  }

  .missions-boxs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .mission-box {
      width: 100%;
      background-color: #ebebeb;
      border-radius: 24px;
      padding: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 16px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(33.333% - 15px);
      `}

      .mission-box-left {
        .progress-box {
          width: 140px;
          height: 140px;
          border: 7px solid #d8d8d8;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          position: relative;

          & > div {
            position: relative;
            top: 10px;
            text-align: center;
          }

          span {
            font-size: 21px;
            color: ${(props) => props.theme.primary};
          }

          p {
            font-size: 24px;
            color: #1bb410;
          }

          .progress-icon {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background-color: #00852a;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -15px;
            right: -15px;

            img {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      .mission-box-right {
        width: 100%;
        padding-left: 25px;

        strong {
          text-transform: uppercase;
          font-size: 12px;
        }

        table {
          width: 100%;

          td {
            padding: 2px 0;
            color: #94a2ac;
          }

          thead {
            td {
              font-size: 10px;
              text-transform: uppercase;
              font-weight: 700;
              color: #94a2ac;
              border-bottom: 1px solid #94a2ac;
            }
          }

          tbody {
            td {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    deleteCompleted: state.user.deleteCompleted,
    userLogged: state.user.userLogged,
    userInfo: state.user.user.userinfo,
    userCouponList: state.user.user.vouchers,
    partners: state.partner.partnerList,
    loading: state.user.loading,
    codes: state.user.user.codes,
    updateModal: state.user.updateModal,
    updateError: state.user.user.updateError,
    orders: state.user.user.orders,
    actions: state.user.user.actions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModalEdit: () => dispatch(actionCreators.closeModalEdit()),
    editUser: (data) => dispatch(actionCreators.editUser(data)),
    deleteUser: () => dispatch(deleteUser()),
    getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Input, Loader } from "../../../Ui";
import { uploadReceiptForm } from "../../../../formsConfig/formsConfig";
import { useTranslation } from "react-i18next";
import useForm from "../../../../hooks/useForm";
import Fade from "../../../Fade/Fade";
import customaxios from "../../../../config/axios-refresh-token";
import { getSlug, getElement } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import { respondTo } from "../../../../theme/mixin";

const UploadReceiptContainer = styled.div`
  padding: 20px;

  ${respondTo.sm`
			padding: 0;
      margin: 0 -15px;
      margin-top: 50px;
  `}
`;

const UploadStep = styled.ul`
  list-style-type: none;
  background-color: #fff;
  border-radius: 20px;

  ${respondTo.sm`
    display: flex;
    align-items: flex-start;
		border-radius: 0;
		background-color: transparent;
	`}

  & li {
    padding: 20px;
    border-bottom: 1px solid #c5cbd1;

    ${respondTo.sm`
			width: calc(33.333% - 15px);
			margin: 0 15px;
			background-color: #fff;
			border-radius: 20px;
			border: none;
			box-shadow: 0 3px 6px #63666919;
		`}

    & input[type="number"] {
      background-color: ${(props) => props.theme.input.bg};
    }
  }

  & li:first-child > div {
    margin-bottom: 0;
  }

  & li:last-child {
    border-bottom: none;
  }
`;

const TextP = styled.h3`
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
  opacity: ${(props) => (props.blocked ? "0.5" : "1")};
`;

const UploadReceipt = (props) => {
  const {
    onShowInteractionHandler,
    typeInteraction,
    g: {
      id,
      interaction,
      preinteraction,
      log: {
        sid,
        sidcheck: { collection },
      },
    },
    lang: lang,
    action: { data: dataAction, loading },
    onShowResult,
  } = props;

  const [blockFields, setBlockFields] = useState(true);
  const [show, setShow] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [act, setAct] = useState(0);
  const [loadingUploadForm, setLoadingUploadForm] = useState(false);
  const [action, setAction] = useState(false);
  const [collectDue, setCollectDue] = useState(collection.collectiondue);

  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);

  const [t] = useTranslation();

  let slug = getSlug();

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  const onSetImmagine = (value) => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const onSetSelectedFiles = (file) => {
    setSelectedFiles((prevState) => {
      return [...prevState, file];
    });
  };

  const uploadForm = uploadReceiptForm(
    t("games.date"),
    t("games.number"),
    t("games.amount")
  );

  const fForm = { ...uploadForm, ...rules };

  const submitHandler = () => {
    const dataToSend = { type: "receipt" };
    for (const property in formData) {
      if (formData[property].valuetosend) {
        dataToSend[property] = formData[property].valuetosend;
      } else {
        dataToSend[property] = formData[property].value;
      }
    }

    var data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (
        selectedFiles[i].name === "file" ||
        selectedFiles[i].name === "receipt"
      ) {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === "fileextra") {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append("file", element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append("data", JSON.stringify(dataToSend));
    data.append("sid", sid);

    data.append(
      "section",
      typeInteraction === "preinteraction" ? "preinteraction" : "interaction"
    );
    setLoadingUploadForm(true);
    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then((response) => {
        const { data } = response;
        setLoadingUploadForm(false);
        if (response.status === 200) {
          if (typeInteraction === "preinteraction") {
            if (sid) {
              if (data.log.sidcheck.collection.status) {
                onShowInteractionHandler();
              } else {
                const {
                  log: {
                    sidcheck: { collection },
                  },
                } = data;
                setCollectDue(collection.collectiondue);
                deleteFileHandler();
              }
            } else {
              onShowInteractionHandler();
            }
          } else {
            setAction(true);
          }
        }
      })
      .catch((error) => {
        setLoadingUploadForm(false);
      });
  };

  let datePickerNames = [];

  for (var prop in fForm) {
    if (fForm[prop].elementType === "datepicker") {
      datePickerNames.push(fForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    districts,
    imgPreviewUrl,
    startDate,
    formIsValid,
    onSetFormValid,
    resetFormHandler,
  } = useForm(submitHandler, fForm, datePickerNames, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        startdate={startDate}
        lang={lang}
        districts={districts}
        title={inp.title}
        onSetImmagine={onSetImmagine}
        onSetFormValid={onSetFormValid}
        onSetSelectedFiles={onSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        resetImage={resetImage}
        {...inp}
      />
    );
  });

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (imgPreviewUrl) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
    }
  }, [imgPreviewUrl]);

  useEffect(() => {
    if (immagine) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
    }
  }, [immagine]);

  useEffect(() => {
    if (dataAction.length > 0) {
      let isData = getElement(dataAction, id);

      if (isData) {
        if (typeInteraction !== "preinteraction") {
          onShowResult();
        }
      }
    }
  }, [dataAction]);

  return (
    <React.Fragment>
      <div className="container">
        <Fade show={show}>
          <UploadReceiptContainer>
            <form onSubmit={formSubmitHandler} encType="multipart/form-data">
              <UploadStep>
                <li>
                  <TextP>1. {t("games.uploadreceipt")}</TextP>
                  <p
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Peso massimo 5Mb, formato pdf, jpg
                  </p>
                  {inputs[0]}
                </li>
                <li>
                  <TextP>2. {t("games.fillfieds")}</TextP>
                  {inputs.slice(1)}
                </li>
                <li>
                  <TextP>3. {t("games.discoverwin")}</TextP>
                  <Button small upper fullwidth>
                    {loadingUploadForm ? (
                      <Loader inBtn show={true} />
                    ) : (
                      t("app.play")
                    )}
                  </Button>
                </li>
              </UploadStep>
            </form>
          </UploadReceiptContainer>
        </Fade>
      </div>
      {action ? (
        <Dynamic id={id} slug={slug} section={typeInteraction} {...props} />
      ) : null}
    </React.Fragment>
  );
};

export default UploadReceipt;

import React from "react";
import PropTypes from "prop-types";
import { Logo, Typography, Wrapper } from "../../components/Ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";

const BannerContainer = styled.div`
  width: 100%;

  & img {
    width: 100%;
    object-fit: contain;
  }

  ${respondTo.md`
    width: calc(100% - 420px);
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
  `}
`;

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;

  ${respondTo.md`
    margin-bottom: -30px;
    padding-top: 20px;
	`}
`;

const AccessContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 40px;
  text-transform: uppercase;
  background-color: ${(props) => (props.active ? props.theme.primary : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#6366697f")};
  font-size: 14px;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;

  & a:first-child ${Tab} {
    border-radius: 20px 0 0 20px;
  }
  & a:last-child ${Tab} {
    border-radius: 0 20px 20px 0;
  }

  ${respondTo.md`
    margin-top: 0;
  `}
`;

const AuthTemplate = (props) => {
  const { logo, titlepage, children } = props;

  const promoImg = useSelector((state) => state.promo.viewer.img_desktop);
  const footercontent = useSelector((state) => state.cms.footer);

  const [t] = useTranslation();

  let activeTab;

  if (titlepage === "Registrati") {
    activeTab = "";
  }

  return (
    <>
      <Container>
        <AccessContainer>
          <Wrapper>{children}</Wrapper>
        </AccessContainer>

        <Footer footercontent={footercontent} />
      </Container>
    </>
  );
};

AuthTemplate.defaultProps = {
  titlepage: "",
};

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default AuthTemplate;

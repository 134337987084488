import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoToGame from "./GoToGame";
import { respondTo } from "../../../theme/mixin";
import { useSpring } from "react-spring";
import { useSelector } from "react-redux";

const ViewerWrapper = styled.div`
  .partecipa-box-wrapper {
    background-color: #6cb967;
    border-radius: 15px;
    padding: 15px;
    margin-top: 50px;

    .partecipa-box-header {
      margin-bottom: 10px;

      h4 {
        font-size: 21px;
        color: #fff;
        font-weight: 700;
      }
    }

    .partecipa-box-body {
      display: flex;
      flex-wrap: wrap;

      .box-body {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        ${respondTo.md`
          width: 50%;
        `}

        img {
          width: 50%;
          height: auto;
        }

        .box-body-info {
          width: 50%;
          margin-top: 20px;

          span {
            color: #fff;
            font-size: 28px;
            font-weight: 300;
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }

    .partecipa-box-footer {
      text-align: center;
      position: relative;

      p {
        color: #fff;
        font-size: 28px;
        font-weight: 300;
      }

      a {
        display: inline-block;
        position: relative;
        margin-top: 20px;

        ${respondTo.md`
          position: absolute;
          right: 0;
          top: 0;
          margin-top: 0;
        `}
      }

      button {
        color: #6cb967;
      }
    }
  }

  .back-box-wrapper {
    width: 100%;
    position: relative;
    padding: 15px 15px 15px 0;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 15px;

    ${respondTo.md`
      margin-top: 0;
      width: calc(50% - 15px);
      padding: 15px;
    `}

    & > img {
      position: absolute;
      top: 0;
      left: 55px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;

      ${respondTo.md`
        left: 0;
      `}
    }

    .back-box-header {
      font-size: 21px;
      color: #6cb967;
      font-weight: 700;
    }

    .back-box-body {
      width: 200px;
      margin-top: 80px;

      ${respondTo.md`
        width: 255px;
      `}

      h5 {
        font-size: 21px;
        color: #185e14;

        ${respondTo.md`
          font-size: 28px;
        `}
      }

      p {
        margin-top: 70px;
      }
    }

    .back-box-footer {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      margin-top: 20px;

      ${respondTo.md`
        position: absolute;
        bottom: 20px;
        margin-top: 0;
      `}
    }
  }
`;

const Viewer = (props) => {
  const {
    scream,
    description,
    type,
    islogged,
    logo,
    logopromo,
    url,
    cta,
    iscta,
    //ctatype,
    dark_text,
    oauths,
    ownlogin,
    oauthinfo,
    idFirstGame,
    idSecondGame,
    pathname,
    multipromo,
  } = props;

  const ctatype = "game";

  return (
    <ViewerWrapper>
      <div className="partecipa-box-wrapper">
        <div className="partecipa-box-header">
          <h4>Partecipa al concorso MYLINKPLUS: Vinci di + Smart Mobility</h4>
        </div>
        <div className="partecipa-box-body">
          <div className="box-body">
            <img
              src={process.env.PUBLIC_URL + "/images/ebike-partecipa.png"}
              alt=""
            />
            <div className="box-body-info">
              <span>Tutti i lunedì</span>
              <p>
                in palio una{" "}
                <strong>
                  Bicicletta
                  <br />
                  Elettrica ICON.E
                </strong>
              </p>
            </div>
          </div>
          <div className="box-body">
            <img
              src={process.env.PUBLIC_URL + "/images/twizy-partecipa.png"}
              alt=""
            />
            <div className="box-body-info">
              <span>Estrazione finale</span>
              <p>
                una <strong>Renault Twizy</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="partecipa-box-footer">
          <p>Con Linkem i tuoi premi sono sempre + smart!</p>

          {idFirstGame && (
            <GoToGame
              isLogged={islogged}
              logo={logo}
              url={url}
              cta={cta}
              ctatype={ctatype}
              oauths={oauths}
              ownlogin={ownlogin}
              oauthinfo={oauthinfo}
              idgame={idFirstGame}
              pathname={pathname}
              multipromo={multipromo}
            />
          )}
        </div>
      </div>
    </ViewerWrapper>
  );
};

Viewer.propTypes = {
  scream: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  logopromo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  iscta: PropTypes.number,
  ctatype: PropTypes.string,
};

export default Viewer;

import React from "react";
import styled from "styled-components";
import Parser from "html-react-parser";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  width: 100%;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1d241e;
    font-size: 32px;
    font-weight: 300;

    &:before {
      content: "";
      flex: 0 0 47px;
      height: 47px;
      margin-right: 10px;
      display: inline-block;
      border-radius: 16px;
      border: 1px solid
        ${(props) =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.value === 1
            ? props.theme.primary
            : props.bg
            ? props.bg
            : "#B9C2E1"};
      background-color: ${(props) =>
        props.value === 1
          ? props.theme.primary
          : props.bg
          ? props.bg
          : "#B9C2E1"};
      transition: all 150ms;
    }
  }
`;

const Checkbox = (props) => {
  return (
    <CheckboxContainer
      isvalid={props.isvalid}
      istouched={props.istouched}
      firstSubmit={props.firstSubmit}
      value={parseInt(props.value)}
      bg={props.bg}
    >
      <HiddenCheckbox {...props} />
      <label htmlFor={props.name}>{Parser(props.label)}</label>
    </CheckboxContainer>
  );
};

export default Checkbox;

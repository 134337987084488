import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "../..";
import LoginBtn from "../../../LoginBtn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorInModal from "../../../ErrorInModal";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const GoToGame = (props) => {
  const [t] = useTranslation();
  const {
    isLogged,
    logo,
    url,
    cta,
    ctatype,
    oauths,
    ownlogin,
    oauthinfo,
    idgame,
    pathname,
    multipromo,
    type,
  } = props;

  let content = null;

  if (ctatype === "url") {
    content = (
      <a href={url} target="_blank">
        <Button upper>{cta ? cta : t("app.visitsite")}</Button>
      </a>
    );
  } else {
    content =
      isLogged && ctatype === "game" ? (
        <Link
          to={{
            pathname: multipromo
              ? `${pathname}/game/${idgame}`
              : `/promo/game/${idgame}`,
            state: { cid: "boxgotogame-viewer" },
          }}
        >
          {type === "back-to-school" ? (
            <Button upper gradient={{ left: "#6CB967", right: "#96F290" }}>
              Gioca <ChevronRight size={30} />
            </Button>
          ) : (
            <Button upper gradient={{ left: "#fff", right: "#fff" }}>
              Partecipa <ChevronRight size={30} />
            </Button>
          )}
        </Link>
      ) : ownlogin ? (
        <Link to="/auth/login">
          <Button upper fullwidth>
            {cta ? cta : t("app.play")}
          </Button>
        </Link>
      ) : (
        <LoginBtn
          label={cta ? cta : t("app.play")}
          ownlogin={ownlogin}
          oauths={oauths}
          oauthinfo={oauthinfo}
        />
      );
  }

  return <React.Fragment>{content}</React.Fragment>;
};

GoToGame.propTypes = {
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  ctatype: PropTypes.string,
};

export default React.memo(GoToGame);

import React from "react";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";

const ProfileContainer = styled.div`
  .slide {
    z-index: 11;
    position: relative;
    padding: 130px 15px 30px 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 25px;

      ${respondTo.md`
        top: 0;
        padding: 15px;
        position: absolute;
        width: 100%;
        height: 100%;
      `}

      .content-info {
        max-width: 650px;
        color: #1d241e;

        h3 {
          font-size: 44px;
        }
      }

      .personal-awards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;

        .award-box {
          width: 280px;
          margin-top: 30px;
          margin-right: 50px;

          strong {
            font-size: 12px;
            color: #1d241e;
            text-transform: uppercase;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-size: 64px;
              color: #00852a;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;

const MissionDetails = (props) => {
  return (
    <>
      <ProfileContainer>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/mission-details-bg.png"}
            alt=""
          />

          <div className="content">
            <div>
              <div className="content-info">
                <h3>Missione postepay</h3>
                <strong>STORYTELLING</strong>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                  nonumy eirmod tempor invidunt. Pisum dolor sit amet,
                  consetetur sadipscing elitr, sed diam n diam nonumy eirmo
                  umquam sed adiscipit nonumy eirmod tempor invidunt adiscipit
                  nonumy eirmod.
                </p>
                <br />
                <strong>OBIETTIVO MISSIONE</strong>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam
                  nonumy eirmod tempor invidunt. Pisum dolor sit amet,
                  consetetur sadipscing elitr, sed diam n diam nonumy eirmo
                  umquam sed adiscipit nonumy eirmod tempor invidunt adiscipit
                  nonumy eirmod.
                </p>
              </div>
              <div className="personal-awards">
                <div className="award-box">
                  <strong>Classifica mission</strong>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/icons/trophy-big-icon.svg"
                      }
                      alt=""
                    />
                    <span>00˚</span>
                  </div>
                </div>
                <div className="award-box">
                  <strong>Punti classifica</strong>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/star-big-icon.svg"}
                      alt=""
                    />
                    <span>0.000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileContainer>

      <Other>
        <div className="col col-60">
          <h3>Avanzamento</h3>
          <div>
            <div className="sub-col">
              <div className="circle-box">
                <div className="circle-info">
                  <h5>Postepay</h5>
                  <span>60%</span>
                </div>
                <div className="sub-circle-box">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/postepay-icon.svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sub-col">
              <div className="info-box">
                <div>
                  <strong>Il tuo obiettivo</strong>
                  <span>000 ricariche</span>
                </div>
                <div>
                  <strong>Punti classifica</strong>
                  <span>000 per ricarica</span>
                </div>
                <div>
                  <strong>Punti catalogo</strong>
                  <span>000 per ricarica</span>
                </div>
              </div>
            </div>
          </div>

          <h3 style={{ marginTop: 70 }}>Riconoscimenti ottenibili</h3>
          <div className="can-awards">
            <div
              className="can-award-box"
              style={{ backgroundColor: "#1BB410" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/icons/mission-badge-green.svg"}
                alt=""
              />
              <div>
                <strong>Campione</strong>
                <span>Postepay</span>
              </div>
            </div>
            <div
              className="can-award-box"
              style={{ backgroundColor: "#94A2AC" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/icons/mission-badge-gray.svg"}
                alt=""
              />
              <div>
                <strong>Badge</strong>
                <span>Postepay</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-40">
          <h3>Classifica di missione</h3>
          <div className="ranking-table">
            <table>
              <thead>
                <tr>
                  <td>POS</td>
                  <td>NICKNAME</td>
                  <td>PUNTI</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr className="active">
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
                <tr>
                  <td>
                    <strong>000</strong>
                  </td>
                  <td>Nickname</td>
                  <td>0.000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Other>
    </>
  );
};

const Other = styled.div`
  background-color: #fff;
  position: relative;
  padding: 50px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${respondTo.md`
    top: -55px;
  `}

  .col {
    margin-top: 25px;

    &.col-40 {
      width: 100%;

      ${respondTo.md`
        width: calc(40% - 30px);
      `}
    }

    &.col-60 {
      width: 100%;

      ${respondTo.md`
        width: calc(60% - 30px);
      `}
    }

    h3 {
      font-size: 44px;
      color: #1d241e;
      margin-bottom: 15px;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
    }

    .ranking-table {
      background-color: #ebebeb;
      border-radius: 24px;
      padding: 10px 16px;

      table {
        width: 100%;
        color: #94a2ac;

        .active {
          color: ${(props) => props.theme.primary};
        }

        thead {
          td {
            font-size: 10px;
            font-weight: 700;
            border-bottom: 1px solid #94a2ac;
          }
        }

        tbody {
          td {
            font-size: 18px;

            strong {
              font-weight: 600;
            }
          }
        }

        td {
          padding: 4px 0;
        }
      }
    }

    .sub-col {
      width: 100%;

      ${respondTo.md`
        width: 50%;
      `}

      .circle-box {
        position: relative;
        width: 312px;
        height: 312px;
        border-radius: 50%;
        background-color: #ebebeb;
        border: 15px solid #d8d8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .circle-info {
          margin-top: 15px;

          h5 {
            color: #00852a;
            font-size: 32px;
          }

          span {
            font-size: 48px;
            color: #1bb410;
          }
        }

        .sub-circle-box {
          position: absolute;
          right: -25px;
          top: -25px;
          background-color: #00852a;
          border-radius: 50%;
          width: 125px;
          height: 125px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 68px;
          }
        }
      }

      .info-box {
        padding-left: 50px;
        margin-left: 50px;
        border-left: 1px solid #94a2ac;
        height: 100%;

        & > div {
          padding-top: 30px;

          strong {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
          }

          span {
            font-size: 24px;
            color: #00852a;
          }
        }
      }
    }

    .can-awards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .can-award-box {
        padding: 16px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        ${respondTo.md`
          margin-bottom: 0;
          width: calc(50% - 15px);
        `}

        div {
          margin-left: 16px;

          strong {
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            color: #1d241e;
          }

          span {
            color: #fff;
            font-size: 24px;
          }
        }
      }
    }
  }
`;

export default MissionDetails;

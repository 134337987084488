import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "../../Interactions";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import Avatar from "../../../Avatar/Avatar";
import { respondTo } from "../../../../theme/mixin";
import { Button } from "../../../Ui";
import * as actionCreators from "../../../../store/actions";

const CardHandlerWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 410px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }

    .ramaining-attempts {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 30px;

      ${respondTo.md`
        margin-top: 0;
        position: absolute;
        bottom: 80px;
      `}

      .ramaining-info {
        color: #6cb967;
        margin-left: 10px;
      }
    }
  }

  .play-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;

    .play-box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      z-index: 1;
      position: relative;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .play-box-rules {
      width: 100%;
      background-color: #6cb967;
      border-radius: 15px;
      padding: 15px;

      ${respondTo.md`
        width: calc(45% - 15px);
      `}

      h4 {
        color: #fff;
      }

      p {
        line-height: 1.5;
      }

      .play-box-rules-image {
        text-align: center;
      }
    }

    .play-box-game {
      position: relative;
      width: 100%;
      min-height: 400px;
      background-color: #fff;
      border-radius: 15px;
      padding: 15px;
      box-shadow: 0px 5px 15px #00000026;
      overflow: hidden;
      margin-top: 50px;

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: calc(100% - 20px);
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: #6cb967;
        border-radius: 15px;
        z-index: 2;
      }
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: calc(100% - 20px);
        position: absolute;
        right: 0;
        top: 10px;
        background-color: #fff;
        z-index: 2;
      }

      ${respondTo.md`
        width: calc(55% - 15px);
        margin-top: 0;
      `}

      h4 {
        color: #6cb967;
      }

      .play-box-background {
        position: absolute;
        top: 20px;
        left: 0;
        width: calc(100% - 20px);
        z-index: 0;
      }
    }
  }

  .privacy-consens {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;

    h5 {
      color: #6cb967;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }

    .privacy-checkbox {
      background-color: #fff;
      box-shadow: 0px 5px 15px #00000026;
      border-radius: 15px;
      width: 270px;
      height: 44px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      a {
        color: #185e14;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
`;

const CardHandler = (props) => {
  const dispatch = useDispatch();

  const {
    g: {
      id,
      preinteraction,
      log: { steps },
      gamePrivacyAccepted,
    },
    played,
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction

  const remaining_attempts = useSelector(
    (state) => state.promo.game[0].remaining_attempts
  );
  const currentUser = useSelector((state) => state.user.user.userinfo);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const acceptGameRulesHandler = () => {
    dispatch(actionCreators.acceptGameRules(id));
  };

  content = (
    <CardHandlerWrapper>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/gioca-slide.jpg"} alt="" />

        <div className="content">
          <div>
            <h3>
              Partecipa al concorso
              <br />
              MYLINKPLUS: Vinci di + Smart Mobility:
            </h3>
            <p>
              <strong>Tutti i lunedì</strong>, dal 02/08/21 al 30/05/22, in
              palio una <strong>Bicicletta Elettrica ICON.E</strong> e partecipi
              all’estrazione finale una <strong>Renault Twizy</strong>.
            </p>
          </div>

          {currentUser && (
            <div className="ramaining-attempts">
              <Avatar firstname={currentUser.firstname} />
              <div className="ramaining-info">
                {currentUser.firstname} hai,
                <br />
                <strong>
                  {remaining_attempts === null ? 0 : remaining_attempts}{" "}
                  possibilità di gioco
                </strong>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="play-box-wrapper">
        <div className="play-box-rules">
          <div className="play-box-header">
            <img src={process.env.PUBLIC_URL + "/icons/play-icon.svg"} alt="" />
            <h4>Come giocare</h4>
          </div>
          <p>
            <strong>Con Linkem i tuoi premi sono sempre + smart!</strong>
            <br />
            <strong>Clicca o fai tap sulla bici</strong>: se parte significa che
            hai vinto!
            <br /> Complimenti, la bici è tua!
            <br /> In più partecipi all’estrazione finale di una fantastica
            <strong> Renault Twizy</strong> per rendere ancora più green i tuoi
            spostamenti in città.
            <br />
            <br />
            <strong>Non hai vinto?</strong>
            <br />
            Non preoccuparti, ricorda che puoi sempre ritentare la fortuna
            lunedì prossimo. <strong>MyLinkPlus</strong> ricompensa la tua
            fedeltà: <strong>da + tempo</strong> sei nostro cliente e{" "}
            <strong>+ possibilità di gioco hai a disposizione.</strong>
          </p>
          <div className="play-box-rules-image">
            <img
              src={process.env.PUBLIC_URL + "/icons/come-giocare-icon.svg"}
              alt=""
            />
          </div>
        </div>
        <div className="play-box-game">
          <div className="play-box-header">
            <img src={process.env.PUBLIC_URL + "/icons/play-icon.svg"} alt="" />
            <h4>Gioca</h4>
          </div>
          <img
            className="play-box-background"
            src={process.env.PUBLIC_URL + "/images/play-background.svg"}
            alt=""
          />

          {showResult || played ? (
            <ResultGame {...props} />
          ) : !showInteraction && steps.preinteraction.data === null ? (
            <PreInteraction
              preinteraction={preinteraction}
              onShowInteractionHandler={onShowInteractionHandler}
              {...props}
            />
          ) : (
            <Card
              remaining_attempts={remaining_attempts}
              typeInteraction="interaction"
              onShowResult={onShowResult}
              {...props}
            />
          )}

          {!gamePrivacyAccepted && (
            <div className="privacy-consens">
              <div className="privacy-content">
                <h5>
                  Per partecipare al concorso
                  <br /> Vinci di + Smart Mobility
                </h5>
                <span>
                  Dichiaro di aver <strong>letto</strong> e{" "}
                  <strong>accettato</strong>
                </span>
                <div className="privacy-checkbox">
                  <a
                    href={`${
                      process.env.PUBLIC_URL +
                      "/pdf/RegCOMYLINKPLUSVINCI DI.pdf"
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Regolamento
                  </a>
                  <Button
                    small
                    gradient={{ left: "#6CB967", right: "#96F290" }}
                    onClick={acceptGameRulesHandler}
                  >
                    Accetto
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </CardHandlerWrapper>
  );

  return content;
};

CardHandler.propTypes = {
  action: PropTypes.object,
};

export default CardHandler;

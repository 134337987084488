import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";
import { sendLogError } from "./error";
import axios from "axios";

const getUserInfoStart = () => {
  return {
    type: actionTypes.GET_USER_INFO_START,
  };
};
const getUserInfoSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    user,
  };
};
const getUserInfoError = (error) => {
  localStorage.removeItem("token");
  return {
    type: actionTypes.GET_USER_INFO_ERROR,
    error,
  };
};

export const getUserInfo = () => {
  let endpoint = "auth/me";
  return (dispatch) => {
    dispatch(getUserInfoStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getUserInfoSuccess(result.data));
        }
      })
      .catch((error) => {
        dispatch(getUserInfoError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const userActivationStart = () => {
  return {
    type: actionTypes.USER_ACTIVATION_START,
  };
};

const userActivationSuccess = (val) => {
  return {
    type: actionTypes.USER_ACTIVATION_SUCCESS,
    val,
  };
};

const userActivationError = (error) => {
  return {
    type: actionTypes.USER_ACTIVATION_ERROR,
    error,
  };
};

export const userActivationClearError = (error) => {
  return {
    type: actionTypes.USER_ACTIVATION_CLEAR_ERROR,
  };
};

export const userActivation = (id) => {
  let endpoint = `auth/signup/activate/${id}`;
  return (dispatch) => {
    dispatch(userActivationStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(userActivationSuccess(result));
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 422) {
          dispatch(userActivationError(2040));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getUserIsLoggedStart = () => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_START,
  };
};

const getUserIsLoggedSuccess = (userLogged) => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_SUCCESS,
    userLogged,
  };
};

const getUserIsLoggedError = (error) => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_ERROR,
    error,
  };
};

export const getUserIsLogged = () => {
  let endpoint = `auth/check`;
  return (dispatch) => {
    dispatch(getUserIsLoggedStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getUserIsLoggedSuccess(result));
      })
      .catch((error) => {
        console.log("error user", error);
        dispatch(getUserIsLoggedError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const editUser = (dataResult) => {
  let endpoint = "auth/me";
  return (dispatch) => {
    customaxios
      .post(endpoint, dataResult)
      .then((result) => {
        dispatch(editUserSuccess(result.data.user));
      })
      .catch((error) => {
        dispatch(editUserError(error));
      });
  };
};

export const editUserSuccess = (data) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    user: data,
  };
};

export const editUserError = (error) => {
  return {
    type: actionTypes.EDIT_USER_ERROR,
    error: error,
  };
};

export const closeModalEdit = () => {
  return {
    type: actionTypes.CLOSE_MODAL_EDIT,
  };
};

export const deleteUser = () => {
  let endpoint = "auth/me/delete";
  return (dispatch) => {
    customaxios
      .post(endpoint)
      .then((result) => {
        dispatch(getUserInfo());
        dispatch(deleteUserSuccess(result.data.user));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const deleteUserSuccess = () => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
  };
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { CardBag } from "../../Interactions";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import Avatar from "../../../Avatar/Avatar";
import { respondTo } from "../../../../theme/mixin";
import { ShoppingBag, X } from "@styled-icons/boxicons-regular";
import Slider from "react-slick";
import { Button, Modal } from "../../../Ui";
import * as actionCreators from "../../../../store/actions";
import { Link } from "react-router-dom";

const CardHandlerWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 410px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }

    .ramaining-attempts {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 30px;

      ${respondTo.md`
        margin-top: 0;
        position: absolute;
        bottom: 80px;
      `}

      .ramaining-info {
        color: #6cb967;
        margin-left: 10px;
      }
    }
  }

  .play-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px;

    ${respondTo.md`
      padding: 0;
    `}

    .play-box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      z-index: 1;
      position: relative;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .play-box-rules {
      width: 100%;
      background-color: #6cb967;
      border-radius: 15px;
      padding: 15px;

      ${respondTo.md`
        width: calc(45% - 15px);
      `}

      h4 {
        color: #fff;
      }

      p {
        line-height: 1.5;
      }

      .play-box-rules-image {
        text-align: center;
      }

      .prizes-list {
        margin-top: 20px;

        .prize-box {
          text-align: center;

          .prize-image {
            margin: 0 auto;
            overflow: hidden;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            box-shadow: 0px 5px 15px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;

            img {
              width: auto;
              height: 45%;
            }
          }

          .prize-icon {
            cursor: pointer;
            background-color: #3f943a;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: relative;
            top: -25px;
            margin: 0 auto;
            transition: all 0.2s;

            &:hover {
              background-color: #bfffbb;
              color: #3f943a;
              transition: all 0.2s;
            }

            &.disabled {
              opacity: 0.7;
              pointer-events: none;
            }
          }

          .prize-info {
            position: relative;
            top: -10px;

            span {
              color: #fff;
              font-weight: 300;
            }

            strong {
              display: block;
              text-transform: uppercase;
            }

            small {
              font-weight: 700;
              font-size: 14px;
              color: #fff;
            }
          }
        }
      }
    }

    .play-box-game {
      position: relative;
      width: 100%;
      min-height: 600px;
      background-color: #fff;
      border-radius: 15px;
      padding: 15px 30px 15px 15px;
      box-shadow: 0px 5px 15px #00000026;
      overflow: hidden;
      margin-top: 50px;

      ${respondTo.md`
        min-height: 550px;
      `}

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: calc(100% - 20px);
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: #6cb967;
        border-radius: 15px;
      }
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: calc(100% - 20px);
        position: absolute;
        right: 0;
        top: 10px;
        background-color: #fff;
        z-index: 1;
      }

      ${respondTo.md`
        width: calc(55% - 15px);
        margin-top: 0;
      `}

      h4 {
        color: #6cb967;
      }

      .back-desktop {
        position: relative;

        ${respondTo.md`
          position: absolute;
          bottom: 0;
          left: 15px;
          width: calc(100% - 50px);
        `}
      }

      .back-bag {
        position: relative;
        left: 70px;

        ${respondTo.md`
          left: 150px;

          &.bag-close {
            bottom: -31px;
          }
        `}
      }

      .bag-prizes {
        position: absolute;
        left: 75px;
        z-index: 10;
        display: flex;
        margin-top: 35px;

        ${respondTo.md`
          left: 155px;
        `}

        .bag-prize {
          position: relative;
          margin: 0 3px;

          .bag-remove {
            position: absolute;
            top: 0;
            right: 0;
            width: 27px;
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #b73e27;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
          }

          .bag-image {
            overflow: hidden;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0px 5px 15px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: auto;
              height: 45%;
            }
          }
        }
      }

      .play-box-background {
        position: absolute;
        top: 20px;
        left: 0;
        width: calc(100% - 20px);
        z-index: 0;
      }
    }

    .privacy-consens {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.9);
      top: 0;
      left: 0;
      z-index: 1;

      h5 {
        color: #6cb967;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }

      span {
        font-size: 12px;
      }

      .privacy-checkbox {
        background-color: #fff;
        box-shadow: 0px 5px 15px #00000026;
        border-radius: 15px;
        width: 270px;
        height: 44px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        a {
          color: #185e14;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
`;

const Asterisk = styled.small`
  font-size: 10px;
  color: #adb1bc;
  display: block;
  margin-top: 20px;
  line-height: 1.5;
  padding: 0 15px;
  z-index: 1;

  ${respondTo.md`
    padding: 0;
  `}

  a {
    color: #eb5a3f;
    text-decoration: underline;
  }
`;

const BackHandler = (props) => {
  const dispatch = useDispatch();

  const {
    g: {
      id,
      preinteraction,
      log: { steps, won },
      gamePrivacyAccepted,
    },
    played,
    action,
  } = props;

  const options = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const interactionData = JSON.parse(props.g.interaction.data);
  const prizesListObj = interactionData.i18l.it.prizes;

  var prizesList = Object.keys(prizesListObj).map((key, index) => {
    return { id: key, ...prizesListObj[key] };
  });

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [prizes, setPrizes] = useState([]);
  const [prizesError, setPrizesError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction
  const [userHasWon, setUserHasWon] = useState(null);

  const remaining_attempts = useSelector(
    (state) => state.promo.game[0].remaining_attempts
  );
  const currentUser = useSelector((state) => state.user.user.userinfo);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const retryHandler = () => {
    dispatch(actionCreators.getGame(id, "vincidipiu", ""));
  };

  const managePrizesHandler = (prizeToAdd) => {
    let newPrizes = [...prizes];

    const alreadyInPrizes = newPrizes.find(
      (prize) => prize.id === prizeToAdd.id
    );

    if ((!played || showResult) && gamePrivacyAccepted) {
      if (prizes.length < 2 && !alreadyInPrizes) {
        newPrizes.push(prizeToAdd);
      } else {
        setPrizesError(true);
      }
    }

    setPrizes(newPrizes);
  };

  const removePrizeHandler = (index) => {
    let newPrizes = [...prizes];
    newPrizes.splice(index, 1);
    setPrizes(newPrizes);
  };

  const acceptGameRulesHandler = () => {
    dispatch(actionCreators.acceptGameRules(id));
  };

  useEffect(() => {
    if (action.data[0]) {
      setUserHasWon(action.data[0].won);
    }
  }, [action]);

  content = (
    <>
      <CardHandlerWrapper>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/back-to-school-slide.jpg"}
            alt=""
          />

          <div className="content">
            <div>
              <h3>Fai spazio nello zaino a premi esclusivi!</h3>
              <p>
                Il Back to School con MyLinkPlus non è<br /> mai stato così...
                <strong>emozionante!</strong>
                <br />
                <br />
                Dal 13/09/2021 al 27/09/2021 scegli i premi
                <br /> che ti piacciono di più,{" "}
                <strong>
                  inseriscili nello zaino,
                  <br /> tenta la fortuna e prova a vincerli!
                </strong>
              </p>
            </div>

            {currentUser && (
              <div className="ramaining-attempts">
                <Avatar firstname={currentUser.firstname} />
                <div className="ramaining-info">
                  {currentUser.firstname} hai,
                  <br />
                  <strong>
                    {remaining_attempts === null ? 0 : remaining_attempts}{" "}
                    possibilità di gioco
                  </strong>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="play-box-wrapper">
          <div className="play-box-rules">
            <div className="play-box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                alt=""
              />
              <h4>Scegli</h4>
            </div>
            <p>
              <strong>
                Con Linkem al rientro delle vacanze fai il pieno di tecnologia!
              </strong>
              <br />
              Scegli i tuoi due premi preferiti fra quelli presenti qui in basso
              e poi clicca sul bottone Gioca:{" "}
              <strong>se lo zaino si chiude li hai vinti!</strong>
              <br />
              <br />
              <strong>Non hai vinto?</strong>
              <br /> Non preoccuparti, ricorda che puoi sempre ritentare domani
              e che <strong>MyLinkPlus ricompensa la tua fedeltà</strong>: da +
              tempo sei nostro cliente e + possibilità di gioco hai a
              disposizione.
            </p>
            <div className="prizes-list">
              <Slider {...options}>
                {prizesList.map((prize, index) => (
                  <div className="prize-box" key={index}>
                    <div className="prize-image">
                      <img src={prize.media.src} alt="" />
                    </div>
                    <div
                      className={`prize-icon ${
                        (played || showResult) &&
                        gamePrivacyAccepted &&
                        "disabled"
                      }`}
                      onClick={() =>
                        (!played || !showResult) && gamePrivacyAccepted
                          ? managePrizesHandler(prize)
                          : null
                      }
                    >
                      <ShoppingBag size={24} />
                    </div>
                    <div className="prize-info">
                      <span>{prize.brand}</span>
                      <strong>{prize.name}</strong>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="play-box-game">
            <div className="play-box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                alt=""
              />
              <h4>Gioca</h4>
            </div>
            {!userHasWon && (
              <div className="bag-prizes">
                {!userHasWon &&
                  prizes.map((prize, index) => (
                    <div className="bag-prize" key={index}>
                      {!showResult && (
                        <div
                          className="bag-remove"
                          onClick={() => removePrizeHandler(index)}
                        >
                          <X size={18} />
                        </div>
                      )}
                      <div className="bag-image">
                        <img src={prize.media.src} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {userHasWon || won ? (
              <img
                className="back-bag bag-close"
                src={
                  process.env.PUBLIC_URL +
                  "/images/back-to-school-bag-close.svg"
                }
                alt=""
              />
            ) : (
              <img
                className="back-bag"
                src={process.env.PUBLIC_URL + "/images/back-to-school-bag.svg"}
                alt=""
              />
            )}

            {showResult || played ? (
              <ResultGame
                type="back-to-school"
                retry={() => retryHandler()}
                remaining_attempts={remaining_attempts}
                {...props}
              />
            ) : !showInteraction && steps.preinteraction.data === null ? (
              <PreInteraction
                preinteraction={preinteraction}
                onShowInteractionHandler={onShowInteractionHandler}
                {...props}
              />
            ) : (
              <CardBag
                remaining_attempts={remaining_attempts}
                canPlay={prizes.length === 2}
                type="back-to-school"
                typeInteraction="interaction"
                onShowResult={onShowResult}
                prizes={prizes}
                {...props}
              />
            )}

            <img
              className="back-desktop"
              src={
                process.env.PUBLIC_URL +
                "/images/back-to-school-desktop-new.svg"
              }
              alt=""
            />

            {!gamePrivacyAccepted && (
              <div className="privacy-consens">
                <div className="privacy-content">
                  <h5>
                    Per partecipare al concorso
                    <br /> Uno Zaino di Premi
                  </h5>
                  <span>
                    Dichiaro di aver <strong>letto</strong> e{" "}
                    <strong>accettato</strong>
                  </span>
                  <div className="privacy-checkbox">
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        "/pdf/regolamento-back-to-school.pdf"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Regolamento
                    </a>
                    <Button
                      small
                      gradient={{ left: "#6CB967", right: "#96F290" }}
                      hoverGradient={{ left: "#96F290", right: "#96F290" }}
                      onClick={acceptGameRulesHandler}
                    >
                      Accetto
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardHandlerWrapper>

      <Asterisk>
        * Concorso a premi valido dal 13/09/21 al 27/09/21. Estrazione riserve
        entro il 08/10/21. Montepremi presunto 11.324,85 iva inclusa. Per
        maggiori informazioni, periodi di partecipazione, premi e dettagli,
        consulta il{" "}
        <Link
          to={{
            pathname:
              process.env.PUBLIC_URL + "/pdf/regolamento-back-to-school.pdf",
          }}
          target="_blank"
        >
          regolamento
        </Link>
        .
      </Asterisk>

      <Modal show={prizesError} close={() => setPrizesError(false)}>
        <p>
          Attenzione, è possibile selezionare al massimo due premi diversi tra
          loro
        </p>
      </Modal>
    </>
  );

  return content;
};

BackHandler.propTypes = {
  action: PropTypes.object,
};

export default BackHandler;

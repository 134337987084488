import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  g g {
    fill: ${(props) => props.theme.primary};
  }
`;

const ArrowIcon = () => {
  return (
    <Icon width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
          <path
            d="M15,2.369 C8.02409132,2.369 2.369,8.02409132 2.369,15 C2.369,21.9759087 8.02409132,27.631 15,27.631 C21.9759087,27.631 27.631,21.9759087 27.631,15 C27.6227345,8.02751787 21.9724821,2.37726549 15,2.369 Z M19.074,15.912 L12.442,21.436 C11.9391752,21.8478583 11.198343,21.7773672 10.7822158,21.2780695 C10.3660886,20.7787717 10.4302656,20.0373659 10.926,19.617 L16.466,15.002 L10.926,10.384 C10.5969004,10.1144381 10.4377098,9.68904298 10.5089907,9.26965146 C10.5802715,8.85025994 10.8710656,8.50134535 11.2707437,8.3556483 C11.6704218,8.20995126 12.117541,8.28986987 12.442,8.565 L19.074,14.092 C19.3439261,14.3171447 19.499971,14.6505028 19.499971,15.002 C19.499971,15.3534972 19.3439261,15.6868553 19.074,15.912 Z"
            opacity="0.4"
          ></path>
          <path d="M15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 C23.2842712,0 30,6.71572875 30,15 C29.9906315,23.2803876 23.2803876,29.9906315 15,30 L15,30 Z M15,2.369 C8.02409132,2.369 2.369,8.02409132 2.369,15 C2.369,21.9759087 8.02409132,27.631 15,27.631 C21.9759087,27.631 27.631,21.9759087 27.631,15 C27.6227345,8.02751787 21.9724821,2.37726549 15,2.369 L15,2.369 Z"></path>
        </g>
      </g>
    </Icon>
  );
};

export default ArrowIcon;

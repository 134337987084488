import React from "react";
import PropTypes from "prop-types";
import RouteGames from "./RouteGames";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";

const Games = (props) => {
  const {
    //general
    lang,
    multipromo,
    // array games
    games,
    sendGameObj,
    // get code
    errorGetCode,
    codes,
    getCode,
    loadingGetCode,
    getCodeCleanError,
    cleanCodeFunction,
    //get link
    getLink,
    links,
    linkRedirect,
    errorGetLink,
    //instant win
    getIw,
    iw,
    loadingIw,
    errorIw,
    getIwCleanError,
    cleanIwDataFunction,
    //save result game
    resultGame,
    loadingResultGame,
    errorResultGame,
    saveGameResult,
    saveGameResultCleanError,
    // auth
    isLogged,
    oauths,
    ownLogin,
    oauthInfo,
  } = props;

  let contentArray = [];
  let content = null;
  let action = {};

  let authObj = {
    isLogged,
    oauths,
    ownLogin,
    oauthInfo,
  };
  // ACTION FORSE DEVE ESSERE GENERICO PER OGNI DINAMICA

  // NEL CASO IN CUI DOVESSI SALVARE IL GAME PRIMA DI AVERE UNA DINAMICA POPTREI PASSARE QUI LA FUNZIONE IN GETDYNAMIC
  // I build action object based on dynamic
  games.forEach((element) => {
    switch (element.dynamic.type) {
      case "getcode":
        action = {
          error: errorGetCode,
          data: codes,
          getDynamic: getCode,
          params: [],
          loading: loadingGetCode,
          cleanError: getCodeCleanError,
          cleanData: cleanCodeFunction,
        };
        break;
      case "getlink":
        action = {
          error: errorGetLink,
          getLink,
          links,
          linkRedirect,
        };
        break;
      case "instantwin":
        action = {
          error: errorIw,
          data: iw,
          getDynamic: getIw,
          params: [],
          loading: loadingIw,
          cleanError: getIwCleanError,
          cleanData: cleanIwDataFunction,
        };
        break;
      case "default":
        action = {
          error: errorResultGame,
          data: resultGame,
          getDynamic: saveGameResult,
          params: [],
          loading: loadingResultGame,
          cleanError: saveGameResultCleanError,
        };
        break;
      default:
        action = {};
    }

    contentArray.push(
      <RouteGames
        lang={lang}
        key={element.id}
        authObj={authObj}
        g={element}
        played={element.played ? element.played : null}
        action={action}
        sendGameObj={sendGameObj}
        multipromo={multipromo}
      />
    );
  });

  content = contentArray.map((a) => {
    return a;
  });

  return <React.Fragment>{content}</React.Fragment>;
};

Games.propTypes = {
  games: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    loadingGetCode: state.game.loadingGetCode,
    errorGetCode: state.game.errorGetCode,
    codes: state.game.codes,
    links: state.game.links,
    linkRedirect: state.game.linkRedirect,
    errorGetLink: state.game.errorGetLink,
    iw: state.game.iw,
    loadingIw: state.game.loadingIw,
    errorIw: state.game.errorIw,
    resultGame: state.game.resultGame,
    loadingResultGame: state.game.loadingResultGame,
    errorResultGame: state.game.errorResultGame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCode: (id, slug, section, resultData, sid) =>
      dispatch(actionCreators.getCode(id, slug, section, resultData, sid)),
    getCodeCleanError: (id) => dispatch(actionCreators.getCodeCleanError(id)),
    cleanCodeFunction: (id) => dispatch(actionCreators.cleanCodeFunction(id)),
    getLink: (id, slug, section, resultData) =>
      dispatch(actionCreators.getLink(id, slug, section, resultData)),
    getIw: (id, slug, section, resultData, sid, prizes) =>
      dispatch(
        actionCreators.getIw(id, slug, section, resultData, sid, prizes)
      ),
    cleanIwDataFunction: (id) =>
      dispatch(actionCreators.cleanIwDataFunction(id)),
    getIwCleanError: () => dispatch(actionCreators.getIwCleanError()),
    saveGameResult: (id, result) =>
      dispatch(actionCreators.saveGameResult(id, result)),
    saveGameResultCleanError: () =>
      dispatch(actionCreators.saveGameResultCleanError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Games);

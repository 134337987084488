import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSlug } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import styled from "styled-components";
import { Button, Loader, Modal } from "../../../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const GameContent = styled.div`
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  z-index: 1;

  .back-button {
    position: absolute;
    left: 200px;
    bottom: -10px;
  }
`;

const CardBag = (props) => {
  const {
    type,
    onShowResult,
    typeInteraction,
    g: {
      id,
      i18l,
      interaction,
      preinteraction,
      log: { sid },
    },
    action: { data: dataAction, loading },
    canPlay,
    remaining_attempts,
  } = props;

  const loaderIw = useSelector((state) => state.game.loadingIw);
  const [attemptsError, setAttemptsError] = useState(false);
  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState(false);
  const [loader, setLoader] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 200);
    }
  }, [dataAction]);

  const clickCardHandler = (index) => {
    setAction(true);
  };

  return (
    <>
      <GameContent>
        <Button
          upper
          className="back-button"
          gradient={{ left: "#6CB967", right: "#96F290" }}
          onClick={
            remaining_attempts && remaining_attempts > 0
              ? () => clickCardHandler(0)
              : () => setAttemptsError(true)
          }
          disabled={!canPlay || loaderIw}
        >
          {loaderIw ? (
            <Loader show={loaderIw} color="#fff" inBtn />
          ) : (
            <>
              Gioca <ChevronRight size={30} />
            </>
          )}
        </Button>
      </GameContent>

      <Modal show={attemptsError} close={() => setAttemptsError(false)}>
        <p>Attenzione, non puoi giocare perchè hai terminato i tentativi</p>
      </Modal>

      {action ? (
        <Dynamic
          id={id}
          sid={sid}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          {...props}
        />
      ) : null}
    </>
  );
};

export default CardBag;

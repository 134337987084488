import * as actionTypes from "./actionTypes";
import customAxiosNm from "../../config/axios-refresh-token-no-multipart";
import axios from "axios";

const toWishlistSuccess = (wishlist) => {
  return {
    type: actionTypes.TO_WISHLIST_SUCCESS,
    wishlist,
  };
};

export const addToWishlist = (productId) => {
  let endpoint = "products/addtowishlist";
  const data = { products: [{ id: productId }] };
  return (dispatch) => {
    customAxiosNm.post(endpoint, data).then((result) => {
      dispatch(toWishlistSuccess(result.data.wishlist));
    });
  };
};

export const removeFromWishlist = (productId) => {
  let endpoint = "products/removefromwishlist";
  const data = { products: [{ id: productId }] };
  return (dispatch) => {
    customAxiosNm.post(endpoint, data).then((result) => {
      if (result.status === 200) {
        dispatch(toWishlistSuccess(result.data.wishlist));
      }
    });
  };
};

const toCartSuccess = (cart) => {
  return {
    type: actionTypes.TO_CART_SUCCESS,
    cart,
  };
};

export const addToCart = (productId) => {
  let endpoint = "products/addtocart";
  const data = { products: [{ id: productId }] };
  return (dispatch) => {
    customAxiosNm.post(endpoint, data).then((result) => {
      dispatch(toCartSuccess(result.data.wishlist));
    });
  };
};

export const removeFromCart = (productId) => {
  let endpoint = "products/removefromcart";
  const data = { products: [{ id: productId }] };
  return (dispatch) => {
    customAxiosNm.post(endpoint, data).then((result) => {
      if (result.status === 200) {
        dispatch(toCartSuccess(result.data.wishlist));
      }
    });
  };
};

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  product: {},
  newOrder: {
    error: "",
    loading: false,
    completed: false,
  },
  orderModal: {
    isOpen: false,
    product: null,
  },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.product[0],
        loading: false,
      };
    case actionTypes.GET_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.ORDER_PRODUCT_START:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          loading: true,
        },
      };

    case actionTypes.ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          loading: false,
          completed: true,
        },
      };

    case actionTypes.OPEN_ORDER_MODAL:
      return {
        ...state,
        orderModal: {
          isOpen: true,
          product: action.product,
        },
      };

    case actionTypes.CLOSE_ORDER_MODAL:
      return {
        ...state,
        orderModal: {
          isOpen: false,
          product: null,
        },
      };

    case actionTypes.CLEAR_ORDER:
      return {
        ...state,
        newOrder: {
          error: "",
          loading: false,
          completed: false,
        },
        orderModal: {
          isOpen: true,
          product: null,
        },
      };
    default:
      return state;
  }
};

export default productReducer;
